import React, { useState, useEffect } from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Icon } from "@iconify/react";
import { _task_filter } from "../../DAL/task/task";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";

const getIcon = (name) => <Icon icon={name} width={20} height={20} />;

export default function FilterTasks({
  filterState,
  setFilterState,
  taskListData,
  setTaskListData,
  messageText,
  setMessageText,
  setDrawerState,
}) {
  const [filterInput, setFilterInput] = React.useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    pending: false,
    inprogress: false,
    completed: false,
  });

  const [filterButtonLoading, setfilterButtonLoading] = useState(false);
  const [clearButtonLoading, setClearButtonLoading] = useState(false);

  const { critical, high, medium, low, pending, inprogress, completed } =
    filterInput;
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    setFilterInput({
      ...filterInput,
      [event.target.name]: event.target.checked,
    });
  };

  const filterTasks = async (e) => {
    setfilterButtonLoading(true);
    setDrawerState(false);

    const taskStatus = Object.keys(filterInput)
      .filter(
        (key) =>
          ["pending", "inprogress", "completed"].includes(key) &&
          filterInput[key]
      )
      .map((key) => key.replace("in_", "in"));

    const taskPriority = Object.keys(filterInput).filter(
      (key) =>
        ["critical", "high", "medium", "low"].includes(key) && filterInput[key]
    );

    const object = {
      task_status: taskStatus,
      task_priority: taskPriority,
    };

    const result = await _task_filter(object);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (result.filter_task == "") {
        setMessageText("No Data Exist");
      } else {
        setMessageText("");
      }

      setTaskListData(result.filter_task.reverse());
      setFilterState(filterInput);
      setfilterButtonLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setfilterButtonLoading(false);
    }
  };

  const clearFilterTasks = async (e) => {
    setClearButtonLoading(true);
    setDrawerState(false);

    const result = await _task_filter({
      task_status: [],
      task_priority: [],
    });

    if (result.code === 200) {
      setFilterInput({
        critical: false,
        high: false,
        medium: false,
        low: false,
        pending: false,
        inprogress: false,
        completed: false,
      });
      setFilterState({
        critical: false,
        high: false,
        medium: false,
        low: false,
        pending: false,
        inprogress: false,
        completed: false,
      });

      if (result.filter_task == "") {
        setMessageText("No Data Exist");
      } else {
        setMessageText("");
      }
      enqueueSnackbar(result.message, { variant: "success" });
      setTaskListData(result.filter_task.reverse());
      setClearButtonLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setClearButtonLoading(false);
    }
  };

  useEffect(() => {
    setFilterInput(filterState);
  }, []);
  //===================================================================
  return (
    <div className="container">
      <div className="d-flex mt-2 mb-4  ">
        <FormControl>
          <FormLabel>Priority</FormLabel>
          <FormGroup style={{ paddingLeft: "5px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={critical}
                  onChange={handleChange}
                  name="critical"
                />
              }
              label="Critical"
            />
            <FormControlLabel
              control={
                <Checkbox checked={high} onChange={handleChange} name="high" />
              }
              label="High"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={medium}
                  onChange={handleChange}
                  name="medium"
                />
              }
              label="Medium"
            />
            <FormControlLabel
              control={
                <Checkbox checked={low} onChange={handleChange} name="low" />
              }
              label="Low"
            />
          </FormGroup>
        </FormControl>
      </div>

      <div className="d-flex mt-2 mb-4 ">
        <FormControl>
          <FormLabel>Status</FormLabel>
          <FormGroup style={{ paddingLeft: "5px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={pending}
                  onChange={handleChange}
                  name="pending"
                />
              }
              label="Pending"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={inprogress}
                  onChange={handleChange}
                  name="inprogress"
                />
              }
              label="In Progress"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={completed}
                  onChange={handleChange}
                  name="completed"
                />
              }
              label="Completed"
            />
          </FormGroup>
        </FormControl>
      </div>

      {filterButtonLoading ? (
        <button className="filter_button" onClick={filterTasks}>
          <CircularProgress />
        </button>
      ) : (
        <button className="filter_button" onClick={filterTasks}>
          {getIcon("material-symbols:filter-alt")} FILTER
        </button>
      )}

      {clearButtonLoading ? (
        <button className="filter_button mt-3" onClick={clearFilterTasks}>
          <CircularProgress />
        </button>
      ) : (
        <button className="filter_button mt-3" onClick={clearFilterTasks}>
          {getIcon("material-symbols:filter-alt-off")} CLEAR ALL
        </button>
      )}
    </div>
  );
}
