import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { _add_category, _update_category } from "../../DAL/category/category";
import {
  _list_product_review,
  _delete_product_review,
  _add_product_review,
  _edit_product_review,
} from "../../DAL/product/product";
import { _add_extra_expanse, _edit_extra_expanse } from "../../DAL/extra_expense/extra_expense";

export default function AddOrUpdateExtraExpenses({
//   product_review_id,
  onCloseDrawer,
  formType,
//   setCategoryData,
  setExtraExpenseData,
extraExpenseData,
  selectedRow,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = React.useState({
    // product_id: product_review_id,
    title: "",
    short_description: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    const response =
      formType === "ADD"
        ? await _add_extra_expanse(formData)
        : await _edit_extra_expanse(formData, inputs._id);
    if (response.code === 200) {
        console.log('response',response);
      let response_object = response.extra_expanses;

      if (formType === "ADD") {
        const newResponseObject = [response_object, ...extraExpenseData];
         
        const data = newResponseObject.map((expanse) => {
            return {
              ...expanse,
          
              less_description: {
                text: expanse.short_description,
                show_full_text: false,
                limit: 200,
              },
            };
          });
          console.log('response_object',response_object);
          console.log('newResponseObject',newResponseObject);
          console.log('data',data);
        // setExtraExpenseData((prev) => [response_object, ...prev]);
        setExtraExpenseData(data);
      } else {
        const newState = extraExpenseData.map((obj) => {
          if (obj._id === inputs._id) {
            return response_object;
          }
          return obj;
        });

        const data = newState.map((expanse) => {
            return {
              ...expanse,
          
              less_description: {
                text: expanse.short_description,
                show_full_text: false,
                limit: 200,
              },
            };
          });
          console.log('newState',newState);
          console.log('data',data);
        setExtraExpenseData(data);
      }
      onCloseDrawer();
    //   setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    //   setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setInputs(selectedRow);
    }
  }, [formType]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="title"
              value={inputs?.title}
              onChange={handleChange}
            />

          </FormControl>
        </div>
        
        <div className="col-12">
          <TextField
            id="outlined-multiline-static"
            label="Short Description"
            multiline
            rows={5}
            name="short_description"
            value={inputs.short_description}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            style={{ width: "100%" }}
            className="mb-3"
          />
        </div>
        <div className="text-end mb-3">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
              ? "Save"
              : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}


