import React, { useState, useEffect } from "react";
import { FormControl, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { _invoice_deposit } from "../../DAL/Shipment/invoice_deposit/invoice_deposit";
export default function InvoiceDeposit({
  apiFunction,
  selectedRow,
  onCloseDrawer,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    invoice_deposit_amount: 0,
    invoice_shipment_deposit: 0,
    live_stock_total_cost: 0,
    invoice_shipment_cost: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const objectData = {
      invoice_deposit_amount: inputs.invoice_deposit_amount,
      invoice_shipment_deposit: inputs.invoice_shipment_deposit,
      live_stock_total_cost: inputs.live_stock_total_cost,
      invoice_shipment_cost: inputs.invoice_shipment_cost,
    };
    const result = await _invoice_deposit(objectData, selectedRow._id);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      onCloseDrawer();
      apiFunction();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRow.is_invoice_deposit) {
      setUpdate(true);
      setInputs({
        invoice_deposit_amount:
          selectedRow.invoice_deposit.invoice_deposit_amount,
        invoice_shipment_deposit:
          selectedRow.invoice_deposit.invoice_shipment_deposit,
        invoice_shipment_cost:
          selectedRow.invoice_deposit.invoice_shipment_cost,
        live_stock_total_cost:
          selectedRow.invoice_deposit.live_stock_total_cost,
      });
    } else {
      setInputs({
        invoice_deposit_amount: 0,
        invoice_shipment_deposit: 0,
        invoice_shipment_cost: 0,
        live_stock_total_cost: selectedRow.live_stock_total_cost,
      });
    }
  }, []);

  return (
    <div className="container">
      <div className="row mt-3">
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Invoice Total Cost"
                variant="outlined"
                sx={{ width: "100%" }}
                name="live_stock_total_cost"
                value={inputs?.live_stock_total_cost}
                onChange={handleChange}
                disabled
                InputProps={{
                  readOnly: true,
                  min: 0,
                  step: 0.01,
                }}
                onKeyPress={(event) => {
                  if (!/[.,0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                required
              />
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Deposit toward Invoice Total Cost"
                variant="outlined"
                sx={{ width: "100%" }}
                name="invoice_deposit_amount"
                value={inputs?.invoice_deposit_amount}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                onKeyPress={(event) => {
                  if (!/[.,0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                required
              />
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Invoice Shipment Cost"
                variant="outlined"
                sx={{ width: "100%" }}
                name="invoice_shipment_cost"
                value={inputs?.invoice_shipment_cost}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                required
                onKeyPress={(event) => {
                  if (!/[.,0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Deposit toward Shipment Cost"
                variant="outlined"
                sx={{ width: "100%" }}
                name="invoice_shipment_deposit"
                value={inputs?.invoice_shipment_deposit}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                required
                onKeyPress={(event) => {
                  if (!/[.,0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </div>

          <div className="col-12 text-end mt-3">
            <button
              className="small-contained-button"
              type="submit"
              disabled={isLoading}
            >
              {isLoading
                ? update === true
                  ? "Updating..."
                  : "Saving..."
                : update === false
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
