import { invokeApi } from "../../bl_libs/invokeApi";

export const _general_Image_upload = async (blobInfo, width, height) => {
  let requestObj = {
    path: "api/app_api/upload_image",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  let _formData = new FormData();
  _formData.append("image", blobInfo);
  _formData.append("width", width);
  _formData.append("height", height);
  requestObj["postData"] = _formData;

  return invokeApi(requestObj);

  // invokeApi(requestObj).then((res) => {
  //   if (res.code === 200) {
  //     success(s3baseUrl + res.path);
  //   } else {
  //     failure(res.message);
  //   }
  // });
};
