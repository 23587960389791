import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

import {
  _add_social_settings_admin,
  _get_social_setting_admin,
} from "../../DAL/website_setting/social_settings";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";

function SocialMediaSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { userInfo, privilegeAccess } = useContentSetting();

  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    facebook: "",
    pinterest: "",
    instagram: "",
    youtube: "",
    snapchat: "",
    twitter: "",
    linkedin: "",
    tiktok: "",
    mailbox: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_social_setting_admin_list = async () => {
    setIsLoading(true);
    const result = await _get_social_setting_admin();
    if (result.code === 200) {
      setInputs(result.social_settings);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_social_setting_admin_list();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_Social_setting === true ||
        privilegeAccess.edit_Social_setting === true
      ) {
        setIsButtonLoading(true);
        const result = await _add_social_settings_admin({
          social_settings: inputs,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      setIsButtonLoading(true);
      const result = await _add_social_settings_admin({
        social_settings: inputs,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="my-3">Social Media Setting</h3>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess.view_Social_setting ? (
          <NoAccessData />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data">
            {/* Social Links */}
            <div className="row ">
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Facebook Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="facebook"
                  onChange={handleChange}
                  value={inputs?.facebook}
                />
              </div>

              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Pinterest Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="pinterest"
                  onChange={handleChange}
                  value={inputs?.pinterest}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Instagram Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="instagram"
                  onChange={handleChange}
                  value={inputs?.instagram}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Youtube Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="youtube"
                  onChange={handleChange}
                  value={inputs?.youtube}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="SnapChat Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="snapchat"
                  onChange={handleChange}
                  value={inputs?.snapchat}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Twitter Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="twitter"
                  onChange={handleChange}
                  value={inputs?.twitter}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Linkedin Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="linkedin"
                  onChange={handleChange}
                  value={inputs?.linkedin}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Tiktok Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="tiktok"
                  onChange={handleChange}
                  value={inputs?.tiktok}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Mailbox Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="mailbox"
                  onChange={handleChange}
                  value={inputs?.mailbox}
                />
              </div>
            </div>

            <div className="col-12 mt-3 text-end">
              <Tooltip
                title={
                  userInfo.type == 2 &&
                  (privilegeAccess.edit_Social_setting ||
                    privilegeAccess.add_Social_setting) === false
                    ? "You have no access to perform this action"
                    : ""
                }
                placement="right-end"
                arrow>
                {isButtonLoading ? (
                  <LoadingButton loading variant="contained">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    // style={{ textTransform: 'capitalize' }}
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                )}
              </Tooltip>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default SocialMediaSetting;
