import { FormControl, TextField } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { _edit_admin } from "../../DAL/admin/admin";
import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import { _set_user_to_localStorage } from "../../DAL/localStorage/localStorage";
import { useEffect, useState } from "react";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import { user_placeholder_image } from "../../assets";

const Input = styled("input")({
  display: "none",
});
export default function EditProfile({ onCloseDrawer }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [oldImage, setOldImage] = useState("");
  const { userInfo, handleUserInfo } = useContentSetting();
  const [name] = useState();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    image: {},
  });

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpload = (event) => {
    const preview = URL.createObjectURL(event.target.files[0]);
    setImage(preview);
    setUserData((userData) => ({
      ...userData,
      ["image"]: event.target.files[0],
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", userData.first_name);
    formData.append("email", userData.email);

    formData.append("last_name", userData.last_name);
    {
      userData.image && formData.append("image", userData.image);
    }
    setIsLoading(true);
    const response = await _edit_admin(formData);
    if (response.code == 200) {
      handleUserInfo(response.adminUser);
      _set_user_to_localStorage(response.adminUser);
      enqueueSnackbar(response.message, {
        variant: "success",
      });
      onCloseDrawer();
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }

    onCloseDrawer();
  };
  useEffect(() => {
    setUserData(userInfo);
    if (userInfo.profile_image) {
      setOldImage(s3baseUrl + userInfo.profile_image);
    }
  }, []);

  return (
    <>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 ">
              <p className="text-center profile-name">{name}</p>
              <div className="text-center">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="profile_image"
                    onChange={handleUpload}
                  />
                  <div className="edit-profile-icon">
                    <img
                      src={
                        image
                          ? image
                          : oldImage
                          ? oldImage
                          : user_placeholder_image
                      }
                    />
                    <PhotoCamera className="edit-profile-icon-position" />
                  </div>
                </label>
              </div>
            </div>
            <div className="col-12">
              <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="first_name"
                  value={userData.first_name}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="last_name"
                  value={userData.last_name}
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="email"
                  required
                  value={userData.email}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="col-12 mt-3 mb-5 text-end">
              <button
                className="small-contained-button"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
