import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../../../config/config";
import GeneralCkeditor from "../../../GeneralComponents/GeneralCkeditor";

export default function AddOrUpdateFaq({ formType, selectedRow }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = React.useState({
    question_statement: "",
    status: true,
    order: 0,
    answer: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.answer == null || inputs.answer == "") {
      enqueueSnackbar(" Answer is not Empty", { variant: "error" });
    }
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setInputs(selectedRow);
    }
  }, [formType]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <TextField
            id="outlined-multiline-static"
            label="Question Statement"
            multiline
            rows={4}
            name="question_statement"
            value={inputs.question_statement}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            style={{ width: "100%" }}
            className="mt-3 mb-3 "
            required
          />
        </div>
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              required
              label="Status"
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        {formType !== "ADD" ? (
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                type="number"
                id="outlined-basic"
                label="Order"
                variant="outlined"
                sx={{ width: "100%" }}
                name="order"
                value={inputs?.order}
                onChange={handleChange}
                inputProps={{ min: 1 }}
                required
              />
            </FormControl>
          </div>
        ) : (
          ""
        )}

        <div className="col-12 mb-3">
          <h4 style={{ fontSize: "1rem" }}>Answer *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            required
            name="answer"
            editorHeight={250}
          />
        </div>

        <div className="text-end mb-3">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
              ? "Save"
              : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
