import React from "react";
import "../../assets/css/style.css";
import { Typography, Grid } from "@mui/material";

export default function DashboardCard({ cards }) {
  return (
    <Grid container spacing={3}>
      {cards.map((card, index) => {
        return (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <div className={card.className}>
              <Grid container sx={{ pt: 1 }}>
                <Grid item xs={3} sx={{ pl: 1 }}>
                  <div className="dashboard-card-icons">{card.icon}</div>
                </Grid>
                <Grid item xs={9} sx={{ color: "white" }}>
                  <Typography variant="h4">{card.counting}</Typography>
                  <Typography variant="subtitle2">{card.title}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
