import React, { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  Container,
  Typography,
  Grid,
  Box,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";

import {
  _add_Employee,
  _add_vendor,
  _edit_Employee_Api,
  _edit_vendor_Api,
} from "../../DAL/Vender/vender";
import styled from "styled-components";
import { _edit_employee_Api } from "../../DAL/employee/employee";
const Input = styled("input")({
  display: "none",
});
function EditEmployees() {
  const param = useParams();
  const _id = param.id;
  const navigate = useNavigate();
  const { userInfo, privilegeAccess } = useContentSetting();
  const [buttonText, setButtonText] = useState();
  const [fileNew, setProfileImageNew] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = React.useState({});
  const [previews, setPreviews] = useState();
  const [Previvlages, setPrevivlages] = useState();
  const [oldImage, setOldImage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    status: true,
    address: "",
    email: "",
    password: "",
    contact_number: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
    setProfileImageNew(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("status", inputs.status);
    formData.append("privilages", JSON.stringify(Previvlages));
    formData.append("last_name", inputs.last_name);
    formData.append("email", inputs.email);
    formData.append("address", inputs.address);
    if (fileNew == true) {
      formData.append("image", image);
    } else {
    }
    formData.append("contact_number", inputs.contact_number);
    setIsLoading(true);
    const result = await _edit_employee_Api(formData, state.editValues._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/employee");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.edit_employee) {
      navigate(`/employee`);
    }
    const newPrevivlages = JSON.parse(state.editValues?.privilages);
    setPrevivlages(JSON.parse(state.editValues?.privilages));
    setButtonText(() =>
      Array.from({ length: newPrevivlages.length }, () => "Select All")
    );
    for (let i = 0; i < newPrevivlages.length; i++) {
      const allChecked = newPrevivlages[i].privilege_items.every(
        (item) => item.privilege_access
      );
      if (allChecked) {
        setButtonText((prev) => ({
          ...prev,
          [i]: "DeSelect All",
        }));
      } else {
        setButtonText((prev) => ({
          ...prev,
          [i]: "Select All",
        }));
      }
    }

    setOldImage(state.editValues?.image?.src);
    setInputs({
      ...inputs,
      ["first_name"]: state.editValues?.first_name,
      ["last_name"]: state.editValues?.last_name,
      ["status"]: state.editValues?.status,
      ["address"]: state.editValues?.address,
      ["contact_number"]: state.editValues?.contact_number,
      ["email"]: state.editValues?.email,
    });
  }, []);
  useEffect(() => {
    if (Previvlages) {
      const newPrevivlages = [...Previvlages];

      for (let i = 0; i < newPrevivlages.length; i++) {
        const allChecked = newPrevivlages[i].privilege_items.every(
          (item) => item.privilege_access
        );
        if (allChecked) {
          setButtonText((prev) => ({
            ...prev,
            [i]: "DeSelect All",
          }));
        } else {
          setButtonText((prev) => ({
            ...prev,
            [i]: "Select All",
          }));
        }
      }
    }
  }, [Previvlages]);

  function handleCheckboxChange(ParentIndex, childIndex) {
    const PrevivlagesCopy = [...Previvlages];
    const selectedArray = PrevivlagesCopy[ParentIndex];
    selectedArray.privilege_items[childIndex].privilege_access =
      !selectedArray.privilege_items[childIndex].privilege_access;
    setPrevivlages(PrevivlagesCopy);
  }
  function handleSelectAllChange(ParentIndex) {
    const PrevivlagesCopy = [...Previvlages];
    const selectedArray = PrevivlagesCopy[ParentIndex];
    const allSelected = selectedArray.privilege_items.every(
      (item) => item.privilege_access
    );
    if (allSelected) {
      selectedArray.privilege_items.forEach(
        (item) => (item.privilege_access = false)
      );
    } else {
      selectedArray.privilege_items.forEach(
        (item) => (item.privilege_access = true)
      );
    }
    setPrevivlages(PrevivlagesCopy);
  }
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Edit Employee</h3>
        </div>
      </div>
      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}>
        {" "}
        <form onSubmit={handleSubmit}>
          <div className="row table-form">
            <div className="col-12">
              <Divider
                sx={{
                  width: "100%",
                  borderColor: "black",
                  color: "#068ce5",
                  mt: 0,
                }}>
                <h5>Personal Info</h5>
              </Divider>
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="First Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="first_name"
                value={inputs?.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_2"
                label="Last Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="last_name"
                value={inputs?.last_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="Status"
                  onChange={handleChange}>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_3"
                label="Email"
                type="email"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="email"
                value={inputs?.email}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_5"
                label="Address"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="address"
                value={inputs?.address}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_7"
                label="Contact Number"
                variant="outlined"
                sx={{ width: "100%" }}
                type="number"
                required
                name="contact_number"
                value={inputs?.contact_number}
                onChange={handleChange}
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-5">
                  <p className="">Profile Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {previews ? (
                    <img src={previews} height={50} />
                  ) : (
                    oldImage && <img src={oldImage} height={50} />
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      id="contained-button-file"
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span">
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12">
              <Divider
                sx={{
                  width: "100%",
                  borderColor: "black",
                  color: "#068ce5",
                }}>
                <h5>Privileges</h5>
              </Divider>
            </div>

            <div className="col-12">
              <div className="row">
                {Previvlages?.map((prevaliges_item, ParentIndex) => {
                  return (
                    <div className="col-4" key={ParentIndex}>
                      <Accordion
                        className="accordian"
                        sx={{
                          boxShadow: "none",
                          borderBottom: "1px solid rgb(229, 229, 229)",
                        }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                          <Typography>
                            {prevaliges_item.privilege_item_lable}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className=" text-end">
                            <Button
                              variant="text"
                              onClick={() =>
                                handleSelectAllChange(ParentIndex)
                              }>
                              {buttonText[ParentIndex]}
                            </Button>
                          </div>
                          <FormGroup>
                            {prevaliges_item.privilege_items?.map(
                              (child, childIndex) => {
                                return (
                                  <FormControlLabel
                                    key={childIndex}
                                    control={
                                      <Checkbox
                                        name={child.privilege_label_for}
                                        checked={child.privilege_access}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            ParentIndex,
                                            childIndex
                                          )
                                        }
                                      />
                                    }
                                    label={child.privilege_label_text}
                                  />
                                );
                              }
                            )}
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default EditEmployees;
