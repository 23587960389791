import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import settings2fill from "@iconify/icons-eva/settings-2-fill";
import filetextoutline from "@iconify/icons-eva/file-text-outline";
import checkmark_square_outline from "@iconify/icons-eva/checkmark-square-outline";

//SidebarConfig-icons
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarConfig = () => {
  const SidebarInfo = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: getIcon(pieChart2Fill),
    },
    {
      title: "Employees",
      path: "/employee",
      icon: getIcon("clarity:employee-group-line"),
    },
    {
      title: "Customers",
      path: "/customers",
      icon: getIcon("pepicons-pop:persons"),
    },
    {
      title: "Vendors",
      path: "/vendors",
      icon: getIcon("fa6-solid:users-rectangle"),
    },
    {
      title: "Categories",
      path: "/categories",
      icon: getIcon("carbon:category"),
    },
    {
      title: "Attribute Variants",
      path: "/attribute-variants",
      icon: getIcon("material-symbols:format-list-bulleted-add-rounded"),
    },
    {
      title: "Products",
      path: "/products",
      icon: getIcon("fluent-mdl2:product-variant"),
    },
    {
      title: "What’s on Stock",
      path: "/stock",
      icon: getIcon("vaadin:stock"),
    },
    {
      title: "Extra Expenses",
      path: "/extra-expenses",
      icon: getIcon("arcticons:expense-register"),
    },
    {
      title: "Shipment",
      path: "/shipment",
      icon: getIcon("gridicons:shipping"),
    },
    {
      title: "Shipping & Tax Rates",
      path: "/shipment-prices",
      icon: getIcon("game-icons:money-stack"),
    },
    {
      title: "Orders",
      path: "/orders",
      icon: getIcon("icon-park-twotone:transaction-order"),
    },
    {
      title: "Sales Report",
      path: "/sales-report",
      icon: getIcon("icon-park-outline:sales-report"),
    },

    {
      title: "Tasks",
      path: "/tasks",
      icon: getIcon(checkmark_square_outline),
    },
    {
      title: "Website FAQs",
      path: "/website-faqs",
      icon: getIcon("mdi:question-mark-circle"),
    },

    // {
    //   title: "Manage Website",
    //   icon: getIcon(settings2fill),
    //   child_options: [
    //     {
    //       title: "Pages",
    //       path: "/website-pages",
    //       icon: getIcon(filetextoutline),
    //     },
    //   ],
    // },

    {
      title: "Website Setting",
      icon: getIcon("material-symbols:settings-rounded"),
      child_options: [
        {
          title: "General Setting",
          path: "/general-setting",
          icon: getIcon("icon-park-outline:setting-web"),
        },
        {
          title: "Home Setting",
          path: "/home-setting",
          icon: getIcon("mdi:home-edit"),
        },
        {
          title: "Social Media Setting",
          path: "/social-media-setting",
          icon: getIcon("tabler:social"),
        },
        {
          title: "Contact Us Setting",
          path: "/contact-us-setting",
          icon: getIcon("fluent-mdl2:contact-card-settings"),
        },
        {
          title: "Payment Setting",
          path: "/payment-setting",
          icon: getIcon(
            "streamline:money-cash-bag-dollar-bag-payment-cash-money-finance"
          ),
        },
        {
          title: "Product Detail Setting",
          path: "/product-detail-setting",
          icon: getIcon("fluent-mdl2:product-release"),
        },
        {
          title: "What’s on Stock Setting",
          path: "/whats-on-stock-setting",
          icon: getIcon("vaadin:stock"),
        },
        {
          title: "About Us Setting",
          path: "/about-us-setting",
          icon: getIcon("mdi:about"),
        },
        {
          title: "Checkout Setting",
          path: "/checkout-setting",
          icon: getIcon(
            "material-symbols:shopping-cart-checkout-outline-rounded"
          ),
        },
      ],
    },
  ];
  return SidebarInfo;
};

// if (row.attributes.length > 0 || row.variation.length > 0) {
//   SidebarConfig.push({
//     label: "Product Variations",
//     icon: "ooui:recent-changes-rtl",
//     handleClick: handleVariations,
//   });
// }

export default SidebarConfig;
