import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_product_details_settings = async (data) => {
    const requestObj = {
      path: `api/website_settings/get_product_details_settings`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
  export const _add_product_detail_settings = async (data) => {
    const requestObj = {
        path: `api/website_settings/add_product_detail_settings`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };