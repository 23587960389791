import { invokeApi } from "../../bl_libs/invokeApi";

export const _list_category = async () => {
  let requestObj = {
    path: `api/category/list_categories`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _list_parent_category = async () => {
  let requestObj = {
    path: `api/category/list_all_parent_categories`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_category = async (id, data) => {
  let requestObj = {
    path: `api/category/update_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_category = async (data) => {
  let requestObj = {
    path: `api/category/create_new_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_category = async (_id) => {
  let requestObj = {
    path: `api/category/delete_category/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _active_list_category = async () => {
  let requestObj = {
    path: "api/category/list_categories",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _categories_list_pagination = async (page, limit, search) => {
  const requestObj = {
    path: `api/category/list_categories_with_pagination?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
