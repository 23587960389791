import React, { useState, useEffect } from "react";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import AddCustomer from "../../components/Orders/AddCustomer";
import { _customers_list_no_pagination } from "../../DAL/Customers/Customers";
import Autocomplete from "@mui/material/Autocomplete";
import { _list_product } from "../../DAL/product/product";
import { _add_order } from "../../DAL/orders/orders";
import { _list_attribute } from "../../DAL/AttributeVarients/AttributeVarients";
import { useSnackbar } from "notistack";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";

export default function CreateOrders() {
  const { userInfo, privilegeAccess } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const [inputValues, setInputValues] = useState({
    variation: "",
    variation_name: "",
    available_quantity: 0,
    quantity_ordered: 0,
    price: 0,
    total_price: 0,
    discount: 0,
  });
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [CustomerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productList, setProductList] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [customer, setCustomer] = useState({
    customer_type: "",
  });
  const [subTotal, setSubTotal] = useState();
  const [discount, setDiscount] = useState();
  const [grandTotal, setGrandTotal] = useState();
  const [taxAmount, setTaxAmount] = useState();
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "product_name", label: "Product Name", alignRight: false },
    { id: "variation_name", label: "Attribute", alignRight: false },
    { id: "price", label: "Price", alignRight: false },
    { id: "discount", label: "Discount", alignRight: false },
    {
      id: "quantity_ordered",
      label: "Quantity",
      alignRight: false,
      renderData: (row) => {
        return (
          <div className="wrapQtyBtn qtyField d-flex align-items-center">
            <button
              onClick={() => {
                handleDecrement(row);
              }}
              className="pointer qtyBtn">
              -
            </button>
            <div className=" qty mx-2">{row.quantity_ordered}</div>
            <button
              onClick={() => {
                handleIncrement(row);
              }}
              className="pointer qtyBtn">
              +
            </button>
          </div>
        );
      },
    },
    { id: "total_price", label: "Total", alignRight: false },
    {
      alignCenter: true,
      renderData: (row) => {
        return (
          <Icon
            fontSize="25"
            style={{ marginLeft: "12px" }}
            onClick={() => {
              handleAgreeDelete(row);
            }}
            icon={"mdi:cancel-box-outline"}
            className="privileges-svg-icon"
          />
        );
      },
    },
  ];
  const handleAgreeDelete = (value) => {
    setDeleteValue(value.id);
    setOpenDelete(true);
    console.log(value);
  };
  const handleDelete = async () => {
    setOpenDelete(false);

    let new_orders_list = orderData.filter((order) => {
      return order.id !== deleteValue;
    });
    setOrderData(new_orders_list);
  };
  const handleIncrement = (item) => {
    const quantity = parseFloat(item.quantity_ordered);
    const updatedQuantityItem = {
      ...item,
      quantity_ordered: quantity + 1,
    };
    const updatedItem = {
      ...updatedQuantityItem,
      total_price:
        updatedQuantityItem.quantity_ordered * updatedQuantityItem.price,
    };
    handleSubmitQuantity(updatedItem);
  };

  const handleSubmitQuantity = async (item) => {
    const updatedOrderData = orderData.map((orderItem) => {
      if (orderItem.id === item.id) {
        return item;
      }
      return orderItem;
    });
    setOrderData(updatedOrderData);
  };
  const handleDecrement = (item) => {
    const quantity = parseFloat(item.quantity_ordered);
    if (item.quantity_ordered > 1) {
      const updatedQuantityItem = { ...item, quantity_ordered: quantity - 1 };
      const updatedItem = {
        ...updatedQuantityItem,
        total_price:
          updatedQuantityItem.quantity_ordered * updatedQuantityItem.price,
      };
      handleSubmitQuantity(updatedItem);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleOpenEditDrawer = () => {};

  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleChangeItems = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputValues((values) => ({ ...values, [name]: value }));
    if (name === "variation") {
      for (let i = 0; i < selectedProduct.variation.length; i++) {
        if (selectedProduct.variation[i]._id === value) {
          let variationName;
          const variation = selectedProduct.variation[i].attributes_items[1]
            ? selectedProduct.variation[i].attributes_items[0].item_name +
              "/" +
              selectedProduct.variation[i].attributes_items[1].item_name
            : selectedProduct.variation[i].attributes_items[0].item_name;
          variationName = variation;

          const unitPrice = selectedProduct.variation[i].unit_price;
          const availableQuanity =
            selectedProduct.variation[i].available_quantity;
          setInputValues((values) => ({ ...values, ["price"]: unitPrice }));
          setInputValues((values) => ({
            ...values,
            ["variation_name"]: variationName,
          }));
          setInputValues((values) => ({
            ...values,
            ["available_quantity"]: availableQuanity,
          }));
        }
      }
    }
    if (name === "quantity_ordered") {
      const unitPrice = inputValues.price;
      const totalPrice = value * unitPrice;
      setInputValues((values) => ({ ...values, ["total_price"]: totalPrice }));
    }
  };
  const customerListing = async () => {
    setIsLoading(true);
    const result = await _customers_list_no_pagination();
    if (result.code === 200) {
      setCustomerList(result.customeruser);
      get_product_listing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_product_listing = async () => {
    const result = await _list_product();
    if (result.code === 200) {
      setProductList(result.product);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const product =
      selectedProduct.common_name +
      " (" +
      selectedProduct.scientific_name +
      ")";

    const objectData = {
      id: orderData.length + 1,
      product_name: product,
      variation_name: inputValues.variation_name,
      price: inputValues.price,
      discount: inputValues.discount,
      quantity_ordered: inputValues.quantity_ordered,
      total_price: inputValues.total_price,
    };
    setOrderData((prev) => [objectData, ...prev]);
    setInputValues({
      variation: "",
      variation_name: "",
      available_quantity: 0,
      quantity_ordered: 0,
      price: 0,
      total_price: 0,
      discount: 0,
    });
    setSelectedProduct(null);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (customer.customer_type == "") {
      enqueueSnackbar("Customer Type is required", { variant: "error" });
    } else {
      if (selectedCustomer == null) {
        enqueueSnackbar("Customer is required", { variant: "error" });
      } else {
        const objectData = {
          orderData: orderData,
        };
        if (customer.customer_type == "guestUser") {
          objectData.customer = customer.customer_type;
        } else {
          objectData.customer = selectedCustomer._id;
        }
      }
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCustomer((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.add_order) {
      navigate(`/orders`);
    } else {
      customerListing();
    }
  }, []);
  useEffect(() => {
    if (selectedProduct == null) {
      setInputValues({
        variation: "",
        variation_name: "",
        available_quantity: 0,
        quantity_ordered: 0,
        price: 0,
        total_price: 0,
        discount: 0,
      });
    }
    if (selectedProduct != null) {
      const discount = 0.0;
      if (selectedProduct.discount_percentage) {
        const discountValue = selectedProduct.discount_percentage.toFixed(2);

        setInputValues((values) => ({
          ...values,
          ["discount"]: discountValue,
        }));
      } else {
        setInputValues((values) => ({
          ...values,
          ["discount"]: discount.toFixed(2),
        }));
      }
    }
  }, [selectedProduct]);
  useEffect(() => {
    // tax amount in bottom
    // let tax_amount = 0;
    // if (orderData.shipping_price) {
    //   tax_amount = orderDetail.shipping_price.toFixed(2);
    // } else {
    //   tax_amount = shipping_amount.toFixed(2);
    // }
    // setTaxAmount(tax_amount);

    // sub total in bottom
    let sub_total = 0;
    for (let i = 0; i < orderData.length; i++) {
      let total = parseFloat(orderData[i].total_price);
      sub_total += total;
    }
    let subTotalPrice = sub_total.toFixed(2);
    setSubTotal(subTotalPrice);

    // total discount in bottom
    let discountAmount = 0;
    for (let i = 0; i < orderData.length; i++) {
      let discountTotal = parseFloat(orderData[i].discount);
      discountAmount += discountTotal;
    }
    let discountTotal = discountAmount.toFixed(2);
    setDiscount(discountTotal);
  }, [orderData]);
  useEffect(() => {
    // grand total in bottom
    let totalAmount = 0;
    totalAmount = parseFloat(subTotal) + parseFloat(discount);
    let grandTotalAmount = totalAmount.toFixed(2);
    setGrandTotal(grandTotalAmount);
  }, [subTotal, discount, orderData]);
  return (
    <>
      <div className="container new-memories">
        {/* top row with title and button */}
        <div className="row">
          <div className="col-12">
            <IconButton onClick={handleBack} className="icon-button">
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
          </div>
          <div className="col-6">
            <h3 className="my-3">Create Order</h3>
          </div>
          <div className="col-6 text-end">
            <button
              className="small-contained-button"
              onClick={handleOpenDrawer}>
              Add New Customer
            </button>
          </div>
        </div>
        {/* customer */}
        <div className="row">
          <div className="col-12 col-lg-6">
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Customer Type*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="customer_type"
                value={customer.customer_type}
                required
                label="Customer Type"
                onChange={handleChange}>
                <MenuItem value="guestUser">Guest User</MenuItem>
                <MenuItem value="newCustomer">New Customer</MenuItem>
              </Select>
            </FormControl>
          </div>

          {customer.customer_type == "newCustomer" && (
            <div className="col-12 col-lg-6">
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="Customer-autocomplete"
                  options={CustomerList}
                  getOptionLabel={(option) =>
                    option.first_name
                      ? option.first_name +
                        " " +
                        option.last_name +
                        "(" +
                        option.email +
                        ")"
                      : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  value={selectedCustomer}
                  onChange={(event, newValue) => {
                    setSelectedCustomer(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </FormControl>
            </div>
          )}
        </div>
        {/* create order */}
        <form onSubmit={handleAdd}>
          {/* card to add product in cart */}
          <div className="row">
            <div className="col-12">
              <div className="row align-items-center">
                <div className="card mt-4">
                  <div className="row">
                    {/* product */}
                    <div className="col-12 col-lg-6 mt-3">
                      <FormControl fullWidth>
                        <Autocomplete
                          id="product-select-123"
                          options={productList}
                          getOptionLabel={(product) =>
                            product.common_name +
                            " (" +
                            product.scientific_name +
                            ")"
                          }
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          value={selectedProduct}
                          onChange={(event, newValue) => {
                            setSelectedProduct(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Product"
                              variant="outlined"
                              required
                            />
                          )}
                          required
                        />
                      </FormControl>
                    </div>
                    {/* variation */}

                    {selectedProduct?.variation?.length > 0 && (
                      <div className="col-12 col-lg-6 mt-3">
                        <FormControl fullWidth>
                          <InputLabel id={`demo-simple-select-autowidth-label`}>
                            Variations
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="variation"
                            required
                            value={inputValues.variation}
                            label="Variations"
                            onChange={(e) => handleChangeItems(e)}>
                            {selectedProduct.variation.map(
                              (item, item_index) => {
                                return (
                                  <MenuItem value={item._id} key={item_index}>
                                    {item.attributes_items[1]
                                      ? item.attributes_items[0].item_name +
                                        "/" +
                                        (item.attributes_items[1]?.item_name ||
                                          "")
                                      : item.attributes_items[0].item_name}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                    {/* available quantity */}
                    <div className="col-12 col-lg-6 mt-3">
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic-2"
                          label="Available Quantity"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          disabled
                          name="available_quantity"
                          value={inputValues.available_quantity}
                        />
                      </FormControl>
                    </div>
                    {/* quantity */}
                    <div className="col-12 col-lg-6 mt-3">
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic-2"
                          label="Quantity"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          required
                          name="quantity_ordered"
                          value={inputValues.quantity_ordered}
                          onChange={(e) => handleChangeItems(e)}
                          onKeyPress={(event) => {
                            if (
                              !/^[0-9]\d*$/.test(
                                event.target.value + event.key
                              ) ||
                              parseInt(event.target.value + event.key) >
                                inputValues.available_quantity
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </FormControl>
                    </div>
                    {/* price per item */}
                    <div className="col-12 col-lg-6 mt-3">
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic-3"
                          label="Price Per Item"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          disabled
                          name="price"
                          value={inputValues.price}
                        />
                      </FormControl>
                    </div>
                    {/* total price  */}
                    <div className="col-12 col-lg-6 mt-3">
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic-3"
                          label="Total Price"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          disabled
                          name="total_price"
                          value={inputValues.total_price}
                        />
                      </FormControl>
                    </div>
                    {/* discount  */}
                    <div className="col-12 col-lg-6 mt-3">
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic-3"
                          label="Discount"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          disabled
                          name="discount"
                          value={inputValues.discount}
                        />
                      </FormControl>
                    </div>
                    <div className="my-3 text-end">
                      <button
                        className="small-contained-button"
                        type="submit"
                        // disabled={isLoadingForm}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* table */}
        <div className="row mt-4">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={orderData}
            className="card-with-background"
            hide_pagination={true}
          />
        </div>
        {/* bottom pricing */}
        <div class="row mt-4">
          <div className="col-7"></div>

          <div className="col-5 border-div payment-box text-end">
            <div className="justify-content-between d-flex">
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Sub Total:{" "}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "14px" }}>{`$${subTotal}`}</Typography>
              </div>
            </div>
            <div className="mt-2 justify-content-between d-flex">
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Total Discount:{" "}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "14px" }}>{`$${discount}`}</Typography>
              </div>
            </div>
            <div className="mt-2 justify-content-between d-flex">
              <div>
                <Typography
                  sx={{
                    mr: 15,
                    fontWeight: "bold",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}>
                  Tax:{" "}
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px" }}>$2.00</Typography>
              </div>
            </div>
            <div className="mt-2 mb-2 justify-content-between d-flex">
              <div>
                <Typography
                  sx={{ mr: 12, fontWeight: "bold", fontSize: "14px" }}>
                  Grand Total:{" "}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "14px" }}>{`$${grandTotal}`}</Typography>
              </div>
            </div>
          </div>
        </div>
        {/* create order button */}
        <div className="row mt-2">
          <div className="col-12 text-end" data-html2canvas-ignore="true">
            <button
              className="small-contained-button"
              onClick={handleSubmit}
              style={{
                marginLeft: "10px",
                backgroundColor: "rgb(25, 97, 222)",
              }}>
              Create Order
            </button>
          </div>
        </div>
      </div>

      {/* add customer drawer */}
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Add Customer"
        componentToPassDown={
          <AddCustomer
            apiFunction={customerListing}
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            setCustomerList={setCustomerList}
            CustomerList={CustomerList}
          />
        }
      />
      {/* delete item confirmation */}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Item?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
