import { TextField } from "@mui/material";
import { useState } from "react";

import React from "react";
import { useSnackbar } from "notistack";
import { _change_customer_password } from "../../DAL/Customers/Customers";
function CustomerChangePassword({ setOpenModel, deleteValue }) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    password: "",
    confirm_password: "",
  });

  const handlePassword = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const changePasswordFields = {
      password: inputs.password,
      confirm_password: inputs.confirm_password,
    };
    const response = await _change_customer_password(
      changePasswordFields,
      deleteValue.user_id
    );
    if (response.code === 200) {
      enqueueSnackbar(response.message, {
        variant: "success",
      });
      setInputs({
        password: "",
        confirm_password: "",
      });
      setOpenModel(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setIsLoading(false);
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <TextField
        name="password"
        value={inputs.password}
        onChange={handlePassword}
        fullWidth
        label="New Password"
        size="small"
        className="form-control mt-3"
        required
      />
      <TextField
        name="confirm_password"
        value={inputs.confirm_password}
        onChange={handlePassword}
        fullWidth
        label="Confirm Password"
        size="small"
        className="form-control mt-3"
        required
      />
      <div className="text-end mt-3">
        <button
          className="small-contained-button"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </form>
  );
}

export default CustomerChangePassword;
