import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { CircularProgress, Tooltip } from "@mui/material";
import CustomCofirmation from "../../components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import {
  _delete_attribute,
  _list_attribute,
} from "../../DAL/AttributeVarients/AttributeVarients";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

export default function AttributeVarients() {
  const [isLoading, setIsLoading] = useState(true);
  const [AttributeData, setAttributeData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const { privilegeAccess, userInfo } = useContentSetting();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    setIsLoading(true);
    const response = await _delete_attribute(selectedRow.attribute_slug);
    if (response.code == 200) {
      enqueueSnackbar(response.message, {
        variant: "success",
      });

      setOpenDelete(false);
      setAttributeData((prevData) =>
        prevData.filter((category) => category._id !== selectedRow._id)
      );
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(
      `/attribute-variants/edit-attribute-variants/${value.attribute_slug}`,
      {
        state: { editValues: value },
      }
    );
  };

  const handleAgreeDelete = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const get_attribute_list = async () => {
    const response = await _list_attribute();
    if (response.code === 200) {
      setAttributeData(response.Attribute_Varient);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "attribute_name", label: "Title", alignRight: false },
    {
      id: "items",
      label: "Items Attribute",
      alignRight: false,
      renderData: (row) => {
        if (row?.attribute_items?.length > 0) {
          return row?.attribute_items?.map((value) => {
            return <div>{value.item_name ? value.item_name : "N/A"}</div>;
          });
        }
      },
    },
  ];
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
      is_access:
        userInfo.type == 0 ? true : privilegeAccess.edit_attribute_variant,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      is_access:
        userInfo.type == 0 ? true : privilegeAccess.delete_attribute_variant,
    },
  ];
  const handleNavigate = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_attribute_variant === true) {
        navigate(`/attribute-variants/add-attribute-variants`);
      }
    } else {
      navigate(`/attribute-variants/add-attribute-variants`);
    }
  };
  useEffect(() => {
    get_attribute_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Attribute Variants</h3>
          </div>
          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 &&
                privilegeAccess.add_attribute_variant === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow
            >
              <button
                className="small-contained-button"
                onClick={handleNavigate}
              >
                Add Attribute
              </button>
            </Tooltip>
          </div>
          {userInfo.type == 2 && !privilegeAccess.view_attribute_variant ? (
            <NoAccessData />
          ) : (
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={AttributeData}
                MENU_OPTIONS={MENU_OPTIONS}
                className="card-with-background"
              />
            </div>
          )}
        </div>
      </div>

      <CustomCofirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Attribute ?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
