import React from "react";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import Filters from "../../components/Orders/Filters";
import { Icon } from "@iconify/react";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";

import Chip from "@material-ui/core/Chip";

const TABLE_HEAD_PENDING = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "order_number", label: "Order No.", alignRight: false },
  { id: "customer_info", label: "Customer Info", alignRight: false },
  { id: "order_date", label: "Created At", alignRight: false },
  { id: "payment_method", label: "Payment Method", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  {
    id: "status",
    label: "Status",
    alignRight: false,
    renderData: () => {
      return (
        <Chip label={"Pending"} style={{ backgroundColor: "#ff9800" }}></Chip>
      );
    },
    // renderData: (row) => {
    //   return (
    //     <Chip label={row.status} style={{ backgroundColor: "#ff9800" }}></Chip>
    //   );
    // },
  },
  // { id: "action", label: "Action", alignRight: false, type: "action" },
];
const TABLE_HEAD_READY_TO_SHIP = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "order_number", label: "Order No.", alignRight: false },
  { id: "customer_info", label: "Customer Info", alignRight: false },
  { id: "order_date", label: "Created At", alignRight: false },
  { id: "payment_method", label: "Payment Method", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  {
    id: "status",
    label: "Status",
    alignRight: false,
    renderData: () => {
      return (
        <Chip
          label={"Ready to Ship"}
          style={{ backgroundColor: "#03a9f4" }}></Chip>
      );
    },
  },
  // { id: "action", label: "Action", alignRight: false, type: "action" },
];
const TABLE_HEAD_SHIPPED = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "order_number", label: "Order No.", alignRight: false },
  { id: "customer_info", label: "Customer Info", alignRight: false },
  { id: "order_date", label: "Created At", alignRight: false },
  { id: "payment_method", label: "Payment Method", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  {
    id: "status",
    label: "Status",
    alignRight: false,
    renderData: () => {
      return (
        <Chip label={"Shipped"} style={{ backgroundColor: "#0288d1" }}></Chip>
      );
    },
  },
  // { id: "action", label: "Action", alignRight: false, type: "action" },
];
const TABLE_HEAD_DELIVERED = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "order_number", label: "Order No.", alignRight: false },
  { id: "customer_info", label: "Customer Info", alignRight: false },
  { id: "order_date", label: "Created At", alignRight: false },
  { id: "payment_method", label: "Payment Method", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  {
    id: "status",
    label: "Status",
    alignRight: false,
    renderData: () => {
      return (
        <Chip label={"Delivered"} style={{ backgroundColor: "#4caf50" }}></Chip>
      );
    },
  },
  // { id: "action", label: "Action", alignRight: false, type: "action" },
];
const TABLE_HEAD_CANCELLED = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "order_number", label: "Order No.", alignRight: false },
  { id: "customer_info", label: "Customer Info", alignRight: false },
  { id: "order_date", label: "Created At", alignRight: false },
  { id: "payment_method", label: "Payment Method", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  {
    id: "status",
    label: "Status",
    alignRight: false,
    renderData: () => {
      return <Chip label={"Cancelled"}></Chip>;
    },
  },
  // { id: "action", label: "Action", alignRight: false, type: "action" },
];
const TABLE_HEAD_RETURNED = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "order_number", label: "Order No.", alignRight: false },
  { id: "customer_info", label: "Customer Info", alignRight: false },
  { id: "order_date", label: "Created At", alignRight: false },
  { id: "payment_method", label: "Payment Method", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  {
    id: "status",
    label: "Status",
    alignRight: false,
    renderData: () => {
      return (
        <Chip label={"Returned"} style={{ backgroundColor: "#ef5350" }}></Chip>
      );
    },
  },
  // { id: "action", label: "Action", alignRight: false, type: "action" },
];
const Pending = [
  {
    id: 1,
    order_number: "pending",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Pending",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Pending",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Pending",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Pending",
  },
];
const ReadyToShip = [
  {
    id: 1,
    order_number: "ReadyToShip",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Ready to Ship",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Ready to Ship",
  },
];
const Shipped = [
  {
    id: 1,
    order_number: "Shipped",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Shipped",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Shipped",
  },
];
const Delivered = [
  {
    id: 1,
    order_number: "Delivered",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Delivered",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Delivered",
  },
];
const Cancelled = [
  {
    id: 1,
    order_number: "Cancelled",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Cancelled",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Cancelled",
  },
];
const Returned = [
  {
    id: 1,
    order_number: "Returned",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Returned",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Returned",
  },
  {
    id: 1,
    order_number: "#0987654",
    customer_info: "Ali",
    order_date: "12-12-22",
    payment_method: "COD",
    price: "$1000",
    status: "Returned",
  },
];

export default function CustomerOrders() {
  const { privilegeAccess, userInfo } = useContentSetting();
  const navigate = useNavigate();
  const [value, setValue] = useState("pending");
  const [data, setData] = useState(Pending);
  const [drawerState, setDrawerState] = useState(false);
  const [TABLE_HEAD, setTABLE_HEAD] = useState(TABLE_HEAD_PENDING);

  const handleOpenDraweradd = () => {
    setDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  useEffect(() => {
    if (value == "pending") {
      setTABLE_HEAD(TABLE_HEAD_PENDING);
      setData(Pending);
    } else if (value == "readyToShip") {
      setTABLE_HEAD(TABLE_HEAD_READY_TO_SHIP);
      setData(ReadyToShip);
    } else if (value == "shipped") {
      setTABLE_HEAD(TABLE_HEAD_SHIPPED);
      setData(Shipped);
    } else if (value == "delivered") {
      setTABLE_HEAD(TABLE_HEAD_DELIVERED);
      setData(Delivered);
    } else if (value == "cancelled") {
      setTABLE_HEAD(TABLE_HEAD_CANCELLED);
      setData(Cancelled);
    } else if (value == "returned") {
      setTABLE_HEAD(TABLE_HEAD_RETURNED);
      setData(Returned);
    }
  }, [value]);

  const handleBack = () => {
    navigate(`/customers`);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton onClick={handleBack} className="icon-button">
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <h3>Customer Orders</h3>
          </div>
          <div className="col-6 text-end">
            <button
              className="small-contained-button"
              onClick={handleOpenDraweradd}>
              Filters
              <Icon
                icon="material-symbols:filter-alt"
                style={{ width: 18, height: 18, marginLeft: 20 }}
              />
            </button>
          </div>
        </div>

        {userInfo.type == 2 && !privilegeAccess.view_order ? (
          <NoAccessData />
        ) : (
          <>
            <div className="row">
              <div className="col-12 mt-3">
                <CustomMUITable
                  TABLE_HEAD={TABLE_HEAD}
                  data={data}
                  className="card-with-background"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <Filters
            setValue={setValue}
            value={value}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
    </>
  );
}
