import React, { useEffect, useState } from "react";
import { Card, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "../../config/config";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _delete_product,
  _products_list_pagination,
} from "../../DAL/product/product";
import { CircularProgress, Tooltip } from "@mui/material";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import TablePagination from "@mui/material/TablePagination";
import ProductDetail from "./ProductDetail";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";

const TABLE_HEAD = [
  {
    id: "action",
    label: "Action",
    alignRight: true,
    MENU_OPTIONS: "MENU_OPTIONS",
    type: "action",
  },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "common_name", label: "Common Name", alignRight: false },
  { id: "scientific_name", label: "Scientific Name", alignRight: false },
  { id: "productType", label: "Product Type", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  {
    id: "available",
    label: "Attribute (Quantity)",
    alignRight: false,
    renderData: (row) => {
      const data = row.available;
      const parts = data.split(" | ");
      return (
        <>
          {parts.map((part, index) => (
            <div key={index}>{part}</div>
          ))}
        </>
      );
    },
  },

  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];
export default function Products() {
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { privilegeAccess, userInfo } = useContentSetting();
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [reset, setReset] = useState(0);
  const [value, setValue] = useState("list");
  const [showPop, setshowPop] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/products/edit-product/${value._id}`, {
      state: value,
    });
  };
  const handleVariations = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/products/product-variations/${value._id}`, {
      state: { variations: value },
    });
  };
  const handleReview = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/products/reviews/${value._id}`, {
      state: { reviewValues: value },
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_product === true) {
        navigate(`/products/add-product`);
      }
    } else {
      navigate(`/products/add-product`);
    }
  };

  const handle_menus = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
        is_access: userInfo.type == 0 ? true : privilegeAccess.edit_product,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
        is_access: userInfo.type == 0 ? true : privilegeAccess.delete_product,
      },
      {
        label: "Reviews",
        icon: "pajamas:eye",
        handleClick: handleReview,
      },
    ];
    if (row.attributes.length > 0 || row.variation.length > 0) {
      MENU_OPTIONS.splice(2, 0, {
        label: "Product Variations",
        icon: "ooui:recent-changes-rtl",
        handleClick: handleVariations,
      });
    }

    return MENU_OPTIONS;
  };

  const productListing = async () => {
    setIsLoading(true);
    const result = await _products_list_pagination(
      page,
      rowsPerPage,
      searchText
    );
    if (result.code === 200) {
      const data = result.product.map((product) => {
        let variationName = "";
        if (product.variation.length > 0) {
          for (let i = 0; i < product.variation.length; i++) {
            if (product.variation[i].attributes_items.length > 1) {
              if (variationName) {
                variationName =
                  variationName +
                  " | " +
                  product.variation[i].attributes_items[0]?.item_name +
                  "/" +
                  product.variation[i].attributes_items[1]?.item_name +
                  " (" +
                  product.variation[i].available_quantity +
                  ")";
              } else {
                if (product.variation[i].attributes_items[0]) {
                  variationName =
                    product.variation[i].attributes_items[0]?.item_name +
                    "/" +
                    product.variation[i].attributes_items[1]?.item_name +
                    " (" +
                    product.variation[i].available_quantity +
                    ")";
                }
              }
            } else {
              if (variationName) {
                variationName =
                  variationName +
                  " | " +
                  product.variation[i].attributes_items[0]?.item_name +
                  " (" +
                  product.variation[i].available_quantity +
                  ")";
              } else {
                if (product.variation[i].attributes_items[0]) {
                  variationName =
                    product.variation[i].attributes_items[0]?.item_name +
                    " (" +
                    product.variation[i].available_quantity +
                    ")";
                }
              }
            }
          }
        }
        let category_id = "";
        if (product.category_info?._id) {
          category_id = product.category_info?.title;
        }
        let sub_category_id = "";
        if (product.sub_category?._id) {
          sub_category_id = product.sub_category?.title;
        }
        return {
          ...product,
          MENU_OPTIONS: handle_menus(product),
          productType:
            product.product_type.charAt(0).toUpperCase() +
            product.product_type.slice(1),
          category: sub_category_id
            ? category_id + " | " + sub_category_id
            : category_id,
          available: variationName,
        };
      });
      setTotalCount(result.total_count);
      setProductList(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const searchFunction = () => {
    setPage(0);
    productListing();
  };
  const searchResetFunction = () => {
    setSearchText("");
    setPage(0);
    if (reset == 0) {
      setReset(1);
    } else {
      setReset(0);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await _delete_product(deleteValue);

    if (result.code === 200) {
      let new_products_list = productList.filter((Product) => {
        return Product._id !== deleteValue;
      });
      setProductList(new_products_list);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleNavigateGallery = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.view_product === true) {
        if (value == "list") {
          setValue("gallery");
        } else {
          setValue("list");
        }
      }
    } else {
      if (value == "list") {
        setValue("gallery");
      } else {
        setValue("list");
      }
    }
  };
  const handleOpenPopup = (row) => {
    handlePrivilegesPopups();
    setSelectedRow(row);
  };
  const handlePrivilegesPopups = () => {
    setshowPop(true);
  };
  const handleCloseBillingDrawer = () => {
    setshowPop(false);
  };
  useEffect(() => {
    productListing();
  }, [page, rowsPerPage, reset]);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      <div className="container">
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this Product?"}
          handleAgree={handleDelete}
        />

        <div className="row mt-2">
          <div className="col-6">
            <h3>Products</h3>
          </div>
          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.view_product === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow
            >
              <button
                className="small-contained-button mx-2"
                onClick={handleNavigateGallery}
              >
                {`${value === "list" ? "Gallery" : "List"} View`}
              </button>
            </Tooltip>
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.add_product === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow
            >
              <button
                className="small-contained-button"
                onClick={handleNavigate}
              >
                Add Product
              </button>
            </Tooltip>
          </div>
        </div>

        {userInfo.type == 2 && !privilegeAccess.view_product ? (
          <NoAccessData />
        ) : (
          <>
            {value == "list" && (
              <div className="row">
                <div className="col-12 mt-3">
                  <CustomMUITable
                    TABLE_HEAD={TABLE_HEAD}
                    data={productList}
                    setData={setProductList}
                    className="card-with-background"
                    custom_pagination={{
                      total_count: totalCount,
                      rows_per_page: rowsPerPage,
                      page: page,
                      handleChangePage: handleChangePage,
                      onRowsPerPageChange: handleChangeRowsPerPage,
                    }}
                    custom_search={{
                      searchText: searchText,
                      setSearchText: setSearchText,
                      handleSubmit: searchFunction,
                      handleReset: searchResetFunction,
                    }}
                  />
                </div>
              </div>
            )}
            {value == "gallery" && (
              <>
                <div className="row g-4 mt-1">
                  {productList.map((item, index) => (
                    <div className="col-lg-4 col-s-12" key={index}>
                      <Card className="shadow-sm rounded">
                        <Carousel>
                          {item.images.map((image, index) => (
                            <Carousel.Item key={index}>
                              <img
                                className="d-block w-100"
                                src={s3baseUrl + image}
                                alt={`Product ${index}`}
                                height={370}
                                width={250}
                              />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                        <Card.Body>
                          <Card.Title>
                            {`${item.common_name} ${item.scientific_name}`}
                          </Card.Title>
                          <Card.Text>{item.category}</Card.Text>
                          <button
                            className="small-contained-button"
                            onClick={() => {
                              handleOpenPopup(item);
                            }}
                          >
                            View Detail
                          </button>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
                <div className="mt-2 gallery-view">
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={showPop}
        onCloseDrawer={handleCloseBillingDrawer}
        pageTitle={"Product Detail"}
        componentToPassDown={<ProductDetail selectedRow={selectedRow} />}
      />
    </>
  );
}
