import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { _add_shipment } from "../../DAL/Shipment/shipment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { _Vendor_listApi } from "../../DAL/Vender/vender";
import moment from "moment";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
function AddShipment({ onCloseDrawer, apiFunction }) {
  const navigate = useNavigate();
  const { userInfo, privilegeAccess } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [vendorData, setVendorData] = useState();
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [inputs, setInputs] = useState({
    shipment_title: "",
    vendor_info: "",
    shipment_type: "",
    shippment_date: dayjs(new Date()),
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDateChange = (date) => {
    setInputs((values) => ({ ...values, shippment_date: dayjs(date.$d) }));
  };

  const get_vendor_list = async () => {
    setIsLoading(true);
    const response = await _Vendor_listApi();
    if (response.code === 200) {
      setVendorData(response.vendor);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);

    e.preventDefault();

    const objectData = {
      shipment_title: inputs.shipment_title,
      vendor_info: selectedVendor._id,
      shipment_type: inputs.shipment_type,
      shippment_date: moment(inputs.shippment_date.$d).format("DD-MM-YYYY"),
    };

    const result = await _add_shipment(objectData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      onCloseDrawer();
      apiFunction();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.add_shipment) {
      navigate(`/shipment`);
    }
    get_vendor_list();
  }, []);

  //===================================================================
  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="col-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Shipment Title"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="shipment_title"
                value={inputs.shipment_title}
                onChange={handleChange}
              />
            </div>
            <div className="col-12">
              <FormControl fullWidth>
                <Autocomplete
                  id="vendor-autocomplete"
                  options={vendorData}
                  getOptionLabel={(option) =>
                    option.first_name + " " + option.last_name
                  }
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                  value={selectedVendor}
                  onChange={(event, newValue) => {
                    setSelectedVendor(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendor*"
                      variant="outlined"
                      sx={{ mb: 2, mt: 2 }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Shipment Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="shipment_type"
                  defaultValue="true"
                  value={inputs.shipment_type}
                  required
                  label="Shipment Type"
                  onChange={handleChange}
                >
                  <MenuItem value="local">Local </MenuItem>
                  <MenuItem value="international">International</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Shipment Date"
                  format="DD-MM-YYYY"
                  className="form-control"
                  name="shippment_date"
                  value={inputs?.shippment_date}
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </div>

            <div className="col-12 mt-3 mb-5 text-end">
              <button
                className="small-contained-button"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddShipment;
