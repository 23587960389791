import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_about_us_settings_admin = async (data) => {
  const requestObj = {
    path: `api/website_settings/get_about_us_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _add_about_us_settings_admin = async (data) => {
  const requestObj = {
    path: `api/website_settings/add_about_us_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _add_image = async (postData) => {
  const requestObj = {
    path: `api/app_api/upload_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _del_image = async (postData) => {
  const requestObj = {
    path: `api/init/delete_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
