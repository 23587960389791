import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { _add_category, _update_category } from "../../DAL/category/category";
import {
  _list_product_review,
  _delete_product_review,
  _add_product_review,
  _edit_product_review,
} from "../../DAL/product/product";

export default function AddOrUpdateProductReview({
  product_review_id,
  onCloseDrawer,
  formType,
  setCategoryData,
  productReviewData,
  selectedRow,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = React.useState({
    product_id: product_review_id,
    first_name: "",
    last_name: "",
    rating: "",
    review_message: "",
    status: true,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("product_id", inputs.product_id);
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("rating", inputs.rating);
    formData.append("review_message", inputs.review_message);
    formData.append("status", inputs.status);
    const response =
      formType === "ADD"
        ? await _add_product_review(formData)
        : await _edit_product_review(formData, inputs._id);
    if (response.code === 200) {
      let response_object = response.productreviews;

      if (formType === "ADD") {
        setCategoryData((prev) => [response_object, ...prev]);
      } else {
        const newState = productReviewData.map((obj) => {
          if (obj._id === inputs._id) {
            return response_object;
          }
          return obj;
        });
        setCategoryData(newState);
      }
      onCloseDrawer();
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setInputs(selectedRow);
    }
  }, [formType]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="first_name"
              value={inputs?.first_name}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="last_name"
              value={inputs?.last_name}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              type="number"
              id="outlined-basic"
              label="Rating"
              variant="outlined"
              sx={{ width: "100%" }}
              name="rating"
              value={inputs?.rating}
              onChange={handleChange}
              inputProps={{ min: 0, max: 5 }}
              required
            />
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              required
              label="Status"
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12">
          <TextField
            id="outlined-multiline-static"
            label="Review Message"
            multiline
            rows={5}
            name="review_message"
            value={inputs.review_message}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            style={{ width: "100%" }}
            className="mb-3"
          />
        </div>
        <div className="text-end mb-3">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
              ? "Save"
              : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
