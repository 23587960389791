import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  _edit_product,
  _list_child_categories,
  _add_image,
  _del_image,
} from "../../DAL/product/product";
import { _list_attribute } from "../../DAL/AttributeVarients/AttributeVarients";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Card,
  CircularProgress,
  Tooltip,
  Stack,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import { _list_category } from "../../DAL/category/category";
import { _list_product, _detail_product } from "../../DAL/product/product";
import { s3baseUrl } from "../../config/config";
import Autocomplete from "@mui/material/Autocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function EditProduct() {
  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;
  const navigate = useNavigate();
  const { userInfo, privilegeAccess } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [categoriesData, setCategoriesData] = useState([]);
  const [attributeData, setAttributeData] = useState([]);
  const [childValue, setChildValue] = useState([]);
  const [image, setImage] = useState([]);
  const [image2, setImage2] = useState([]);
  const [paths, setPaths] = useState([]);
  const [paths2, setPaths2] = useState([]);
  const [showImages, setShowImages] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);

  const [inputs, setInputs] = useState({
    common_name: "",
    scientific_name: "",
    short_description: "",
    detailed_description: "",
    status: "true",
    is_featured: "true",
    is_on_sale: true,
    discount_percentage: "",
    video_url: "",
    product_type: "plant",
    attributes: [],
    category: "",
    sub_category: "",
    discount_type: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    // if (name === "category") {
    //   // setCategorySelected(true);
    //   handleChildCategory(value);
    // }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const get_category_list = async () => {
    const response = await _list_category();
    if (response.code === 200) {
      setCategoriesData(response.category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_attribute_list = async () => {
    const response = await _list_attribute();
    if (response.code === 200) {
      setAttributeData(response.Attribute_Varient);
      get_category_list();
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handle_format_data = (data) => {
    get_attribute_list();
    let attributes_array = [];
    if (data.attributes.length > 0) {
      attributes_array = data.attributes.map((item) => {
        return item._id;
      });
    }
    let category_id = "";
    if (data.category_info?._id) {
      category_id = data.category_info?._id;
    }
    let sub_category_id = "";
    if (data.sub_category?._id) {
      sub_category_id = data.sub_category?._id;
      // setCategorySelected(true);
    }
    setInputs({
      ...data,
      // category: category_id,
      // sub_category: sub_category_id,
      attributes: attributes_array,
    });
    setSelectedParent(data.category_info);
    setSelectedChild(data.sub_category);
    if (data.images.length > 0) {
      setPaths(data.images);
      setImage(data.images);
      setShowImages(true);
    }
  };

  const get_detail_product = async () => {
    const response = await _detail_product(_ID);
    if (response.code === 200) {
      handle_format_data(response.Product);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let attributes_array = [];
    if (inputs.attributes.length > 0) {
      inputs.attributes.map((item) => {
        attributes_array.push({
          attribute_id: item,
        });
      });
    }
    if (attributes_array.length < 1) {
      enqueueSnackbar("Please Select Atleast One Attribute Variante", {
        variant: "error",
      });
    } else {
      const merged = [...paths, ...paths2];
      const objectData = {
        common_name: inputs.common_name,
        scientific_name: inputs.scientific_name,
        short_description: inputs.short_description,
        detailed_description: inputs.detailed_description,
        is_featured: inputs.is_featured,
        is_on_sale: inputs.is_on_sale,
        discount_percentage: inputs.discount_percentage,
        discount_type: inputs.discount_type,
        video_url: inputs.video_url,
        product_type: inputs.product_type,
        status: inputs.status,
        attributes: attributes_array,
        images: merged,
      };

      if (selectedParent != null) {
        objectData.category = selectedParent._id;
      }
      if (childValue.length > 0 && selectedChild != null) {
        objectData.sub_category = selectedChild._id;
      }
      // if (childValue.length > 0 && inputs.sub_category) {
      //   objectData.sub_category = inputs.sub_category;
      // }
      if (attributes_array.length > 2) {
        enqueueSnackbar("Select only 2 attribute variants", {
          variant: "error",
        });
      }

      if (paths.length > 0 || paths2.length > 0) {
        if (attributes_array.length <= 2) {
          const result = await _edit_product(objectData, inputs._id);

          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else {
        enqueueSnackbar("Product Image is Required", {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };
  const handleChildCategory = async (id) => {
    const result = await _list_child_categories(id);

    if (result.code === 200) {
      let new_category_list = result.category;

      setChildValue(new_category_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const uploadMaterial = async (image) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("width", "600");
    formData.append("height", "850");
    const result = await _add_image(formData);
    if (result.code === 200) {
      return result.path;
    } else {
      console.log(result.message, "error");
    }
  };

  const handleMaterial = async (e) => {
    const images = Array.from(e.target.files);
    setImage2((prevImages) => [...prevImages, ...images]);
    setShowImages(true);

    const results = [];
    for (let i = 0; i < images.length; i++) {
      const result = await uploadMaterial(images[i]);
      results.push(result);
    }
    setPaths2((prevMaterials) => [...prevMaterials, ...results]);
  };

  const handleDeleteMaterial = (index) => {
    delMaterial(paths[index]);
    const newFile = [...image];
    newFile.splice(index, 1);
    setImage(newFile);
    const newMaterail = [...paths];
    newMaterail.splice(index, 1);
    setPaths(newMaterail);
    if (newFile.length === 0 && image2.length === 0) {
      setShowImages(false);
    }
  };
  const delMaterial = async (img) => {
    const data = new FormData();
    data.append("image_path", img);
    const result = await _del_image(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  const handleDeleteMaterial2 = (index) => {
    delMaterial2(paths2[index]);
    const newFile = [...image2];
    newFile.splice(index, 1);
    setImage2(newFile);
    const newMaterail = [...paths2];
    newMaterail.splice(index, 1);
    setPaths2(newMaterail);
    if (newFile.length === 0 && image.length == 0) {
      setShowImages(false);
    }
  };
  const delMaterial2 = async (img) => {
    const data = new FormData();
    data.append("image_path", img);
    const result = await _del_image(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.edit_product) {
      navigate(`/products`);
    }
    if (!state) {
      get_detail_product();
    } else {
      handle_format_data(state);
      window.history.replaceState({}, document.title);
    }
  }, []);

  useEffect(() => {
    if (selectedParent != null) {
      handleChildCategory(selectedParent._id);
    }
  }, [selectedParent]);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Edit Product</h3>
        </div>
      </div>
      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 2,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form pt-5 pb-4">
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic"
                label="Common Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="common_name"
                value={inputs?.common_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic"
                label="Scientific Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="scientific_name"
                value={inputs?.scientific_name}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic"
                label="Video Url"
                type="URl"
                variant="outlined"
                sx={{ width: "100%" }}
                // required
                name="video_url"
                value={inputs?.video_url}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Product Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="product_type"
                  defaultValue="plant"
                  value={inputs.product_type}
                  required
                  label="Product Type"
                  onChange={handleChange}
                >
                  <MenuItem value="plant">plant</MenuItem>
                  <MenuItem value="fish">fish</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <Autocomplete
                  id="category-autocomplete"
                  options={categoriesData}
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                  value={selectedParent}
                  onChange={(event, newValue) => {
                    setSelectedParent(newValue);
                    setSelectedChild(null);
                    handleChildCategory(newValue._id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        inputs.product_type == "plant"
                          ? "Main Category"
                          : "Main Category*"
                      }
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </div>
            {selectedParent != null ? (
              <div className="col-md-6 col-xs-12">
                <FormControl fullWidth>
                  <Autocomplete
                    id="category-child-autocomplete"
                    options={childValue}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) =>
                      option._id === value._id
                    }
                    value={selectedChild}
                    onChange={(event, newValue) => {
                      setSelectedChild(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub Category"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            ) : null}

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  defaultValue="true"
                  value={inputs.status}
                  required
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Is On Sale*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_on_sale"
                  defaultValue="true"
                  value={inputs.is_on_sale}
                  required
                  label="Is On Sale"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs.is_on_sale == true && (
              <>
                <div className="col-md-6 col-xs-12">
                  <FormControl fullWidth sx={{ mb: 0 }}>
                    <InputLabel id="demo-simple-select-label">
                      Discount Type*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="discount_type"
                      defaultValue="true"
                      value={inputs.discount_type}
                      required
                      label="Discount Type"
                      onChange={handleChange}
                    >
                      <MenuItem value="value">Value</MenuItem>
                      <MenuItem value="percentage">Percentage</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-6 col-xs-12">
                  <TextField
                    id="outlined-basic-8"
                    label="Discount"
                    variant="outlined"
                    type="number"
                    sx={{ width: "100%" }}
                    required
                    value={inputs.discount_percentage}
                    name="discount_percentage"
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Is featured*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_featured"
                  defaultValue="true"
                  value={inputs.is_featured}
                  required
                  label="Is featured "
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-xs-12 ">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Select Attribute Variants *
                </InputLabel>
                <Tooltip
                  title="You can only select upto 2 variants"
                  placement="right-end"
                  arrow
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="attributes"
                    multiple
                    required
                    value={inputs.attributes}
                    label="Select Attribute Variants *"
                    onChange={handleChange}
                  >
                    {attributeData?.map((attribute, index) => (
                      <MenuItem key={attribute._id} value={attribute._id}>
                        {attribute.attribute_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </FormControl>
            </div>
            <p className="mt-2 mb-0">Upload Images (600x850)*</p>

            <div className="col-md-12 d-flex">
              <Stack direction={{ xs: "column", sm: "row" }} spacing={3.5}>
                <span className="upload-button mx-4">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="paths"
                    style={{ display: "none" }}
                    name="paths"
                    onChange={handleMaterial}
                    inputprops={{ multiple: true }}
                  />
                  <label htmlFor="paths" className="mt-3">
                    <CloudUploadIcon />
                  </label>
                </span>
              </Stack>

              {showImages === true ? (
                <Stack direction="row" spacing={3.5}>
                  <Stack direction="row" spacing={3.5}>
                    <>
                      {image.map((img, index) => (
                        <Stack
                          className="delete-icon"
                          key={index}
                          position="relative"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <img
                            alt={`image-${index}`}
                            src={s3baseUrl + img}
                            width="150"
                            style={{ maxWidth: "100%" }}
                          />

                          <span
                            aria-label="delete"
                            onClick={() => handleDeleteMaterial(index)}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              backgroundColor: "red",
                              fontSize: "13px",
                              fontWeight: "bold",
                              height: "20px,",
                              width: "20px",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "10px",
                            }}
                          >
                            x
                          </span>
                        </Stack>
                      ))}
                    </>
                  </Stack>
                  <Stack direction="row" spacing={3.5}>
                    <>
                      {image2.map((img, index) => (
                        <Stack
                          className="delete-icon"
                          key={index}
                          position="relative"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <img
                            alt={`image-${index}`}
                            src={URL.createObjectURL(img)}
                            width="150"
                            style={{ maxWidth: "100%" }}
                          />

                          <span
                            aria-label="delete"
                            onClick={() => handleDeleteMaterial2(index)}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              backgroundColor: "red",
                              fontSize: "13px",
                              fontWeight: "bold",
                              height: "20px,",
                              width: "20px",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "10px",
                            }}
                          >
                            x
                          </span>
                        </Stack>
                      ))}
                    </>
                  </Stack>
                </Stack>
              ) : null}
            </div>
            <div className="col-12">
              <TextField
                id="outlined-multiline-static"
                label="Short Description"
                multiline
                rows={5}
                name="short_description"
                value={inputs?.short_description}
                onChange={(e) => handleChange(e)}
                inputs={inputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-12">
              <h4 style={{ fontSize: "1rem" }}>Detail Description </h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="detailed_description"
                editorHeight={350}
              />
            </div>
            <div className="col-12 text-end">
              <button
                className="small-contained-button"
                type="submit"
                disabled={isLoading}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default EditProduct;
