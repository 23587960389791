import { invokeApi } from "../../bl_libs/invokeApi";

export const _list_attribute = async () => {
  let requestObj = {
    path: `api/attribute_varient/list_attribute_varient`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_attribute = async (data, id) => {
  let requestObj = {
    path: `api/attribute_varient/edit_attribute_varient/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      // "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_attributeVarients = async (data) => {
  let requestObj = {
    path: `api/attribute_varient/add_attribute_varient`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      // "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_attribute = async (_id) => {
  let requestObj = {
    path: `api/attribute_varient/delete_attribute_varient/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _detail_attribute = async (pageSlug) => {
  let requestObj = {
    path: `api/attribute_varient/detail_attribute_varient/${pageSlug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
