import React from "react";
import { useState, useEffect } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { Card, Carousel } from "react-bootstrap";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import { Chip, Tooltip } from "@mui/material";
import { _list_stock } from "../../DAL/stock/stock";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import TablePagination from "@mui/material/TablePagination";
import ProductDetail from "./ProductDetail";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import { Icon } from "@iconify/react";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "common_name", label: "Common Name", alignRight: false },
  { id: "scientific_name", label: "Scientific Name", alignRight: false },
  { id: "productType", label: "Product Type", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  {
    id: "available",
    label: "Attribute (Quantity)",
    alignRight: false,
    renderData: (row) => {
      const data = row.available;
      const parts = data.split(" | ");
      return (
        <>
          {parts.map((part, index) => (
            <div key={index}>{part}</div>
          ))}
        </>
      );
    },
  },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

export default function Stock() {
  const { privilegeAccess, userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState("list");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [stockData, setStockData] = useState([]);
  const [showPop, setshowPop] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const handleCloseBillingDrawer = () => {
    setshowPop(false);
  };
  const get_stock_list = async () => {
    setIsLoading(true);
    const response = await _list_stock();
    if (response.code === 200) {
      const data = response.product.map((product) => {
        let variationName = "";
        if (product.attributes_items) {
          if (product.attributes_items.length > 1) {
            if (product.attributes_items[0]) {
              variationName =
                product.attributes_items[0]?.item_name +
                "/" +
                product.attributes_items[1]?.item_name +
                " (" +
                product.available_quantity +
                ")";
            }
          } else {
            if (product.attributes_items[0]) {
              variationName =
                product.attributes_items[0]?.item_name +
                " (" +
                product.available_quantity +
                ")";
            }
          }
        }
        let category_id = "";
        if (product.category_info?._id) {
          category_id = product.category_info?.title;
        }
        let sub_category_id = "";
        if (product.sub_category?._id) {
          sub_category_id = product.sub_category?.title;
        }
        let productKind = "";
        if (product.product_type) {
          productKind =
            product.product_type.charAt(0).toUpperCase() +
            product.product_type.slice(1);
        }
        return {
          ...product,
          category: sub_category_id
            ? category_id + " | " + sub_category_id
            : category_id,
          available: variationName,
          productType: productKind,
        };
      });
      setTotalCount(response.total_count);
      setStockData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleNavigate = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.view_stock === true) {
        if (value == "list") {
          setValue("gallery");
        } else {
          setValue("list");
        }
      }
    } else {
      if (value == "list") {
        setValue("gallery");
      } else {
        setValue("list");
      }
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleOpenPopup = (row) => {
    handlePrivilegesPopups();
    setSelectedRow(row);
  };
  const handlePrivilegesPopups = () => {
    setshowPop(true);
  };
  useEffect(() => {
    get_stock_list();
  }, [page, rowsPerPage]);
  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Stock</h3>
          </div>
          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.view_stock === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              <button
                className="small-contained-button"
                onClick={handleNavigate}>
                {`${value === "list" ? "Gallery" : "List"} View`}
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            {userInfo.type == 2 && !privilegeAccess.view_stock ? (
              <NoAccessData />
            ) : (
              <>
                {value == "list" && (
                  <CustomMUITable
                    TABLE_HEAD={TABLE_HEAD}
                    data={stockData}
                    className="card-with-background"
                    // custom_pagination={{
                    //   total_count: totalCount,
                    //   rows_per_page: rowsPerPage,
                    //   page: page,
                    //   handleChangePage: handleChangePage,
                    //   onRowsPerPageChange: handleChangeRowsPerPage,
                    // }}
                  />
                )}
                {value == "gallery" && (
                  <>
                    <div className="row g-4">
                      {stockData.map((item, index) => (
                        <div className="col-lg-4 col-s-12" key={index}>
                          <Card className="shadow-sm rounded">
                            <Carousel>
                              {item.images.map((image, index) => (
                                <Carousel.Item key={index}>
                                  <img
                                    className="d-block w-100"
                                    src={s3baseUrl + image}
                                    alt={`Product ${index}`}
                                    height={370}
                                    width={250}
                                  />
                                </Carousel.Item>
                              ))}
                            </Carousel>
                            <Card.Body>
                              <Card.Title>
                                {`${item.common_name} ${item.scientific_name}`}
                              </Card.Title>
                              {/* <Card.Text>{item.available}</Card.Text>
                              <Card.Text>
                                <Chip
                                  width="140px"
                                  label={
                                    item.status === true ? "Active" : "Inactive"
                                  }
                                  variant="contained"
                                  color={
                                    item.status === true ? "success" : "error"
                                  }
                                  size="small"
                                />
                              </Card.Text> */}
                              <div className="row">
                                <div
                                  className="col-md-6"
                                  style={{ whiteSpace: "nowrap" }}>
                                  <Card.Text>{item.available}</Card.Text>
                                </div>
                                <div className="col-md-6 text-end">
                                  <Card.Text>
                                    <Chip
                                      width="140px"
                                      label={
                                        item.status === true
                                          ? "Active"
                                          : "Inactive"
                                      }
                                      variant="contained"
                                      color={
                                        item.status === true
                                          ? "success"
                                          : "error"
                                      }
                                      size="small"
                                    />
                                  </Card.Text>
                                </div>
                              </div>

                              {/* <div className="row">
                                <div className="col-12 mt-3  d-flex justify-content-center">
                                <div className="col-12 mt-3 text-end">
                                  
                                </div>
                              </div> */}
                              <button
                                className="small-contained-button mt-2"
                                onClick={() => {
                                  handleOpenPopup(item);
                                }}>
                                View Detail
                              </button>
                              {/* <Tooltip
                                title="View Product Detail"
                                placement="bottom-start"
                                arrow>
                                <Icon
                                  fontSize="25"
                                  // style={{ marginLeft: "20px" }}
                                  onClick={() => {
                                    handleOpenPopup(item);
                                  }}
                                  icon={"carbon:data-view-alt"}
                                  className="privileges-svg-icon"
                                />
                              </Tooltip> */}
                            </Card.Body>
                          </Card>
                        </div>
                      ))}
                    </div>
                    {/* <div className="mt-2 gallery-view">
                      <TablePagination
                        component="div"
                        count={totalCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div> */}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={showPop}
        onCloseDrawer={handleCloseBillingDrawer}
        pageTitle={"Product Detail"}
        componentToPassDown={<ProductDetail selectedRow={selectedRow} />}
      />
    </>
  );
}
