import { TextField } from "@mui/material";
import { useState } from "react";

import React from "react";
import { _change_password } from "../../DAL/admin/admin";
import { useSnackbar } from "notistack";

function ChangePassword({ setOpenModel }) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handlePassword = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdatePassword = async () => {
    setIsLoading(true);
    const changePasswordFields = {
      old_password: inputs.old_password,
      new_password: inputs.new_password,
      confirm_password: inputs.confirm_password,
    };

    const res = await _change_password(changePasswordFields);
    if (res.code === 200) {
      enqueueSnackbar(res.message, {
        variant: "success",
      });
      setInputs({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
      setOpenModel(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }

    setIsLoading(false);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleUpdatePassword();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <TextField
        name="old_password"
        value={inputs.old_password}
        onChange={handlePassword}
        fullWidth
        label="Old Password"
        size="small"
        className="form-control mt-4"
        required
      />
      <TextField
        name="new_password"
        value={inputs.new_password}
        onChange={handlePassword}
        fullWidth
        label="New Password"
        size="small"
        className="form-control mt-3"
        required
      />
      <TextField
        name="confirm_password"
        value={inputs.confirm_password}
        onChange={handlePassword}
        fullWidth
        label="Confirm Password"
        size="small"
        className="form-control mt-3"
        required
      />
      <div className="text-end mt-3">
        <button className="small-contained-button" type="submit">
          {isLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </form>
  );
}

export default ChangePassword;
