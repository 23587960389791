import React, { useEffect, useState } from "react";
import {
  _list_category,
  _delete_category,
} from "../../../DAL/category/category";
import CustomDrawer from "../../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import AddOrUpdateCategory from "../../../components/Categories/AddOrUpdateCategory";
import { CircularProgress, IconButton } from "@mui/material";
import CustomCofirmation from "../../../components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../../config/config";
import {
  _list_reviews_with_product_id,
  _list_product_review,
  _delete_product_review,
  _add_product_review,
  _edit_product_review,
} from "../../../DAL/product/product";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import AddOrUpdateProductReview from "../../../components/ProductReview/AddOrUpdateProductReview";
import { Icon } from "@iconify/react";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "first_name", label: "First Name", alignRight: false },
  { id: "last_name", label: "Last Name", alignRight: false },
  { id: "review_message", label: "Review Message", alignRight: false },
  { id: "rating", label: "Rating", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

export default function ProductReview() {
  const param = useParams();
  const product_review_id = param.id;
  const product_review_common_name = param.id;
  const [productCommonName, setProductCommonName] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [isLoading, setIsLoading] = useState(true);
  const [productReviewData, setProductReviewData] = useState([]);
  const [drawerState, setDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleDelete = async () => {
    const response = await _delete_product_review(selectedRow._id);
    if (response.code == 200) {
      enqueueSnackbar(response.message, {
        variant: "success",
      });
      setOpenDelete(false);
      setProductReviewData((prevData) =>
        prevData.filter((category) => category._id !== selectedRow._id)
      );
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    setSelectedRow(value);
    setDrawerState(true);
    setFormType("EDIT");
  };
  const handleAgreeDelete = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
    setFormType("ADD");
  };
  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const get_list_product_review = async () => {
    const response = await _list_reviews_with_product_id(product_review_id);
    if (response.code === 200) {
      setProductReviewData(response.productreviews);
      setProductCommonName(response.product.common_name);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    get_list_product_review();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
          <span className="page_title">{productCommonName}</span>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <h3>Product Reviews</h3>
          </div>
          <div className="col-6 text-end">
            <button
              className="small-contained-button"
              onClick={handleOpenDrawer}
            >
              Add Product Review
            </button>
          </div>

          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={productReviewData}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Review`}
        componentToPassDown={
          <AddOrUpdateProductReview
            product_review_id={product_review_id}
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            formType={formType}
            setCategoryData={setProductReviewData}
            productReviewData={productReviewData}
            selectedRow={selectedRow}
          />
        }
      />
      <CustomCofirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Product Review ?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
