import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { _list_shipment, _delete_shipment } from "../../DAL/Shipment/shipment";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import InvoiceDeposit from "../../components/shipments/InvoiceDeposit";
import CloseInvoice from "../../components/shipments/CloseInvoice";

import AddShipment from "./AddShipment";
import EditShipment from "./EditShipment";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
export default function Shipments() {
  const [isLoading, setIsLoading] = useState(true);
  const [shipmentData, setShipmentData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [EditValue, setEditValue] = useState();
  const navigate = useNavigate();
  const [editDraerState, seteditDraerState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { privilegeAccess, userInfo } = useContentSetting();
  const [drawerState, setDrawerState] = useState(false);
  const [invoiceDrawerState, setInvoiceDrawerState] = useState(false);
  const [closeInvoice, setCloseInvoice] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await _delete_shipment(selectedRow);

    if (result.code === 200) {
      let new_shipment_list = shipmentData.filter((shipment) => {
        return shipment._id !== selectedRow;
      });
      setShipmentData(new_shipment_list);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    setEditValue(value);
    handleOpenEditedDrawer();
  };

  const handleShipmentOrders = (value) => {
    navigate(`/shipment/shipment-items/${value._id}`, {
      state: { shipmentValues: value },
    });
  };
  const handleShipmentTransit = (value) => {
    navigate(`/shipment/shipment-transit/${value._id}`, {
      state: { shipmentValues: value },
    });
  };
  const handleShipmentReceived = (value) => {
    navigate(`/shipment/shipment-received/${value._id}`, {
      state: { shipmentValues: value },
    });
  };

  const handleAgreeDelete = (value) => {
    setSelectedRow(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };

  const handleInvoiceDeposit = (value) => {
    setSelectedRow(value);
    setInvoiceDrawerState(true);
  };
  const handleCloseInvoice = (value) => {
    setSelectedRow(value);
    setCloseInvoice(true);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseInvoiceDrawer = () => {
    setInvoiceDrawerState(false);
  };
  const handleCloseInvoiceCloseDrawer = () => {
    setCloseInvoice(false);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const get_shipment_list = async () => {
    setIsLoading(true);
    const response = await _list_shipment(page, rowsPerPage);
    if (response.code === 200) {
      let data = response.Shipment.map((shipment) => {
        let shipmentTitle = "N/A";
        if (shipment.shipment_title) {
          shipmentTitle = shipment.shipment_title;
        }
        return {
          ...shipment,
          shipmentTitle,
          type:
            shipment.shipment_type.charAt(0).toUpperCase() +
            shipment.shipment_type.slice(1),
          vendor_name:
            shipment.vendor_info?.first_name +
              " " +
              shipment.vendor_info?.last_name || "",
        };
      });
      setTotalCount(response.total_count);
      setShipmentData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "shipmentTitle", label: "Shipment Title", alignRight: false },
    { id: "shipment_id", label: "Shipment Id", alignRight: false },
    { id: "vendor_name", label: "Vendor", alignRight: false },
    { id: "type", label: "Shipment Type", alignRight: false },
    { id: "shippment_date", label: "Shipment Date", alignRight: false },
  ];
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
      is_access: userInfo.type == 0 ? true : privilegeAccess.edit_shipment,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      is_access: userInfo.type == 0 ? true : privilegeAccess.delete_shipment,
    },
    {
      label: "Confirm Shipment",
      icon: "akar-icons:shipping-box-v2",
      handleClick: handleShipmentOrders,
    },
    {
      label: "Invoice Deposit",
      icon: "mdi:invoice-edit-outline",
      handleClick: handleInvoiceDeposit,
    },
    {
      label: "Shipment Transit",
      icon: "wpf:in-transit",
      handleClick: handleShipmentTransit,
    },
    {
      label: "Shipment Received",
      icon: "mdi:package-variant-closed-delivered",
      handleClick: handleShipmentReceived,
    },
    {
      label: "Close Invoice",
      icon: "mdi:invoice-check-outline",
      handleClick: handleCloseInvoice,
    },
  ];

  const handleOpenDraweradd = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_shipment === true) {
        setDrawerState(true);
      }
    } else {
      setDrawerState(true);
    }
  };

  const handleOpenEditedDrawer = () => {
    seteditDraerState(true);
  };
  const handlecloseEditedDrawer = () => {
    seteditDraerState(false);
  };
  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleOpenInvoiceDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleOpenInvoiceCloseDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    get_shipment_list();
  }, [page, rowsPerPage]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Shipment</h3>
          </div>
          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.add_shipment === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow
            >
              <button
                className="small-contained-button"
                onClick={handleOpenDraweradd}
              >
                Add Shipment
              </button>
            </Tooltip>
          </div>
        </div>

        {userInfo.type == 2 && !privilegeAccess.view_shipment ? (
          <NoAccessData />
        ) : (
          <div className="row">
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={shipmentData}
                MENU_OPTIONS={MENU_OPTIONS}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
              />
            </div>
          </div>
        )}
      </div>

      <CustomDrawer
        isOpenDrawer={invoiceDrawerState}
        onOpenDrawer={handleOpenInvoiceDrawer}
        onCloseDrawer={handleCloseInvoiceDrawer}
        pageTitle={`Invoice Deposit`}
        componentToPassDown={
          <InvoiceDeposit
            selectedRow={selectedRow}
            onCloseDrawer={handleCloseInvoiceDrawer}
            apiFunction={get_shipment_list}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={closeInvoice}
        onOpenDrawer={handleOpenInvoiceCloseDrawer}
        onCloseDrawer={handleCloseInvoiceCloseDrawer}
        pageTitle={`Close Invoice`}
        componentToPassDown={
          <CloseInvoice
            selectedRow={selectedRow}
            onCloseDrawer={handleCloseInvoiceCloseDrawer}
            apiFunction={get_shipment_list}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Shipment?"}
        handleAgree={handleDelete}
      />
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Add Shipment"
        componentToPassDown={
          <AddShipment
            apiFunction={get_shipment_list}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={editDraerState}
        onOpenDrawer={handleOpenEditedDrawer}
        onCloseDrawer={handlecloseEditedDrawer}
        pageTitle="Edit Shipment"
        componentToPassDown={
          <EditShipment
            data={EditValue}
            apiFunction={get_shipment_list}
            onCloseDrawer={handlecloseEditedDrawer}
          />
        }
      />
    </>
  );
}
