import {
  FormControl,
  TextField,
  Typography,
  InputLabel,
  Tooltip,
  MenuItem,
  Select,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect } from "react";

export default function OrderPrepareItems({
  setaddArray,
  addArray,
  handleAdd,
  handleDelete,
  items,
  index,
  orderPrepared,
}) {
  const handleChangeItems = (e, array_index) => {
    const { name, value } = e.target;
    addArray[index].shipment[array_index][name] = value;
    addArray[index]["product_id"] = items.product_id._id;
    addArray[index]["variation"] = items.variation;
    setaddArray([...addArray]);

    if (name === "_id") {
      for (let i = 0; i < items.product_id.shipment.length; i++) {
        for (let j = 0; j < addArray[index].shipment.length; j++) {
          if (
            items.product_id.shipment[i]._id === addArray[index].shipment[j]._id
          ) {
            const availableQuanity =
              items.product_id.shipment[i].available_quantity;
            addArray[index].shipment[j].available_quantity = availableQuanity;
          }
        }
      }
    }
  };
  useEffect(() => {
    if (items.product_id.variation?.length > 0) {
      for (let i = 0; i < items.product_id.variation.length; i++) {
        if (items.product_id.variation[i]._id === items.variation) {
          const variation = items.product_id.variation[i].attributes_items[1]
            ? items.product_id.variation[i].attributes_items[0].item_name +
              "/" +
              items.product_id.variation[i].attributes_items[1].item_name
            : items.product_id.variation[i].attributes_items[0].item_name;
          addArray[index].variationName = variation;
        }
      }
      setaddArray([...addArray]);
    }
  }, []);
  return (
    <>
      <Typography>{`Item ${index + 1}`}</Typography>
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-lg-6 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic-2"
                label="Product Name"
                variant="outlined"
                sx={{ width: "100%" }}
                disabled
                name="product_name"
                value={
                  items.product_id?.common_name
                    ? items.product_id.common_name +
                      "(" +
                      items.product_id.scientific_name +
                      ")"
                    : ""
                }
              />
            </FormControl>
          </div>
          <div className="col-12 col-lg-6 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic-2"
                label="Quantity"
                variant="outlined"
                sx={{ width: "100%" }}
                disabled
                name="quantity"
                value={items.quantity}
              />
            </FormControl>
          </div>
          <div className="col-12 col-lg-6 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic-2"
                label="Variation"
                variant="outlined"
                sx={{ width: "100%" }}
                disabled
                value={addArray[index].variationName}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="col-12">
        {addArray[index].shipment?.map((data, array_index) => {
          return (
            <div key={array_index}>
              <div className="row">
                {/* shipment */}
                <div className="col-12 col-lg-6 mt-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Shipment*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="_id"
                      disabled={orderPrepared}
                      value={data._id}
                      required
                      label="Shipment"
                      onChange={(e) => handleChangeItems(e, array_index)}>
                      {items.product_id.shipment?.map((shipments, index) => (
                        <MenuItem key={shipments._id} value={shipments._id}>
                          {shipments.shipment_id
                            ? shipments.shipment_id +
                              " (" +
                              shipments.shippment_date +
                              ")"
                            : shipments.shippment_date}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/* available quantity */}
                <div className="col-12 col-lg-6 mt-3">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic-21"
                      label="Available Quantity"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      disabled
                      name="available_quantity"
                      value={data.available_quantity}
                    />
                  </FormControl>
                </div>
                {/* quantity */}
                <div className="col-12 col-lg-6 mt-3 mb-2">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic-2"
                      label="Quantity"
                      disabled={orderPrepared}
                      variant="outlined"
                      sx={{ width: "100%" }}
                      required
                      name="quantity"
                      onKeyPress={(event) => {
                        if (
                          !/^[0-9]\d*$/.test(event.target.value + event.key) ||
                          parseInt(event.target.value + event.key) >
                            items.quantity
                        ) {
                          event.preventDefault();
                        }
                      }}
                      value={data.quantity}
                      onChange={(e) => handleChangeItems(e, array_index)}
                    />
                  </FormControl>
                </div>

                {!orderPrepared && (
                  <span className="col-12 text-end mb-1">
                    {addArray[index].shipment.length > 1 ? (
                      <Tooltip title="Remove">
                        <RemoveCircleOutlineIcon
                          onClick={() => handleDelete(array_index, index)}
                          className="diary-icon-remove"
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}

                    <Tooltip title="Add">
                      <AddCircleOutlineIcon
                        onClick={() => handleAdd(array_index, index)}
                        className="diary-icon-add"
                      />
                    </Tooltip>
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
