import React, { useState } from "react";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
import EmailVerification from "../../components/forgotPassword/EmailVerification";
import PincodeVerification from "../../components/forgotPassword/PincodeVerification";
import ChangePassword from "../../components/forgotPassword/ChangePassword";
import { login } from "../../assets";

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitEmail = () => {
    // setAdminEmail(_email);
    // const formData = new FormData();
    // formData.append("email", _email);
    // const result = await confirmEmail(formData);
    // if (result.code === 200) {
    //   enqueueSnackbar(result.message, { variant: "success" });
    //   setIsLoading(false);
    setStatus(1);
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const handleSubmitPinCode = () => {
    // const formData = new FormData();
    // formData.append("email", adminEmail);
    // formData.append("verification_code", pinCode);
    // const result = await confirmPinCode(formData);
    // if (result.code === 200) {
    //   enqueueSnackbar(result.message, { variant: "success" });
    //   setIsLoading(false);
    setStatus(2);
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const handleSubmitNewPassword = () => {
    // const formData = new FormData();
    // formData.append("email", adminEmail);
    // formData.append("password", newPassword);
    // formData.append("confirm_password", confirmPassword);
    // const result = await updatePassword(formData);
    // if (result.code === 200) {
    setStatus(0);
    //   navigate("/login");
    //   enqueueSnackbar("Password Changed Successfully", { variant: "success" });
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };
  return (
    <div>
      <div className="container">
        <div className="row w-100">
          <div className="col-sm-5 pt-5">
            <div className="container">
              <div className="card image-container">
                <Typography
                  variant="h3"
                  sx={{ px: 5, mt: 10, mb: 5, fontSize: "2.5vw" }}>
                  Hi, Welcome Back
                </Typography>
                <img src={login} alt="Login" />
              </div>
            </div>
          </div>
          <div className="col-sm-7 pt-5">
            <div className="container">
              <div className="form-container">
                <Stack sx={{ mb: 3 }}>
                  {status === 0 ? (
                    <>
                      <Typography variant="h5" gutterBottom>
                        Forget Password
                      </Typography>
                      <Typography sx={{ color: "text.secondary" }}>
                        Enter your Email.
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                  {status === 1 ? (
                    <>
                      <Typography variant="h5" gutterBottom>
                        Please Check your Email
                      </Typography>
                      <Typography sx={{ color: "text.secondary" }}>
                        Enter PIN Code here.
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                  {status === 2 ? (
                    <>
                      <Typography variant="h5" gutterBottom>
                        Forget Password
                      </Typography>
                      <Typography sx={{ color: "text.secondary" }}>
                        Enter your New Password.
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                </Stack>
                {status === 0 ? (
                  <EmailVerification
                    onhandleEmailSubmit={handleSubmitEmail}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                  />
                ) : (
                  ""
                )}
                {status === 1 ? (
                  <PincodeVerification
                    onhandlePinCodeSubmit={handleSubmitPinCode}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                  />
                ) : (
                  ""
                )}
                {status === 2 ? (
                  <ChangePassword
                    onhandleNewPasswordSubmit={handleSubmitNewPassword}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
