import { TextField, Typography } from "@mui/material";
import { useState } from "react";
import React from "react";
import { useSnackbar } from "notistack";
import { _change_order_status } from "../../DAL/orders/orders";

function Reason({ setOpenModel, apiFunction, statusReason, orderId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    reason: "",
  });

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const objectData = {
      order_status: statusReason,
    };
    if (statusReason == "returned") {
      objectData.return_reason = inputs.reason;
    } else {
      objectData.cancellation_reason = inputs.reason;
    }
    const result = await _change_order_status(objectData, orderId);
    if (result.code === 200) {
      apiFunction();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setOpenModel(false);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {/* {statusReason == "returned" ? (
        <Typography sx={{ mt: 2 }}>Do you want to Return the Order?</Typography>
      ) : (
        <Typography sx={{ mt: 2 }}>Do you want to Cancel the Order?</Typography>
      )} */}
      <TextField
        name="reason"
        value={inputs.reason}
        onChange={handleChange}
        fullWidth
        label={
          statusReason == "returned"
            ? "Reason for Returning Order"
            : "Reason for Cancelling Order"
        }
        multiline
        rows={5}
        className="form-control mt-3"
        required
      />
      <div className="text-end mt-3">
        <button className="small-contained-button" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
}

export default Reason;
