import { invokeApi } from "../../bl_libs/invokeApi";

export const _list_shipment = async (page, limit) => {
  let requestObj = {
    path: `api/shipment/list_shipment?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_shipment = async (data) => {
  console.log(data, "data");
  const requestObj = {
    path: `api/shipment/create_shipment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_shipment = async (id) => {
  const requestObj = {
    path: `api/shipment/delete_shipment/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _edit_shipment = async (postData, _id) => {
  const requestObj = {
    path: `api/shipment/edit_shipment/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _detail_shipment = async (id) => {
  const requestObj = {
    path: `api/shipment/detail_shipment/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _list_shipment_by_product = async (id) => {
  const requestObj = {
    path: `api/shipment/list_shippment_by_product_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
