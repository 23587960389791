import { useState } from "react";
// material
import { Stack, TextField } from "@mui/material";
import { Button, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

// ----------------------------------------------------------------------

export default function EmailVerification({
  onhandleEmailSubmit,
  setIsLoading,
  isLoading,
}) {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    onhandleEmailSubmit();
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="username"
          type="email"
          label="Email address"
        />
      </Stack>

      <div className="text-end">
        <Stack>
          <Typography variant="body2" sx={{ mt: 3 }}>
            Back to Login?&nbsp;
            <Link variant="subtitle2" component={RouterLink} to="/login">
              Login
            </Link>
          </Typography>
        </Stack>
      </div>

      <Stack>
        <Button fullWidth size="large" type="submit" variant="contained">
          Submit
        </Button>
      </Stack>
    </form>
  );
}
