export const ORDER_STATUSES = [
  {
    status: "pending",
    color: "#ff9800",
  },
  {
    status: "ready_to_ship",
    color: "#03a9f4",
  },
  {
    status: "shipped",
    color: "#0288d1",
  },
  {
    status: "delivered",
    color: "#4caf50",
  },
  {
    status: "cancelled",
    color: "#cccccc",
  },
  {
    status: "returned",
    color: "#f01e2c",
  },
];

export let find_order_color = (status) => {
  let order_status = ORDER_STATUSES.find(
    (ORDER_STATUS) => ORDER_STATUS.status == status
  );
  return order_status.color;
};
