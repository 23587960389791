import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";

import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import {
  _list_shipment_items_by_shipment,
  edit_shipment_received_bulk,
  make_product_status_active,
} from "../../DAL/Shipment/shipment_items/shipment_items";
import {
  _detail_shipment,
  _update_shipment_received,
} from "../../DAL/Shipment/shipment_received/shipment_received";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import AddShipmentReceived from "../../components/shipments/shipment_received/AddShipmentReceived";
import { _delete_shipment_item } from "../../DAL/Shipment/shipment_items/shipment_items";
import { useSnackbar } from "notistack";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";

export default function ShipmentReceived() {
  const add_new_ref = useRef(null);
  const { shipment_id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [close_invoice, setClose_invoice] = useState({});
  const [shipmentItems, setShipmentItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedReceive, setSelectedReceive] = useState([]);
  const [isAllFiltered, setIsAllFiltered] = useState(false);
  const [selectedTemp, setSelectedTemp] = useState([]);

  const [values, setValues] = useState({
    shipment_id: "",
    vendor_name: "",
    shipment_type: "",
    live_stock_total_cost: 0,
    total_shipment_cost: 0,
    total_extra_expense_cost: 0,
    complete_cost_for_shipment: 0,
    shipment_title: "",
  });
  const [inputs, setInputs] = useState({
    product_id: "",
    shipment_item_id: "",
    product_name: "",
    total_quantity: 0,
    per_unit_cost: 0,
    total_cost: 0,
    landed_cost: 0,
    dead_count: 0,
    cost_per_item: 0,
    live_count: 0,
    attributes_items: [],
    refund_amount: 0,
    is_received: false,
  });

  const handleBack = () => {
    navigate(`/shipment`);
  };
  const handleOpenDraweradd = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const get_shipment_items_info = async () => {
    setIsLoading(true);
    const response = await _list_shipment_items_by_shipment(shipment_id);

    if (response.code === 200) {
      let data = response.shipment_item.map((item) => {
        let unit_expense_landed_cost = 0;
        if (item.dead_count == item.total_quantity) {
          unit_expense_landed_cost = 0;
        } else {
          unit_expense_landed_cost = (
            parseFloat(item.total_cost) /
            (parseFloat(item.total_quantity) - parseFloat(item.dead_count))
          ).toFixed(2);
        }
        let shipment_unit_cost = 0;
        if (item.dead_count == item.total_quantity) {
          shipment_unit_cost = 0;
        } else {
          shipment_unit_cost = (
            parseFloat(item.per_unit_cost) *
              (parseFloat(item.total_quantity) - parseFloat(item.dead_count)) +
            (parseFloat(item.total_cost) /
              (parseFloat(item.total_quantity) - parseFloat(item.dead_count))) *
              parseFloat(item.total_quantity)
          ).toFixed(2);
        }

        return {
          ...item,
          is_hide_selection: item.product_status,
          attribute1: item.attributes_items[0]?.attribute_name
            ? item.attributes_items[0]?.attribute_name +
              " | " +
              item.attributes_items[0]?.item_name
            : "",
          attribute2: item.attributes_items[1]?.attribute_name
            ? item.attributes_items[1]?.attribute_name +
              " | " +
              item.attributes_items[1]?.item_name
            : "",
          totalCost: "$" + item.total_cost,
          perUnitCost: "$" + item.per_unit_cost,

          landedCost: "$" + unit_expense_landed_cost,
          costPerItem: "$" + shipment_unit_cost,
          refundAmount: "$" + item.refund_amount,
        };
      });
      setShipmentItems(data);
      setSelectedTemp(data);
      setClose_invoice(response.close_invoice);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const get_shipment_items_info_no_loader = async () => {
    const response = await _list_shipment_items_by_shipment(shipment_id);
    if (response.code === 200) {
      let data = response.shipment_item.map((item) => {
        let unit_expense_landed_cost = 0;
        if (item.dead_count == item.total_quantity) {
          unit_expense_landed_cost = 0;
        } else {
          unit_expense_landed_cost = (
            parseFloat(item.total_cost) /
            (parseFloat(item.total_quantity) - parseFloat(item.dead_count))
          ).toFixed(2);
        }
        let shipment_unit_cost = 0;
        if (item.dead_count == item.total_quantity) {
          shipment_unit_cost = 0;
        } else {
          shipment_unit_cost = (
            parseFloat(item.per_unit_cost) *
              (parseFloat(item.total_quantity) - parseFloat(item.dead_count)) +
            (parseFloat(item.total_cost) /
              (parseFloat(item.total_quantity) - parseFloat(item.dead_count))) *
              parseFloat(item.total_quantity)
          ).toFixed(2);
        }

        return {
          ...item,
          attribute1: item.attributes_items[0]?.attribute_name
            ? item.attributes_items[0]?.attribute_name +
              " | " +
              item.attributes_items[0]?.item_name
            : "",
          attribute2: item.attributes_items[1]?.attribute_name
            ? item.attributes_items[1]?.attribute_name +
              " | " +
              item.attributes_items[1]?.item_name
            : "",
          totalCost: "$" + item.total_cost,
          perUnitCost: "$" + item.per_unit_cost,
          landedCost: "$" + unit_expense_landed_cost,
          costPerItem: "$" + shipment_unit_cost,
          refundAmount: "$" + item.refund_amount,
          is_received: item?.is_received
            ? item?.is_received == true
              ? "Yes"
              : "No"
            : "No",
        };
      });
      setShipmentItems(data);
      setClose_invoice(response.close_invoice);
      get_detail_shipment_values();
      get_detail_shipment();
    } else {
    }
  };

  const get_detail_shipment = async () => {
    setIsLoading(true);
    const response = await _detail_shipment(shipment_id);
    if (response.code === 200) {
      const vendorName =
        response.Shipment.vendor_info.first_name +
        response.Shipment.vendor_info.last_name;
      const completeCost =
        response.Shipment.live_stock_total_cost +
        response.Shipment.invoice_deposit.invoice_shipment_cost +
        response.Shipment.total_shipment_expense;
      setValues({
        shipment_id: response.Shipment.shipment_id,
        vendor_name: vendorName,
        shipment_type: response.Shipment.shipment_type,
        live_stock_total_cost: response.Shipment.live_stock_total_cost,
        total_shipment_cost:
          response.Shipment.invoice_deposit.invoice_shipment_cost,
        total_extra_expense_cost: response.Shipment.total_shipment_expense,
        shipment_title: response.Shipment.shipment_title,
        complete_cost_for_shipment: completeCost,
      });
      get_shipment_items_info();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const get_detail_shipment_values = async () => {
    const response = await _detail_shipment(shipment_id);

    if (response.code === 200) {
      const vendorName =
        response.Shipment.vendor_info.first_name +
        response.Shipment.vendor_info.last_name;
      const completeCost =
        response.Shipment.live_stock_total_cost +
        response.Shipment.invoice_deposit.invoice_shipment_cost +
        response.Shipment.total_shipment_expense;
      setValues({
        shipment_id: response.Shipment.shipment_id,
        vendor_name: vendorName,
        shipment_type: response.Shipment.shipment_type,
        live_stock_total_cost: response.Shipment.live_stock_total_cost,
        total_shipment_cost:
          response.Shipment.invoice_deposit.invoice_shipment_cost,
        total_extra_expense_cost: response.Shipment.total_shipment_expense,
        complete_cost_for_shipment: completeCost,
      });
    } else {
    }
  };
  const handleAgreeDelete = (value) => {
    setSelectedItem(value);
    setOpenDelete(true);
  };

  const handleDeleteItem = async (id) => {
    setOpenDelete(false);
    let objectData = {
      shipment_item_id: selectedItem._id,
    };
    const result = await _delete_shipment_item(objectData, shipment_id);
    if (result.code === 200) {
      get_shipment_items_info_no_loader();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleEdit = (value) => {
    const refund = (
      parseFloat(value.dead_count) * parseFloat(value.per_unit_cost)
    ).toFixed(2);
    const unitCost =
      value.dead_count == value.total_quantity
        ? 0
        : (
            parseFloat(value.per_unit_cost) *
              (parseFloat(value.total_quantity) -
                parseFloat(value.dead_count)) +
            (parseFloat(value.total_cost) /
              (parseFloat(value.total_quantity) -
                parseFloat(value.dead_count))) *
              parseFloat(value.total_quantity)
          ).toFixed(2);
    const landed =
      value.dead_count == value.total_quantity
        ? 0
        : (
            parseFloat(value.total_cost) /
            (parseFloat(value.total_quantity) - parseFloat(value.dead_count))
          ).toFixed(2);

    setInputs({
      product_id: value.product_id,
      shipment_item_id: value._id,
      product_name: value.product_name,
      total_quantity: value.total_quantity,
      total_cost: value.total_cost,
      per_unit_cost: value.per_unit_cost,
      landed_cost: landed,
      dead_count: value.dead_count,
      cost_per_item: unitCost,
      live_count: value.live_count,
      attributes_items: value.attributes_items,
      refund_amount: refund,
      is_received: true,
    });
    setFormType("Edit");
    setTimeout(() => {
      add_new_ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value,
    }));
    if (name === "dead_count" && value != "") {
      const deadCount = name === "dead_count" ? value : inputs.dead_count;
      const totalQuantity = inputs.total_quantity;
      const perUnitCost = inputs.per_unit_cost;
      const refundAmount = (
        parseFloat(deadCount) * parseFloat(perUnitCost)
      ).toFixed(2);
      const liveCount = parseFloat(totalQuantity) - parseFloat(deadCount);
      const unitCost =
        deadCount == totalQuantity
          ? 0
          : (
              parseFloat(perUnitCost) *
                (parseFloat(totalQuantity) - parseFloat(deadCount)) +
              (parseFloat(inputs.total_cost) / liveCount) *
                parseFloat(totalQuantity)
            ).toFixed(2);
      const landed =
        deadCount == totalQuantity
          ? 0
          : (parseFloat(inputs.total_cost) / liveCount).toFixed(2);

      setInputs((values) => ({
        ...values,
        live_count: liveCount,
        refund_amount: refundAmount,
        cost_per_item: unitCost,
        landed_cost: landed,
      }));
    }
  };
  const handleSubmit = async (e) => {
    setIsLoadingForm(true);
    e.preventDefault();
    const objectData = {
      product_id: inputs.product_id,
      shipment_item_id: inputs.shipment_item_id,
      landed_cost: inputs.landed_cost,
      dead_count: inputs.dead_count,
      cost_per_item: inputs.cost_per_item,
      live_count: inputs.total_quantity - inputs.dead_count,
      refund_amount: inputs.refund_amount,
      is_received: true,
    };
    if (
      inputs.dead_count <= inputs.total_quantity ||
      (inputs.dead_count == 0 && inputs.dead_count != "")
    ) {
      const result = await _update_shipment_received(objectData, shipment_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoadingForm(false);
        setFormType("ADD");
        get_shipment_items_info_no_loader();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingForm(false);
      }
    } else {
      if (inputs.landed_cost == "") {
        enqueueSnackbar("Dead quantity can not be  empty ", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          "Dead quantity can not be greater than total quantity ",
          {
            variant: "error",
          }
        );
      }
      setIsLoadingForm(false);
    }
  };

  const handleSubmitStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const product_id = [
      ...new Set(selected.map((product) => product.product_id)),
    ];
    let postData = {
      product_id: product_id,
    };
    const result = await make_product_status_active(postData);
    if (result.code === 200) {
      get_detail_shipment();
      setSelected([]);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmitReceived = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      shipment_items: selectedReceive,
    };
    const result = await edit_shipment_received_bulk(shipment_id, postData);
    if (result.code === 200) {
      get_detail_shipment();
      setSelectedReceive([]);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeAll = (e) => {
    let is_checked = e.target.checked;
    setIsAllFiltered(is_checked);
    if (is_checked) {
      setSelectedReceive(
        selectedTemp.filter((item) => item.is_received == false)
      );
    } else {
      setSelectedReceive([]);
    }
  };

  const handleCheckboxChange = (e, row) => {
    let is_checked = e.target.checked;
    if (is_checked) {
      setSelectedReceive((old) => [...old, row]);
    } else {
      setSelectedReceive((old) => old.filter((item) => item._id !== row._id));
    }
  };

  const TABLE_HEAD = [
    {
      id: "is_received",
      label: (
        <FormControlLabel
          control={<Checkbox checked={isAllFiltered} />}
          label="Shipment"
          onChange={handleChangeAll}
        />
      ),
      renderData: (row) => {
        const find_check = selectedReceive.some((item) => item._id === row._id);
        return (
          <>
            {row.is_received == false && (
              <div>
                <Checkbox
                  checked={find_check}
                  onChange={(e) => handleCheckboxChange(e, row)}
                />
              </div>
            )}
          </>
        );
      },
    },
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "product_name",
      label: "Product Name",
    },
    {
      id: "product_status",
      label: "Product Status",
      type: "row_status",
    },
    {
      id: "is_received",
      label: "Received",
      renderData: (row) => {
        return (
          <>
            {row.is_received == true ? (
              <Tooltip title="Item Received">
                <Chip label="Yes" color="success" />
              </Tooltip>
            ) : (
              <Tooltip title="Item Not Received !">
                <Chip label="No" color="error" />
              </Tooltip>
            )}
          </>
        );
      },
    },
    { id: "attribute1", label: "Attribute 1", alignRight: false },
    { id: "attribute2", label: "Attribute 2", alignRight: false },
    { id: "total_quantity", label: "Total Quantity", alignRight: false },
    { id: "perUnitCost", label: "Vendor Price Per Item", alignRight: false },
    { id: "totalCost", label: "Vendor Total Price", alignRight: false },
    { id: "dead_count", label: "Dead on Arrival", alignRight: false },
    { id: "landedCost", label: "Shipment Unit Expense", alignRight: false },
    { id: "costPerItem", label: "Shipment Unit Cost", alignRight: false },
    { id: "refundAmount", label: "Refund Amount", alignRight: false },
  ];

  useEffect(() => {
    get_detail_shipment();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton onClick={handleBack} className="icon-button">
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
          </div>
          <div className="col-6 my-3">
            <h3>Shipment Received</h3>
          </div>
          <div className="col-6 text-end my-1">
            <button
              className="small-contained-button"
              onClick={handleOpenDraweradd}
            >
              Add Shipment Received Item
            </button>
          </div>
        </div>
        <div className="row mx-1">
          <div className="card mb-4">
            <div className="row align-items-center mb-3">
              {values.shipment_title && (
                <div className="col-xs-12 col-md-4 mt-3 d-flex">
                  <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                    Shipment Title:{" "}
                  </Typography>
                  <Typography>{values.shipment_title}</Typography>
                </div>
              )}
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Shipment Id:{" "}
                </Typography>
                <Typography>{values.shipment_id}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Vendor Name:{" "}
                </Typography>
                <Typography>{values.vendor_name}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Shipment Type:{" "}
                </Typography>
                <Typography>{values.shipment_type}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Total Invoice Cost:{" "}
                </Typography>
                <Typography>{`$${values.live_stock_total_cost}`}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Total Shipping Cost:{" "}
                </Typography>
                <Typography>{`$${values.total_shipment_cost}`}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Total Extra Expense Cost:{" "}
                </Typography>
                <Typography>{`$${values.total_extra_expense_cost}`}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Complete Cost For Shipment:{" "}
                </Typography>
                <Typography>{`$${values.complete_cost_for_shipment}`}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Invoice Status:{" "}
                </Typography>
                <Typography>
                  {close_invoice?.paid_status
                    ? close_invoice?.paid_status === "unpaid"
                      ? "Unpaid"
                      : "Paid"
                    : "Invoice Not Closed"}
                </Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Invoice Refund Type:{" "}
                </Typography>
                <Typography>
                  {close_invoice?.refund_type
                    ? close_invoice?.refund_type === "vendor_credit"
                      ? "Vendor Credit"
                      : "Refund"
                    : "Invoice Not Closed"}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        {formType !== "ADD" && (
          <div className="row mx-1" ref={add_new_ref}>
            <div className="card mb-4">
              <Typography className="shipment-received-cards">
                Edit Shipment Received Item
              </Typography>
              <form
                className="new-variation-form"
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="row align-items-center">
                  <div className="col-xs-12 col-md-4 mt-3">
                    <TextField
                      name="product"
                      value={inputs.product_name}
                      fullWidth
                      label="Product"
                      className="form-control"
                      disabled
                    />
                  </div>
                  {inputs?.attributes_items?.length > 0 &&
                    inputs?.attributes_items.map((attribute, index) => {
                      return (
                        <div className="col-xs-12 col-md-4 mt-3" key={index}>
                          <TextField
                            name={attribute.attribute_name}
                            value={attribute.item_name}
                            fullWidth
                            label={attribute.attribute_name}
                            className="form-control"
                            disabled
                          />
                        </div>
                      );
                    })}

                  <div className="col-xs-12 col-md-4 mt-3">
                    <TextField
                      name="total_quantity"
                      value={inputs.total_quantity}
                      fullWidth
                      label="Total Quantity"
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 mt-3">
                    <TextField
                      value={inputs.total_cost}
                      name="item_total_cost"
                      fullWidth
                      type="number"
                      label="Vendor Total Price"
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 mt-3">
                    <TextField
                      value={inputs.per_unit_cost}
                      name="per_unit_cost"
                      fullWidth
                      type="number"
                      label="Vendor Price Per Item"
                      disabled
                      className="form-control"
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 mt-3">
                    <TextField
                      name="dead_count"
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      label="Dead on Arrival"
                      className="form-control"
                      value={inputs.dead_count}
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 mt-3">
                    <TextField
                      value={inputs.landed_cost}
                      name="landed_cost"
                      fullWidth
                      type="number"
                      label="Shipment Unit Expense"
                      className="form-control"
                      // onChange={handleChange}
                      disabled
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 mt-3">
                    <TextField
                      value={inputs.cost_per_item}
                      name="cost_per_item"
                      fullWidth
                      disabled
                      type="number"
                      label="Shipment Unit Cost"
                      className="form-control"
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 mt-3">
                    <TextField
                      value={inputs.refund_amount}
                      name="refund_amount "
                      fullWidth
                      disabled
                      type="number"
                      label="Refund Amount "
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="my-3 text-end">
                  <button
                    className="small-contained-button"
                    type="submit"
                    disabled={isLoadingForm}
                  >
                    {isLoadingForm
                      ? formType === "ADD"
                        ? "Submitting..."
                        : "Updating..."
                      : formType === "ADD"
                      ? "Submit"
                      : "Update"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="row mx-1 card">
          {selected.length > 0 && (
            <div class="text-end mt-4 fixedbutton">
              <button
                class="small-contained-button"
                onClick={handleSubmitStatus}
              >
                Make Product Active
              </button>
            </div>
          )}
          {selectedReceive.length > 0 && (
            <div class="text-end mt-4 fixedbutton2">
              <button
                class="small-contained-button"
                onClick={handleSubmitReceived}
              >
                Shipment Confirmation
              </button>
            </div>
          )}
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            selected={selected}
            setSelected={setSelected}
            selected_by="product_id"
            checkbox_selection={true}
            data={shipmentItems}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
          />
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Add Shipment Received Item"
        componentToPassDown={
          <AddShipmentReceived
            apiFunction={get_shipment_items_info_no_loader}
            onCloseDrawer={handleCloseEditDrawer}
            setShipmentItems={setShipmentItems}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Item?"}
        handleAgree={handleDeleteItem}
      />
    </>
  );
}
