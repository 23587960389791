import { invokeApi } from "../../bl_libs/invokeApi";

export const _list_stock = async (page, limit) => {
  const requestObj = {
    // path: `api/product/list_whats_on_stock?page=${page}&limit=${limit}`,
    path: `api/product/list_whats_on_stock`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
