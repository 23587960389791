import { invokeApi } from "../../../bl_libs/invokeApi";

export const _list_shipment_items_by_shipment = async (shipment_id) => {
  let requestObj = {
    path: `api/shipment_item/list_shipment_item/${shipment_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const make_product_status_active = async (data) => {
  const requestObj = {
    path: `api/product/make_product_status_active`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_shipment_received_bulk = async (shipment_id, data) => {
  const requestObj = {
    path: `api/shipment_received/edit_shipment_received_bulk/${shipment_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_shipment_item = async (data) => {
  const requestObj = {
    path: `api/shipment_item/create_shipment_item`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_shipment_item = async (data, shipment_id) => {
  const requestObj = {
    path: `api/shipment_item/delete_shipment_item/${shipment_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_shipment_item = async (postData, shipment_id) => {
  const requestObj = {
    path: `api/shipment_item/edit_shipment_item/${shipment_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _detail_shipment = async (id) => {
  const requestObj = {
    path: `api/shipment/detail_shipment/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
