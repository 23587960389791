import React, { useEffect, useState } from "react";
import {
  _list_category,
  _delete_category,
} from "../../../../DAL/category/category";
import CustomDrawer from "../../../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { CircularProgress, IconButton } from "@mui/material";
import CustomCofirmation from "../../../../components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../../../config/config";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import AddOrUpdateFaq from "../../../../components/ManageWebsite/Pages/Faqs/AddOrUpdateFaq";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "question_statement", label: "Question Statement", alignRight: false },
  { id: "answer", label: "Answer", alignRight: false },
  { id: "order", label: "Order", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
  { id: "action", label: "Action", alignRight: false, type: "action" },
];
const FaqListData = [
  {
    id: 1,
    question_statement: "Question Statement 1 ?",
    status: true,
    order: 1,
    answer: "Answer  ... 1",
  },
  {
    id: 2,
    question_statement: "Question Statement 2?",
    status: false,
    order: 2,
    answer: "Answer  ... 2",
  },
];

export default function FaqList() {
  const [formType, setFormType] = useState("ADD");
  const [isLoading, setIsLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [drawerState, setDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleDelete = async () => {};

  const handleEdit = (value) => {
    setSelectedRow(value);
    setDrawerState(true);
    setFormType("EDIT");
  };
  const handleAgreeDelete = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
    setFormType("ADD");
  };
  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container">
        <IconButton onClick={handleBack} className="icon-button">
          <Icon icon="akar-icons:arrow-left" />
        </IconButton>
        <div className="row mt-2">
          <div className="col-6">
            <h3>FAQs</h3>
          </div>
          <div className="col-6 text-end">
            <button
              className="small-contained-button"
              onClick={handleOpenDrawer}>
              Add FAQ
            </button>
          </div>

          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={FaqListData}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Edit"} FAQ`}
        componentToPassDown={
          <AddOrUpdateFaq
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            formType={formType}
            setFaqData={setFaqData}
            faqData={faqData}
            selectedRow={selectedRow}
          />
        }
      />
      <CustomCofirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this FAQ ?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
