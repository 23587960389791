import { invokeApi } from "../../bl_libs/invokeApi";

export const _add_employee = async (data) => {
  const requestObj = {
    path: `api/employee/create_employee`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _employe_list = async (data) => {
  const requestObj = {
    path: `api/employee/list_employee`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _change_employee_password = async (data, user_id) => {
  const requestObj = {
    path: `api/app_api/change_employee_password/${user_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _employee_delete = async (id) => {
  const requestObj = {
    path: `api/employee/delete_employee/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _edit_employee_Api = async (postData, _id) => {
  const requestObj = {
    path: `api/employee/edit_employee/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _employee_list_pagination = async (page, limit, search) => {
  const requestObj = {
    path: `api/employee/list_employee_with_pagination?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
