import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_whats_on_stock_setting = async (data) => {
  const requestObj = {
    path: `api/website_settings/get_what_on_stock_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _add_whats_on_stock_setting = async (data) => {
  const requestObj = {
    path: `api/website_settings/add_what_on_stock_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
