import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect } from "react";
import { _edit_vendor_Api } from "../../DAL/Vender/vender";
export default function EditVendors({ onCloseDrawer, data, apiFunction }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [previews, setPreviews] = useState("");
  const [image, setImage] = React.useState("");
  const [inputs, setInputs] = React.useState({
    first_name: "",
    last_name: "",
    address: "",
    contact_number: "",
    status: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("address", inputs.address);
    formData.append("status", inputs.status);
    formData.append("contact_number", inputs.contact_number);
    if (image) {
      formData.append("image", image);
    }
    // formData.append("image", image);
    setIsLoading(true);
    const result = await _edit_vendor_Api(formData, data._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      onCloseDrawer();
      apiFunction();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setPreviews(data.image?.src);
    setInputs({
      ...inputs,
      ["first_name"]: data?.first_name,
      ["last_name"]: data?.last_name,
      ["address"]: data?.address,
      ["status"]: data?.status,
      ["contact_number"]: data?.contact_number,
    });
  }, []);

  return (
    <>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="first_name"
                  value={inputs?.first_name}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="last_name"
                  value={inputs?.last_name}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Contact No."
                  type="number"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="contact_number"
                  value={inputs?.contact_number}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="address"
                  value={inputs?.address}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <p className="mt-2 mb-0">Upload Image (1000x670) </p>
            <div className="col-md-12 mt-2 d-flex">
              {previews && (
                <span className="preview mt-2">
                  <span onClick={handleRemove}>x</span>
                  <img src={previews} />
                </span>
              )}
              {previews == "" && (
                <span className="upload-button mt-2">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    name="affirmationImage"
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file">
                    <CloudUploadIcon />
                  </label>
                </span>
              )}
            </div>
            <div className="col-12 mt-3 mb-5 text-end">
              <button
                className="small-contained-button"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
