import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_extra_expanses_list = async (data) => {
    const requestObj = {
      path: `api/extra_expenses/list_extra_expanses`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };


  export const _add_extra_expanse = async (data) => {
    const requestObj = {
        path: `api/extra_expenses/add_extra_expanses`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
  export const _edit_extra_expanse = async (postData, _id) => {
        const requestObj = {
          path: `api/extra_expenses/edit_extra_expanses/${_id}`,
          method: "PUT",
          headers: {
            "x-sh-auth": localStorage.getItem("token"),
          },
          postData: postData,
        };
        return invokeApi(requestObj);
      };

  export const _delete_extra_expanse = async (id) => {
    const requestObj = {
      path: `api/extra_expenses/delete_extra_expanses/${id}`,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };