import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_website_faqs = async (page, limit) => {
  const requestObj = {
    path: `api/faq/list_faq?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_website_faqs = async (data) => {
  const requestObj = {
    path: `api/faq/add_faq`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_website_faqs = async (_id, postData) => {
  const requestObj = {
    path: `api/faq/edit_faq/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _detail_website_faqs = async (id) => {
  const requestObj = {
    path: `api/faq/detail_faq/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _delete_website_faqs = async (id) => {
  const requestObj = {
    path: `api/faq/delete_faq/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
