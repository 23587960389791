import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  _add_product,
  _list_child_categories,
  _add_image,
  _del_image,
} from "../../DAL/product/product";

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Card,
  Tooltip,
  Stack,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import { _list_category } from "../../DAL/category/category";
import { _list_attribute } from "../../DAL/AttributeVarients/AttributeVarients";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import Autocomplete from "@mui/material/Autocomplete";

function AddProducts() {
  const navigate = useNavigate();
  const { userInfo, privilegeAccess } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [categoriesData, setCategoriesData] = useState();
  const [AttributeData, setAttributeData] = useState([]);
  const [childValue, setChildValue] = useState([]);
  const [image, setImage] = useState([]);
  const [material, setMaterail] = useState([]);
  const [show1, setShow1] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);

  const [inputs, setInputs] = useState({
    common_name: "",
    scientific_name: "",
    short_description: "",
    detailed_description: "",
    status: "false",
    is_featured: "true",
    is_on_sale: true,
    discount_percentage: "",
    video_url: "",
    product_type: "plant",
    attributes: [],
    category: "",
    sub_category: "",
    discount_type: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleBack = () => {
    navigate(-1);
  };
  const get_category_list = async () => {
    const response = await _list_category();
    if (response.code === 200) {
      setCategoriesData(response.category);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });

      setIsLoading(false);
    }
  };
  const get_attribute_list = async () => {
    const response = await _list_attribute();
    if (response.code === 200) {
      setAttributeData(response.Attribute_Varient);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let attributes_array = [];
    if (inputs.attributes.length > 0) {
      inputs.attributes.map((item) => {
        attributes_array.push({
          attribute_id: item,
        });
      });
    }
    if (attributes_array.length < 1) {
      enqueueSnackbar("please select atleast one attribute variante", {
        variant: "error",
      });
    } else {
      const objectData = {
        common_name: inputs.common_name,
        scientific_name: inputs.scientific_name,
        short_description: inputs.short_description,
        detailed_description: inputs.detailed_description,
        is_featured: inputs.is_featured,
        is_on_sale: inputs.is_on_sale,
        discount_percentage: inputs.discount_percentage,
        discount_type: inputs.discount_type,
        video_url: inputs.video_url,
        product_type: inputs.product_type,
        status: inputs.status,
        attributes: attributes_array,
        images: material,
      };

      if (selectedParent != null) {
        objectData.category = selectedParent._id;
      }
      if (selectedChild != null) {
        objectData.sub_category = selectedChild._id;
      }

      if (attributes_array.length > 2) {
        enqueueSnackbar("Select only 2 attribute variants", {
          variant: "error",
        });
      }

      if (material.length > 0) {
        if (attributes_array.length <= 2) {
          setIsLoading(true);
          const result = await _add_product(objectData);

          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            setIsLoading(false);
            navigate(-1);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      } else {
        enqueueSnackbar("Product Image is Required", {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };

  const handleChildCategory = async (id) => {
    setSelectedChild(null);
    const result = await _list_child_categories(id);

    if (result.code === 200) {
      let new_category_list = result.category;

      setChildValue(new_category_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const uploadMaterial = async (image) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("width", "600");
    formData.append("height", "850");

    const result = await _add_image(formData);
    if (result.code === 200) {
      return result.path;
    } else {
      console.log(result.message, "error");
    }
  };
  const handleMaterial = async (e) => {
    const images = Array.from(e.target.files);
    setImage((prevImages) => [...prevImages, ...images]);
    setShow1(true);

    const results = [];
    for (let i = 0; i < images.length; i++) {
      const result = await uploadMaterial(images[i]);
      results.push(result);
    }
    setMaterail((prevMaterials) => [...prevMaterials, ...results]);
  };
  const handleDeleteMaterial = (index) => {
    delMaterial(material[index]);
    const newFile = [...image];
    newFile.splice(index, 1);
    setImage(newFile);
    const newMaterail = [...material];
    newMaterail.splice(index, 1);
    setMaterail(newMaterail);
    if (newFile.length === 0) {
      setShow1(false);
    }
  };
  const delMaterial = async (img) => {
    const data = new FormData();
    data.append("image_path", img);
    const result = await _del_image(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.add_product) {
      navigate(`/products`);
    }
    get_category_list();
    get_attribute_list();
  }, []);

  //===================================================================

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Product</h3>
        </div>
      </div>
      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 2,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form pt-2 pb-2">
            <div className="col-md-6 col-xs-12 ">
              <TextField
                id="outlined-basic"
                label="Common Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="common_name"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic"
                label="Scientific Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="scientific_name"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic"
                label="Video Url"
                type="URl"
                variant="outlined"
                sx={{ width: "100%" }}
                name="video_url"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Product Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="product_type"
                  defaultValue="plant"
                  value={inputs.product_type}
                  required
                  label="Product Type"
                  onChange={handleChange}
                >
                  <MenuItem value="plant">Plant</MenuItem>
                  <MenuItem value="fish">Fish</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <Autocomplete
                  id="category-autocomplete"
                  options={categoriesData}
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                  value={selectedParent}
                  onChange={(event, newValue) => {
                    setSelectedParent(newValue);
                    handleChildCategory(newValue._id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        inputs.product_type == "plant"
                          ? "Main Category"
                          : "Main Category*"
                      }
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </div>
            {selectedParent != null ? (
              <div className="col-md-6 col-xs-12">
                <FormControl fullWidth>
                  <Autocomplete
                    id="category-child-autocomplete"
                    options={childValue}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) =>
                      option._id === value._id
                    }
                    value={selectedChild}
                    onChange={(event, newValue) => {
                      setSelectedChild(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub Category"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            ) : null}

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  defaultValue="true"
                  value={inputs.status}
                  required
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Is On Sale*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_on_sale"
                  defaultValue={true}
                  value={inputs.is_on_sale}
                  required
                  label="Is On Sale"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs.is_on_sale == true && (
              <>
                <div className="col-md-6 col-xs-12">
                  <FormControl fullWidth sx={{ mb: 0 }}>
                    <InputLabel id="demo-simple-select-label">
                      Discount Type*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="discount_type"
                      defaultValue="true"
                      value={inputs.discount_type}
                      required
                      label="Discount Type"
                      onChange={handleChange}
                    >
                      <MenuItem value="value">Value</MenuItem>
                      <MenuItem value="percentage">Percentage</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-6 col-xs-12">
                  <TextField
                    id="outlined-basic-8"
                    label="Discount"
                    type="number"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    required
                    value={inputs.discount_percentage}
                    name="discount_percentage"
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Is featured*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_featured"
                  defaultValue="true"
                  value={inputs.is_featured}
                  required
                  label="Is featured "
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 col-xs-12 ">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Select Attribute Variants *
                </InputLabel>
                <Tooltip title="You can select upto 2 variants" arrow>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="attributes"
                    multiple
                    required
                    value={inputs.attributes}
                    // required
                    label="Select Attribute Variants *"
                    onChange={handleChange}
                  >
                    {AttributeData?.map((attribute, index) => (
                      <MenuItem key={attribute._id} value={attribute._id}>
                        {attribute.attribute_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </FormControl>
            </div>
            <p className="mt-2 mb-0">Upload Images (600x850)*</p>

            <div className="col-md-12 d-flex">
              <Stack direction={{ xs: "column", sm: "row" }} spacing={3.5}>
                <span className="upload-button mx-4">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="material"
                    style={{ display: "none" }}
                    name="material"
                    onChange={handleMaterial}
                    inputprops={{ multiple: true }}
                  />
                  <label htmlFor="material" className="mt-3">
                    <CloudUploadIcon />
                  </label>
                </span>
              </Stack>

              {show1 === true ? (
                <Stack direction="row" spacing={3.5}>
                  {image.map((img, index) => (
                    <Stack
                      className="delete-icon"
                      key={index}
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        alt={`image-${index}`}
                        src={URL.createObjectURL(img)}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />

                      <span
                        aria-label="delete"
                        onClick={() => handleDeleteMaterial(index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor: "red",
                          fontSize: "13px",
                          fontWeight: "bold",
                          height: "20px,",
                          width: "20px",
                          color: "#fff",
                          textAlign: "center",
                          borderRadius: "10px",
                        }}
                      >
                        x
                      </span>
                    </Stack>
                  ))}
                </Stack>
              ) : null}
            </div>
            <div className="col-12">
              <TextField
                id="outlined-multiline-static"
                label="Short Description"
                multiline
                rows={5}
                name="short_description"
                onChange={(e) => handleChange(e)}
                inputs={inputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-12">
              <h4 style={{ fontSize: "1rem" }}>Detail Description </h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="detailed_description"
                editorHeight={350}
              />
            </div>
            <div className="col-12 text-end">
              <button
                className="small-contained-button"
                type="submit"
                disabled={isLoading}
              >
                {isLoading
                  ? "Submitting..."
                  : "Updating..."
                  ? "Submit"
                  : "Update"}
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddProducts;
