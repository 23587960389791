import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Icon } from "@iconify/react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { CircularProgress, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import AddOrUpdateTast from "../../components/Tasks/AddOrUpdateTast";
import FilterTasks from "../../components/Tasks/FilterTasks";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import { useSnackbar } from "notistack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { InputAdornment, Toolbar, Tooltip } from "@mui/material";
import {
  _add_new_task,
  _assign_task,
  _change_task_priority,
  _delete_task,
  _get_task_list,
  _set_task_date_time,
  _update_order_task,
  _update_task_status,
} from "../../DAL/task/task";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { _employe_list } from "../../DAL/employee/employee";
import { s3baseUrl } from "../../config/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useParams } from "react-router-dom";

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

const getStateColor = (task_status) => {
  if (task_status == "completed") {
    return "green";
  } else if (task_status == "pending") {
    return "orange";
  } else if (task_status == "inprogress") {
    return "blue";
  } else {
    return "black";
  }
};
const getPriorityColor = (task_status) => {
  switch (task_status) {
    case "critical":
      return "red";
    case "high":
      return "orange";
    case "medium":
      return "yellowgreen";
    case "low":
      return "steelblue";
    default:
      return "black";
  }
};

const Tasks = () => {
  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorElTaskStatus, setAnchorElTaskStatus] = React.useState(null);
  const [anchorElTaskPriority, setAnchorElTaskPriority] = React.useState(null);
  const task_status_open = Boolean(anchorElTaskStatus);
  const task_priority_open = Boolean(anchorElTaskPriority);
  const [anchorElAssignedTask, setAnchorElAssignedTask] = React.useState(null);
  const openAssignedTask = Boolean(anchorElAssignedTask);
  const { privilegeAccess, userInfo } = useContentSetting();
  const [taskListData, setTaskListData] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [currentValue, setCurrentValue] = useState("");

  const [employeeList, setemployee] = useState([]);
  const [selectedItemsChangeOrNot, setSelectedItemsChangeOrNot] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedItems, setSelectedItems] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [datePickers, setDatePickers] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [copyToClipboardID, setCopyToClipboardID] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);
  const [assignedListType, setAssignedListType] = useState(null);
  const param = useParams();
  const _ID = param.id;

  const url = window.location.href;

  const [inputs, setInputs] = React.useState({
    title: "",
    description: "",
  });
  const [filterState, setFilterState] = React.useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    pending: false,
    inprogress: false,
    completed: false,
  });

  const handleCopyToClipboard = (text, task_id) => {
    setCopyToClipboardID(task_id);
    navigator.clipboard.writeText(`${origin}/tasks/${task_id}`);
    enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
  };

  const handleClickAssignedTask = (event, task_id, msg) => {
    setAssignedListType(msg);

    setCurrentValue(task_id);
    setAnchorElAssignedTask(event.currentTarget);

    const filteredArray = taskListData.filter((obj) => {
      return obj._id === task_id;
    });

    if (filteredArray[0]) {
      const newArray = filteredArray[0].assigners.map((value) => {
        return value._id;
      });
      setSelectedItems(newArray);
    }
  };

  const handleCloseAssignedTask = async () => {
    setAnchorElAssignedTask(null);

    if (selectedItemsChangeOrNot) {
      let filteredArray = taskListData.filter((obj) => {
        return obj._id === currentValue;
      });

      const newAssigners = selectedItems.map((value) => {
        let employee = employeeList.filter((emp) => {
          return emp._id === value;
        });
        return {
          _id: value,
          first_name: employee[0].first_name,
          last_name: employee[0].last_name,
          profile_image: employee[0].image,
        };
      });

      filteredArray[0].assigners = newAssigners;
      let New_taskListData = taskListData;

      const updatedObject = filteredArray[0];

      const index = New_taskListData.findIndex(
        (obj) => obj._id === currentValue
      );

      ///////////////// API Code Start
      const newArray = selectedItems.map((value) => {
        return { _id: value };
      });

      const result = await _assign_task(
        {
          assigners: newArray,
        },
        currentValue
      );

      if (result.code === 200) {
        if (index !== -1) {
          New_taskListData.splice(index, 1, updatedObject);
        }

        enqueueSnackbar(result.message, { variant: "success" });
        setSelectedItemsChangeOrNot(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setSelectedItemsChangeOrNot(false);
      }
    }
  };

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setCurrentValue(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTaskStatusClick = (event, _id) => {
    setCurrentValue(_id);
    setAnchorElTaskStatus(event.currentTarget);
  };
  const handleTaskPriorityClick = (event, _id) => {
    setCurrentValue(_id);
    setAnchorElTaskPriority(event.currentTarget);
  };

  const handleTaskStatusChange = async (task_id, status_type) => {
    const result = await _update_task_status(
      {
        task_status: status_type,
      },
      task_id
    );

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }

    const newState = taskListData.map((obj) => {
      if (obj._id === task_id) {
        obj.task_status = status_type;
      }
      return obj;
    });
    setTaskListData(newState);
    setAnchorElTaskStatus(null);
  };

  const handleTaskPriorityChange = async (task_id, priority_type) => {
    setAnchorElTaskPriority(null);
    const newState = taskListData.map((obj) => {
      if (obj._id === task_id) {
        obj.task_priority = priority_type;
      }
      return obj;
    });

    const result = await _change_task_priority(
      {
        task_priority: priority_type,
      },
      task_id
    );

    if (result.code === 200) {
      setTaskListData(newState);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleTaskStatusClose = () => {
    setAnchorElTaskStatus(null);
  };
  const handleTaskPriorityClose = () => {
    setAnchorElTaskPriority(null);
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const handleOpenDrawer = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.edit_task === true) {
        handleClose();
        setDrawerState(true);
        setFormType("Edit");
      }
    } else {
      handleClose();
      setDrawerState(true);
      setFormType("Edit");
    }
  };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const taskOrderUpdate = async (id, order_number) => {
    const result = await _update_order_task(
      {
        order: order_number,
      },
      id
    );

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  function handleOnDragEnd(result) {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    const items = Array.from(taskListData);

    // API Call
    if (
      items[result.source.index]._id &&
      items[result.destination.index].order
    ) {
      if (items[result.source.index] != items[result.destination.index]) {
        taskOrderUpdate(
          items[result.source.index]._id,
          items[result.destination.index].order
        );
      }
    }

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTaskListData(items);
  }

  const handleChange = (event) => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_task === true) {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
      }
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userInfo.type == 2) {
      if (privilegeAccess.add_task === true) {
        const result = await _add_new_task(inputs);

        if (result.code === 200) {
          setInputs({ ...inputs, title: "" });
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          const newArray = [...taskListData];
          newArray.unshift(result.Task);
          // newArray.push(result.Task); // use push instead of unshift

          if (newArray.length < 1) {
            setMessageText("No Data Exist");
          } else {
            setMessageText("");
          }

          setTaskListData(newArray);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    } else {
      const result = await _add_new_task(inputs);

      if (result.code === 200) {
        setInputs({ ...inputs, title: "" });
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        const newArray = [...taskListData];
        newArray.unshift(result.Task);
        // newArray.push(result.Task); // use push instead of unshift

        setTaskListData(newArray);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const employeeListing = async () => {
    const result = await _employe_list();
    if (result.code === 200) {
      setemployee(result.employeeuser);

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const get_task_list = async () => {
    setIsLoading(true);
    const result = await _get_task_list();
    setTaskListData(result.Task.reverse());
    if (result.code === 200) {
      if (result.Task.length < 1) {
        setMessageText("No Data Exist");
      } else {
        setMessageText("");
      }

      setIsLoading(false);
    } else {
      if (result.Task.length < 1) {
        setMessageText("No Data Exist");
      } else {
        setMessageText("");
      }
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    if (userInfo.type == 2) {
      if (privilegeAccess.delete_task === true) {
        setDeleteValue(value);
        setOpenDelete(true);
        handleCloseMenu();
      }
    } else {
      setDeleteValue(value);
      setOpenDelete(true);
      handleCloseMenu();
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await _delete_task(deleteValue);
    if (result.code === 200) {
      let new_task_list = taskListData.filter((task) => {
        return task._id !== deleteValue;
      });

      if (new_task_list.length < 1) {
        setMessageText("No Data Exist");
      } else {
        setMessageText("");
      }

      setTaskListData(new_task_list);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  function handleSelectedItems(prevSelectedItems, item) {
    if (prevSelectedItems.includes(item)) {
      return prevSelectedItems.filter((selectedItem) => selectedItem !== item);
    } else {
      return [...prevSelectedItems, item];
    }
  }

  const handleMenuItemClick = (event, item) => {
    setSelectedItemsChangeOrNot(true);

    const newSelectedItems = handleSelectedItems(selectedItems, item);
    setSelectedItems(newSelectedItems);

    // when user select employee
    let filteredArray = taskListData.filter((obj) => {
      return obj._id === currentValue;
    });

    // const newAssigners = selectedItems.map((value) => {
    const newAssigners = newSelectedItems.map((value) => {
      let employee = employeeList.filter((emp) => {
        return emp._id === value;
      });
      return {
        _id: value,
        first_name: employee[0].first_name,
        last_name: employee[0].last_name,
        profile_image: employee[0].image,
      };
    });

    if (filteredArray[0]) {
      filteredArray[0].assigners = newAssigners;
    }

    let New_taskListData = taskListData;

    const updatedObject = filteredArray[0];

    const index = New_taskListData.findIndex((obj) => obj._id === currentValue);

    const newArray = selectedItems.map((value) => {
      return { _id: value };
    });

    if (index !== -1) {
      New_taskListData.splice(index, 1, updatedObject);
      setTaskListData(New_taskListData);
    }
  };

  function selectedStatus(task_id, status_type) {
    let filteredArray = taskListData.filter((obj) => {
      return obj._id === task_id;
    });

    if (filteredArray[0]) {
      if (filteredArray[0].task_status === status_type) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  function selectedPriority(task_id, priority_type) {
    let filteredArray = taskListData.filter((obj) => {
      return obj._id === task_id;
    });

    if (filteredArray[0]) {
      if (filteredArray[0].task_priority === priority_type) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      // weekday: 'short',
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
  }

  const handleButtonClickCloseDate = async (index, task_id, current_Date) => {
    const newDatePickers = [...datePickers];

    newDatePickers[index].showDatePicker = false;
    newDatePickers[index].isCalendarclose = true;
    setDatePickers(newDatePickers);

    if (current_Date) {
      let submit_date = current_Date;
      const now = new Date();
      const Hours = now.getHours();
      const Minutes = now.getMinutes();

      if (
        now.getFullYear() == current_Date.getFullYear() &&
        now.getMonth() + 1 == current_Date.getMonth() + 1 &&
        now.getDate() == current_Date.getDate()
      ) {
        if (current_Date.getTime() < now.getTime()) {
          submit_date = now;
        }
      }

      const inputDate = new Date(submit_date);
      let date_str = inputDate.toUTCString();

      // Convert the date string into a Date object
      let date_obj = new Date(date_str);

      // Extract the date and time components from the date object
      let day = date_obj.getUTCDate().toString().padStart(2, "0");
      let month = (date_obj.getUTCMonth() + 1).toString().padStart(2, "0");
      let year = date_obj.getUTCFullYear().toString();
      let hours = date_obj.getUTCHours().toString().padStart(2, "0");
      let minutes = date_obj.getUTCMinutes().toString().padStart(2, "0");

      // Create the JSON object
      let outputObj = {
        task_date: `${day}-${month}-${year}`,
        task_time: `${hours}:${minutes}`,
      };

      const result = await _set_task_date_time(outputObj, task_id);

      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setCurrentDate("");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setCurrentDate("");
      }
    }
  };

  const handleDateSelect = (index, current_date, task_id) => {
    setCurrentDate(current_date);
    setSelectedDate(current_date);

    const newDatePickers = [...datePickers];
    newDatePickers[index].selectedDate = current_date;
    newDatePickers[index].selectedText = current_date;

    newDatePickers[index].showDatePicker = false;
    newDatePickers[index].isCalendarclose = true;
    setDatePickers(newDatePickers);
  };

  const createNumberOfDatePickers = useCallback(() => {
    const newDatePickers = [];

    let numbertasks = taskListData.length;

    for (let i = 0; i < numbertasks; i++) {
      // let selected_date = null;
      let selected_text = null;
      let selected_date = new Date();
      if (taskListData[i].task_date_time) {
        let new_selected_date = new Date(taskListData[i].task_date_time);
        const utcString = new_selected_date.toUTCString();
        selected_date = new_selected_date;
        selected_text = new_selected_date;
      }

      newDatePickers.push({
        selectedDate: selected_date,
        selectedText: selected_text,
        // selectedDate: null,
        isCalendarclose: false,
        showDatePicker: false,
        _id: taskListData[i]._id,
      });
    }

    setDatePickers(newDatePickers);
  }, [taskListData.length]);

  useEffect(() => {
    createNumberOfDatePickers(); 
  }, [createNumberOfDatePickers]);

  useEffect(() => {
    get_task_list();
    employeeListing();
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      {userInfo.type == 2 && !privilegeAccess.view_task ? (
        <NoAccessData />
      ) : (
        <div className="container">
          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this Task?"}
            handleAgree={handleDelete}
          />

          <div className="row pt-3 pb-3 ">
            <div className="col-9">
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Tooltip
                  title={
                    userInfo.type == 2 && privilegeAccess.add_task === false
                      ? "You have no access to perform this action"
                      : ""
                  }
                >
                  <div className="row">
                    <div className="col-12 mt-1">
                      <TextField
                        id="outlined-multiline-static"
                        // label="Create Task"
                        placeholder="Create Task"
                        name="title"
                        value={inputs?.title}
                        onChange={(e) => handleChange(e)}
                        inputs={inputs}
                        variant="standard"
                        style={{ width: "100%" }}
                        InputProps={{
                          // style: { borderStyle: 'dashed' },
                          style: {
                            borderStyle: "dashed",
                            borderWidth: "0.12rem",
                            borderColor: "#000",
                            borderRadius: "10px",
                            padding: "7px",
                            marginTop: "7px",
                          },

                          startAdornment: (
                            <InputAdornment position="start">
                              {inputs.title === "" && (
                                <Icon
                                  color="#1976d2"
                                  icon={"mdi:plus"}
                                  width={20}
                                  height={20}
                                />
                              )}
                              {inputs.title !== "" && (
                                <Icon
                                  color="transparent"
                                  icon={"mdi:plus"}
                                  width={20}
                                  height={20}
                                />
                              )}
                            </InputAdornment>
                          ),

                          endAdornment: inputs.title !== "" && (
                            <InputAdornment position="end">
                              <button
                                className="btn send_button_icon"
                                type="submit"
                              >
                                <Icon
                                  icon={"ic:baseline-send"}
                                  width={24}
                                  height={24}
                                />
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Tooltip>
              </form>
            </div>

            <div className="col-3">
              <Button
                onClick={handleOpenDrawerFilter}
                sx={{
                  spacing: 4,
                  borderRadius: "10px",
                  width: "100%",
                  p: 1.65,
                  mt: 1.2,
                  backgroundColor: "#f6f7f9",
                  display: "flex",
                  justifyContent: "center",
                  alignIitems: "center",
                  color: "black",
                }}
              >
                <span className="text-capitalize">Filters</span>
                <span className=" px-2">
                  {getIcon("material-symbols:filter-list")}
                </span>
              </Button>
            </div>
          </div>

          <Box
            sx={{
              p: 2,
              border: "1px solid #dbdee4",
              borderRadius: "5px",
            }}
          >
            <div className="task_heading_feedback">
              Feedback - Issues - Improvements
            </div>

            {messageText !== "" && (
              <div className="message-container">
                <p className="message-text">{messageText}</p>
              </div>
            )}

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <div
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {taskListData.map((task, task_index) => {
                      return (
                        <Draggable
                          key={task._id}
                          draggableId={task._id}
                          index={task_index}
                        >
                          {(provided) => (
                            <Card
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              sx={{
                                minWidth: 275,
                                mt: 2,

                                boxShadow:
                                  _ID && _ID == task._id
                                    ? "0px 0px 10px rgba(0, 128, 255, 0.7)"
                                    : 2,
                              }}
                            >
                              <CardContent>
                                <div style={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      color: "text.secondary",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: 15,
                                        height: 15,
                                        marginTop: "6px",
                                        padding: "7px",
                                        backgroundColor: getStateColor(
                                          task.task_status
                                        ),
                                      }}
                                    />

                                    <div className="task_title">
                                      {task.description !== "" ? (
                                        <Tooltip
                                          title={
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: task.description,
                                              }}
                                            ></span>
                                          }
                                          placement="top"
                                          arrow
                                        >
                                          <p>{task.title}</p>
                                        </Tooltip>
                                      ) : (
                                        <p>{task.title}</p>
                                      )}
                                    </div>
                                  </Box>
                                </div>

                                <div className="row  mt-1 mt-sm-0">
                                  <div className="col-6 col-md-2  mb-0 float-start">
                                    {datePickers.map((datePicker, index) =>
                                      datePicker._id == task._id ? (
                                        <div key={index}>
                                          {
                                            <div className="">
                                              <DatePicker
                                                selected={
                                                  datePicker.selectedDate
                                                }
                                                onChange={(date) =>
                                                  handleDateSelect(
                                                    index,
                                                    date,
                                                    task._id
                                                  )
                                                }
                                                showTimeSelect
                                                // timeFormat="HH:mm"
                                                timeFormat="h:mm aa" // using 12-hour time format
                                                timeIntervals={15}
                                                minDate={new Date()}
                                                minTime={
                                                  datePicker.selectedDate &&
                                                  new Date(
                                                    datePicker.selectedDate
                                                  ).toDateString() ===
                                                    new Date().toDateString()
                                                    ? new Date().setHours(
                                                        new Date().getHours(),
                                                        new Date().getMinutes()
                                                      )
                                                    : new Date().setHours(0, 0)
                                                }
                                                customInput={
                                                  <p className="task-select-date">
                                                    {datePicker.selectedText
                                                      ? formatDate(
                                                          datePicker.selectedDate
                                                        )
                                                      : "Select date/time"}
                                                  </p>
                                                } // This removes the input field
                                                maxTime={new Date().setHours(
                                                  23,
                                                  59
                                                )}
                                                timeCaption="Time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                onCalendarClose={() => {
                                                  handleButtonClickCloseDate(
                                                    index,
                                                    task._id,
                                                    currentDate
                                                  );
                                                }}
                                              />
                                            </div>
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </div>
                                  <div className="col-6 col-md-4  mb-0">
                                    <div className="task-buttons-menu">
                                      <Stack
                                        direction="row"
                                        spacing={1}
                                        className="task_icon_color"
                                      >
                                        <Avatar
                                          id="task-button-priority"
                                          aria-controls={
                                            task_priority_open
                                              ? "task-menu"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            task_priority_open
                                              ? "true"
                                              : undefined
                                          }
                                          onClick={(e) =>
                                            handleTaskPriorityClick(e, task._id)
                                          }
                                          sx={{
                                            width: 35,
                                            height: 35,
                                            backgroundColor: "#eff9f1",
                                            color: getPriorityColor(
                                              task.task_priority
                                            ),
                                            cursor: "pointer",
                                          }}
                                        >
                                          {getIcon("material-symbols:flag")}
                                        </Avatar>
                                        <Avatar
                                          id="task-button"
                                          aria-controls={
                                            task_status_open
                                              ? "task-menu"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            task_status_open
                                              ? "true"
                                              : undefined
                                          }
                                          onClick={(e) =>
                                            handleTaskStatusClick(e, task._id)
                                          }
                                          sx={{
                                            width: 35,
                                            height: 35,
                                            backgroundColor: "#eff9f1",
                                            cursor: "pointer",
                                            color: getStateColor(
                                              task.task_status
                                            ),
                                          }}
                                        >
                                          {" "}
                                          {getIcon("material-symbols:circle")}
                                        </Avatar>

                                        <div className="task_icon_copy">
                                          {copyToClipboardID === task._id ? (
                                            <Tooltip
                                              title="Copied!"
                                              placement="top"
                                              arrow
                                            >
                                              <Icon
                                                icon={"ic:sharp-content-copy"}
                                                width={20}
                                                height={20}
                                                onClick={() =>
                                                  handleCopyToClipboard(
                                                    task.title,
                                                    task._id
                                                  )
                                                }
                                              />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              title="Copy task link"
                                              placement="top"
                                              arrow
                                            >
                                              <Icon
                                                icon={"ic:sharp-content-copy"}
                                                width={20}
                                                height={20}
                                                onClick={() =>
                                                  handleCopyToClipboard(
                                                    task.title,
                                                    task._id
                                                  )
                                                }
                                              />
                                            </Tooltip>
                                          )}
                                        </div>
                                      </Stack>
                                    </div>
                                  </div>
                                  <div className="col-9 col-md-5 ">
                                    <div className="task-assigned-menu">
                                      <AvatarGroup
                                        onClick={(e) =>
                                          handleClickAssignedTask(
                                            e,
                                            task._id,
                                            "assigned"
                                          )
                                        }
                                        aria-label="more"
                                        id="assigned-button"
                                        aria-controls={
                                          openAssignedTask
                                            ? "assigned-menu"
                                            : undefined
                                        }
                                        aria-expanded={
                                          openAssignedTask ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        max={5}
                                        sx={{
                                          "& .MuiAvatar-root": {
                                            width: 35,
                                            height: 35,
                                            fontSize: 15,
                                            cursor: "pointer",
                                          },
                                        }}
                                      >
                                        <Avatar
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickAssignedTask(
                                              e,
                                              task._id,
                                              "add"
                                            );
                                          }}
                                          sx={{
                                            width: 35,
                                            height: 35,
                                            cursor: "pointer",
                                          }}
                                        >
                                          {" "}
                                          <Icon
                                            icon={"ic:baseline-plus"}
                                            width={15}
                                            height={15}
                                          />
                                        </Avatar>

                                        {task.assigners.map(
                                          (image, image_index) => {
                                            return (
                                              <Avatar
                                                key={image._id}
                                                alt={image.first_name}
                                                src={
                                                  s3baseUrl +
                                                  image.profile_image
                                                }
                                              />
                                            );
                                          }
                                        )}
                                      </AvatarGroup>
                                    </div>
                                  </div>
                                  <div className="col-3 col-md-1  float-end">
                                    <div className="task-click-option">
                                      <Stack
                                        direction="row"
                                        spacing={2}
                                        className="task_icon_color"
                                      >
                                        <button
                                          className="buttons_in_task_list"
                                          id="basic-button"
                                          aria-controls={
                                            open ? "basic-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            open ? "true" : undefined
                                          }
                                          onClick={(e) =>
                                            handleClick(e, task._id)
                                          }
                                        >
                                          <Icon
                                            icon={
                                              "akar-icons:more-vertical-fill"
                                            }
                                            width={20}
                                            height={20}
                                          />
                                        </button>

                                        <button className="buttons_in_task_list">
                                          <Icon
                                            icon={
                                              "akar-icons:drag-vertical-fill"
                                            }
                                            width={20}
                                            height={20}
                                          />
                                        </button>
                                      </Stack>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </div>
      )}

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle={`${formType === "Edit" ? "Edit Task" : "Filters"}`}
        componentToPassDown={
          formType === "Edit" ? (
            <AddOrUpdateTast
              task_id={currentValue}
              onCloseDrawer={handleCloseEditDrawer}
              isOpenDrawer={drawerState}
              formType={formType}
              taskListData={taskListData}
              setTaskListData={setTaskListData}
            />
          ) : (
            <FilterTasks
              filterState={filterState}
              setFilterState={setFilterState}
              taskListData={taskListData}
              setTaskListData={setTaskListData}
              messageText={messageText}
              setMessageText={setMessageText}
              setDrawerState={setDrawerState}
            />
          )
        }
      />

      {/* All MenuItem Start*/}
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableAutoFocusItem
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Tooltip
            title={
              userInfo.type == 2 && privilegeAccess.edit_task === false
                ? "You have no access to perform this action"
                : ""
            }
          >
            <MenuItem onClick={() => handleOpenDrawer(currentValue)}>
              <Icon
                icon={"akar-icons:edit"}
                width={20}
                height={20}
                color="#045dbb"
              />{" "}
              &nbsp; Edit
            </MenuItem>
          </Tooltip>

          <Tooltip
            title={
              userInfo.type == 2 && privilegeAccess.delete_task === false
                ? "You have no access to perform this action"
                : ""
            }
          >
            <MenuItem onClick={() => handleAgreeDelete(currentValue)}>
              {" "}
              <Icon
                icon={"ant-design:delete-twotone"}
                width={20}
                height={20}
                color="#045dbb"
              />{" "}
              &nbsp; Delete
            </MenuItem>
          </Tooltip>
        </Menu>

        <Menu
          id="task-menu"
          anchorEl={anchorElTaskStatus}
          open={task_status_open}
          // task_status
          onClose={handleTaskStatusClose}
          disableAutoFocusItem
          MenuListProps={{
            "aria-labelledby": "task-button",
          }}
        >
          <MenuItem
            selected={selectedStatus(currentValue, "completed") ? true : false}
            onClick={() => handleTaskStatusChange(currentValue, "completed")}
          >
            <Box
              sx={{
                color: getStateColor("completed"),
              }}
            >
              {getIcon("material-symbols:circle")}
            </Box>{" "}
            &nbsp; Completed
          </MenuItem>
          <MenuItem
            selected={selectedStatus(currentValue, "pending") ? true : false}
            onClick={() => handleTaskStatusChange(currentValue, "pending")}
          >
            <Box
              sx={{
                color: getStateColor("pending"),
              }}
            >
              {getIcon("material-symbols:circle")}
            </Box>{" "}
            &nbsp; Pending
          </MenuItem>
          <MenuItem
            selected={selectedStatus(currentValue, "inprogress") ? true : false}
            onClick={() => handleTaskStatusChange(currentValue, "inprogress")}
          >
            <Box
              sx={{
                color: getStateColor("inprogress"),
              }}
            >
              {getIcon("material-symbols:circle")}
            </Box>{" "}
            &nbsp; In Progress
          </MenuItem>
        </Menu>

        <Menu
          id="task-menu"
          anchorEl={anchorElTaskPriority}
          open={task_priority_open}
          onClose={handleTaskPriorityClose}
          disableAutoFocusItem
          MenuListProps={{
            "aria-labelledby": "task-button-priority",
          }}
        >
          <MenuItem
            selected={selectedPriority(currentValue, "critical") ? true : false}
            onClick={() => handleTaskPriorityChange(currentValue, "critical")}
          >
            <Box
              sx={{
                color: getPriorityColor("critical"),
              }}
            >
              {getIcon("material-symbols:flag")}
            </Box>{" "}
            &nbsp; Critical
          </MenuItem>
          <MenuItem
            selected={selectedPriority(currentValue, "high") ? true : false}
            onClick={() => handleTaskPriorityChange(currentValue, "high")}
          >
            <Box
              sx={{
                color: getPriorityColor("high"),
              }}
            >
              {getIcon("material-symbols:flag")}
            </Box>{" "}
            &nbsp; High
          </MenuItem>
          <MenuItem
            selected={selectedPriority(currentValue, "medium") ? true : false}
            onClick={() => handleTaskPriorityChange(currentValue, "medium")}
          >
            <Box
              sx={{
                color: getPriorityColor("medium"),
              }}
            >
              {getIcon("material-symbols:flag")}
            </Box>{" "}
            &nbsp; Medium
          </MenuItem>
          <MenuItem
            selected={selectedPriority(currentValue, "low") ? true : false}
            onClick={() => handleTaskPriorityChange(currentValue, "low")}
          >
            <Box
              sx={{
                color: getPriorityColor("low"),
              }}
            >
              {getIcon("material-symbols:flag")}
            </Box>{" "}
            &nbsp; Low
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorElAssignedTask}
          open={Boolean(openAssignedTask)}
          onClose={handleCloseAssignedTask}
          disableAutoFocusItem
        >
          {employeeList.map((employee, index) =>
            assignedListType === "add" ? (
              !selectedItems.includes(employee._id) ? (
                <MenuItem
                  key={employee._id}
                  onClick={(event) => handleMenuItemClick(event, employee._id)}
                  selected={selectedItems.includes(employee._id)}
                >
                  <Avatar
                    sx={{
                      width: 25,
                      height: 25,
                    }}
                    alt={employee.first_name}
                    src={s3baseUrl + employee.image}
                  />
                  <div className="px-2">
                    {employee.first_name + " " + employee.last_name}
                  </div>
                </MenuItem>
              ) : null
            ) : assignedListType === "assigned" ? (
              selectedItems.includes(employee._id) ? (
                <MenuItem
                  key={employee._id}
                  onClick={(event) => handleMenuItemClick(event, employee._id)}
                  selected={selectedItems.includes(employee._id)}
                >
                  <Avatar
                    sx={{
                      width: 25,
                      height: 25,
                    }}
                    alt={employee.first_name}
                    src={s3baseUrl + employee.image}
                  />
                  <div className="px-2">
                    {employee.first_name + " " + employee.last_name}
                  </div>
                </MenuItem>
              ) : null
            ) : null
          )}
          {!employeeList.some(
            (employee) =>
              assignedListType === "add" &&
              !selectedItems.includes(employee._id)
          ) &&
            (assignedListType === "add" ? (
              <MenuItem>No employees available</MenuItem>
            ) : null)}

          {!employeeList.some(
            (employee) =>
              assignedListType === "assigned" &&
              selectedItems.includes(employee._id)
          ) &&
            (assignedListType === "assigned" ? (
              <MenuItem>No employees available</MenuItem>
            ) : null)}
        </Menu>
      </div>

      {/* All MenuItem End  */}
    </>
  );
};

export default Tasks;
