import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "../../config/config";
import {
  vendor_Delete_Api,
  _vendors_list_pagination,
} from "../../DAL/Vender/vender";
import AddVendors from "./AddVendors";
import EditVendors from "./EditVendors";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "vendor_name", label: "Name", alignRight: false },
  {
    id: "image",
    label: "Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "contact_number", label: "Contact Number", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
  { id: "action", label: "Action", alignRight: false, type: "action" },
];

export default function Vendors() {
  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const [editDraerState, seteditDraerState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [VendorList, setVendorList] = useState([]);
  const [deleteValue, setDeleteValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [EditValue, setEditValue] = useState();
  const { privilegeAccess, userInfo } = useContentSetting();
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [reset, setReset] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const VendorListing = async () => {
    setIsLoading(true);

    const result = await _vendors_list_pagination(
      page,
      rowsPerPage,
      searchText
    );
    if (result.code === 200) {
      const vendors = result.vendor.map((vendors, index) => {
        return {
          ...vendors,
          vendor_name: vendors.first_name + " " + vendors.last_name,

          image: {
            src: s3baseUrl + vendors.image,
            alt: vendors.first_name,
          },
        };
      });
      setTotalCount(result.total_count);
      setVendorList(vendors);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const searchFunction = () => {
    setPage(0);
    VendorListing();
  };

  const searchResetFunction = () => {
    setSearchText("");
    setPage(0);
    if (reset == 0) {
      setReset(1);
    } else {
      setReset(0);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEdit = (value) => {
    setEditValue(value);
    handleOpenEditedDrawer();
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenDraweradd = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_vendor === true) {
        setDrawerState(true);
      }
    } else {
      setDrawerState(true);
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleOpenEditedDrawer = () => {
    seteditDraerState(true);
  };
  const handlecloseEditedDrawer = () => {
    seteditDraerState(false);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await vendor_Delete_Api(deleteValue);

    if (result.code === 200) {
      setIsLoading(false);
      setVendorList(VendorList.filter((vendor) => vendor._id !== deleteValue));
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    VendorListing();
  }, [page, rowsPerPage, reset]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
      is_access: userInfo.type == 0 ? true : privilegeAccess.edit_vendor,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      is_access: userInfo.type == 0 ? true : privilegeAccess.delete_vendor,
    },
  ];

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Vendors</h3>
          </div>
          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.add_vendor === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              <button
                className="small-contained-button"
                onClick={handleOpenDraweradd}>
                Add Vendors
              </button>
            </Tooltip>
          </div>
        </div>

        {userInfo.type == 2 && !privilegeAccess.view_vendor ? (
          <NoAccessData />
        ) : (
          <div className="row">
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={VendorList}
                MENU_OPTIONS={MENU_OPTIONS}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                  handleReset: searchResetFunction,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Vendor?"}
        handleAgree={handleDelete}
      />
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Add Vendor"
        componentToPassDown={
          <AddVendors
            apiFunction={VendorListing}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={editDraerState}
        onOpenDrawer={handleOpenEditedDrawer}
        onCloseDrawer={handlecloseEditedDrawer}
        pageTitle="Edit Vendor"
        componentToPassDown={
          <EditVendors
            data={EditValue}
            apiFunction={VendorListing}
            onCloseDrawer={handlecloseEditedDrawer}
          />
        }
      />
    </>
  );
}
