import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import AddEmployee from "./AddEmployees";
import CustomPopovers from "./pliveragePopUp";
import { Icon } from "@iconify/react";
import CustomModal from "../../components/GeneralComponents/CustomModal";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { s3baseUrl } from "../../config/config";
import {
  _employee_delete,
  _employee_list_pagination,
} from "../../DAL/employee/employee";
import EmployeeChangePassword from "../../components/Employees/EmployeeChangePassword";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

export default function Employes() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const [showPop, setshowPop] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [employeeList, setemployee] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [privileges, setPrivileges] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const { privilegeAccess, userInfo } = useContentSetting();
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [reset, setReset] = useState(0);

  const handleChangePassword = (row) => {
    setDeleteValue(row);
    setOpenModel(true);
  };

  const handleEdit = (value) => {
    navigate(`/employee/Edit-employee/${value._id}`, {
      state: { editValues: value },
    });
  };

  const handlePrivilegesPopups = () => {
    setshowPop(true);
  };

  const HandleClosePrebilage = () => {
    setshowPop(false);
  };

  const handlePrivilegesPopup = (row) => {
    setPrivileges(JSON.parse(row.privilages));
    handlePrivilegesPopups();
  };

  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const employeeListing = async () => {
    setIsLoading(true);
    const result = await _employee_list_pagination(
      page,
      rowsPerPage,
      searchText
    );
    if (result.code === 200) {
      const category = result.employeeuser.map((category, index) => {
        return {
          ...category,
          image: {
            src: s3baseUrl + category.image,
            alt: category.first_name,
          },
          name: category.first_name + " " + category.last_name,
        };
      });
      setTotalCount(result.total_count);
      setemployee(category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const searchFunction = () => {
    setPage(0);
    employeeListing();
  };
  const searchResetFunction = () => {
    setSearchText("");
    setPage(0);
    if (reset == 0) {
      setReset(1);
    } else {
      setReset(0);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleNavigate = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_employee === true) {
        navigate(`/employee/add-employee`);
      }
    } else {
      navigate(`/employee/add-employee`);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await _employee_delete(deleteValue);
    if (result.code === 200) {
      employeeListing();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "name", label: "Name", alignRight: false },
    {
      id: "image",
      label: "Profile",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "email", label: "Email", alignRight: false },

    { id: "contact_number", label: "Phone", alignRight: false },

    { id: "status", label: "Status", alignRight: false, type: "row_status" },
    {
      label: "Privileges",
      alignCenter: true,
      renderData: (row) => {
        return (
          <Tooltip
            title="View Employee Privileges"
            placement="bottom-start"
            arrow>
            <Icon
              fontSize="25"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                handlePrivilegesPopup(row);
              }}
              icon={"carbon:data-view-alt"}
              className="privileges-svg-icon"
            />
          </Tooltip>
        );
      },
    },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
      is_access: userInfo.type == 0 ? true : privilegeAccess.edit_employee,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      is_access: userInfo.type == 0 ? true : privilegeAccess.delete_employee,
    },
    {
      label: "Change Password",
      icon: "mdi:password",
      handleClick: handleChangePassword,
    },
  ];

  useEffect(() => {
    employeeListing();
  }, [page, rowsPerPage, reset]);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Employees</h3>
          </div>

          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.add_employee === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              <button
                className="small-contained-button"
                onClick={handleNavigate}>
                Add Employee
              </button>
            </Tooltip>
          </div>
        </div>

        {userInfo.type == 2 && !privilegeAccess.view_employee ? (
          <NoAccessData />
        ) : (
          <div className="row">
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={employeeList}
                MENU_OPTIONS={MENU_OPTIONS}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                  handleReset: searchResetFunction,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Add Employee"
        componentToPassDown={<AddEmployee />}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Employee?"}
        handleAgree={handleDelete}
      />
      <CustomModal
        title="Change Password"
        openModel={openModel}
        setOpenModel={setOpenModel}
        componentToPassDown={
          <EmployeeChangePassword
            setOpenModel={setOpenModel}
            deleteValue={deleteValue}
          />
        }
      />

      <CustomPopovers
        isOpenPop={showPop}
        onOpenPopUp={handlePrivilegesPopup}
        forClosePopUp={HandleClosePrebilage}
        data={privileges}
      />
    </>
  );
}
