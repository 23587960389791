import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Toolbar, OutlinedInput, InputAdornment, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "end",
}));

UserListToolbarsForSearch.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbarsForSearch({
  numSelected,
  filterName,
  onFilterName,
  handleSubmit,
  handleReset,
  buttonText,
}) {
  return (
    <RootStyle>
      <Grid container justifyContent="end">
        <Grid className="mui-table-search-input-box">
          <OutlinedInput
            className="ms-auto mui-table-search-input"
            value={filterName}
            onChange={onFilterName}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Icon icon="ic:baseline-search" />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid className="mui-table-search-input-box mt-1">
          {buttonText ? (
            ""
          ) : (
            <>
              <button
                className="small-contained-button ms-3"
                onClick={() => handleSubmit()}>
                Search
              </button>

              {handleReset && (
                <button
                  className="small-contained-button ms-1"
                  onClick={() => handleReset()}>
                  Reset
                </button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </RootStyle>
  );
}
