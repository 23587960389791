import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  _detail_orders,
  _add_order_prepared,
  _change_order_status,
} from "../../DAL/orders/orders";
import OrderDetailItems from "../../components/Orders/OrderDetailItems";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import OrderPrepareItems from "../../components/Orders/OrderPreparetems";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
export default function OrderPrepare() {
  const { privilegeAccess, userInfo } = useContentSetting();
  let { state } = useLocation();
  const param = useParams();
  const _ID = param.id;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [addArray, setaddArray] = useState([]);
  const [billing, setBilling] = useState();
  const [price, setPrice] = useState();
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [orderPrepared, setOrderPrepared] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [inputs, setInputs] = useState({
    expected_delivery_date: dayjs(new Date()),
  });
  const handleDateChange = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: dayjs(value.$d),
      };
    });
  };

  const handleBack = () => {
    navigate(`/orders`);
  };
  const handleAdd = (array_index, main_index) => {
    if (!orderPrepared) {
      const newData = [...addArray];
      const newShipmentObj = { _id: "", quantity: 0, available_quantity: 0 };
      newData[main_index].shipment.splice(array_index + 1, 0, newShipmentObj);
      setaddArray(newData);
    }
  };
  const handleDelete = (array_index, main_index) => {
    if (!orderPrepared) {
      addArray[main_index].shipment.splice(array_index, 1);
      setaddArray([...addArray]);
    }
  };

  const get_detail_order = async () => {
    const response = await _detail_orders(_ID);
    if (response.code === 200) {
      let data = response.order.order_items.map((item) => {
        return {
          ...item,
        };
      });
      setBilling(state?.customer);
      setPrice(response.order.total_order_price);
      setEmail(state?.email);
      setOrderItems(data);

      if (Object.keys(response.order_prepared).length > 0) {
        setOrderPrepared(true);
        let addedElementArray = [];
        for (let i = 0; i < response.order_prepared.items.length; i++) {
          addedElementArray.push({
            product_id: response.order_prepared.items[i].product_id,
            variation: response.order_prepared.items[i].variation,
            variationName: "",
            shipment: [],
          });
          for (
            let j = 0;
            j < response.order_prepared.items[i].shipment.length;
            j++
          ) {
            addedElementArray[i].shipment.push({
              _id: response.order_prepared.items[i].shipment[j]._id,
              quantity: response.order_prepared.items[i].shipment[j].quantity,
              available_quantity: 0,
            });
          }
        }
        setaddArray(addedElementArray);
      } else {
        if (
          response.order.order_items &&
          response.order.order_items.length > 0
        ) {
          let addedElementArray = [];
          for (let i = 0; i < response.order.order_items.length; i++) {
            addedElementArray.push({
              product_id: "",
              variation: "",
              variationName: "",
              shipment: [{ _id: "", quantity: 0, available_quantity: 0 }],
            });
          }
          setaddArray(addedElementArray);
        }
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const handleStatusChange = async (e) => {
    const objectData = {
      order_status: "ready_to_ship",
    };
    await _change_order_status(objectData, _ID);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!orderPrepared) {
      setIsLoading(true);

      const newArray = addArray.map(({ product_id, variation, shipment }) => ({
        product_id,
        variation,
        shipment: shipment.map(({ _id, quantity }) => ({ _id, quantity })),
      }));

      let postData = {
        order_id: _ID,
        items: newArray,
        expected_delivery_date: inputs.expected_delivery_date,
      };
      const result = await _add_order_prepared(postData);
      if (result.code === 200) {
        handleStatusChange();
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        navigate(`/orders`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.view_order) {
      navigate(`/orders`);
    } else {
      get_detail_order();
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container new-memories">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-6">
          <h3 className="my-3">Prepare Order</h3>
        </div>
      </div>

      {billing && (
        <div className="row px-2">
          <div className="card mt-4">
            <div className="row align-items-center mb-3">
              <div className="col-md-12 col-lg-6 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Name:{" "}
                </Typography>
                <Typography>
                  {billing?.first_name + " " + billing?.last_name}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Email:{" "}
                </Typography>
                <Typography>{email}</Typography>
              </div>

              <div className="col-md-12 col-lg-6 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Contact No:{" "}
                </Typography>
                <Typography>{billing?.contact_number}</Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Total Order Price:{" "}
                </Typography>
                <Typography>{`$${price}`}</Typography>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12 col-md-4 mt-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expected Order Delivery"
              format="DD-MM-YYYY"
              className="form-control"
              name="expected_delivery_date"
              value={inputs.expected_delivery_date}
              onChange={(e) => {
                handleDateChange("expected_delivery_date", e);
              }}
              disablePast
            />
          </LocalizationProvider>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row px-2">
          {orderItems.length > 0 &&
            orderItems?.map((items, index) => {
              if (orderPrepared) {
                for (let i = 0; i < items.product_id.shipment.length; i++) {
                  for (let j = 0; j < addArray[index].shipment.length; j++) {
                    if (
                      items.product_id.shipment[i]._id ===
                      addArray[index].shipment[j]._id
                    ) {
                      const availableQuanity =
                        items.product_id.shipment[i].available_quantity;
                      addArray[index].shipment[j].available_quantity =
                        availableQuanity;
                    }
                  }
                }
                if (items.product_id.variation.length > 0) {
                  for (let i = 0; i < items.product_id.variation.length; i++) {
                    if (items.product_id.variation[i]._id === items.variation) {
                      const variation = items.product_id.variation[i]
                        .attributes_items[1]
                        ? items.product_id.variation[i].attributes_items[0]
                            .item_name +
                          "/" +
                          items.product_id.variation[i].attributes_items[1]
                            .item_name
                        : items.product_id.variation[i].attributes_items[0]
                            .item_name;
                      addArray[index].variationName = variation;
                    }
                  }
                }
              }
              return (
                <div className="card mt-4" key={index}>
                  <OrderPrepareItems
                    items={items}
                    addArray={addArray}
                    setaddArray={setaddArray}
                    index={index}
                    handleAdd={handleAdd}
                    handleDelete={handleDelete}
                    orderPrepared={orderPrepared}
                  />
                </div>
              );
            })}

          <div className="text-end mt-3 mb-3">
            <Tooltip
              title={orderPrepared ? "You cannot perform this action" : ""}
              placement="right-end"
              arrow
            >
              <button className="small-contained-button" type="submit">
                Confirm Order
              </button>
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  );
}
