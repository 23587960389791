import { Box, Modal, Typography, IconButton } from "@mui/material";

import React from "react";
import closeFill from "@iconify/icons-eva/close-fill";
import { Icon } from "@iconify/react";

function ChangePassword({
  openModel,
  setOpenModel,
  title,
  componentToPassDown,
}) {
  const handleCloseDialog = () => {
    setOpenModel(false);
  };

  return (
    <>
      <Modal
        open={openModel}
        onClose={handleCloseDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="change-password">
          <div className="change-password-modal">
            <div>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                {title}
              </Typography>
            </div>

            <div>
              <IconButton onClick={handleCloseDialog} className="icon-button">
                <Icon icon={closeFill} width={20} height={20} />
              </IconButton>
            </div>
          </div>
          {componentToPassDown}
          <div></div>
        </Box>
      </Modal>
    </>
  );
}

export default ChangePassword;
