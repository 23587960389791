import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddOrUpdateExtraExpenses from "../../components/ExtraExpenses/AddOrUpdateExtraExpenses";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _delete_extra_expanse,
  _get_extra_expanses_list,
} from "../../DAL/extra_expense/extra_expense";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "less_description",
    label: "Short Description",
    alignRight: false,
    type: "less_more_description",
  },
];

export default function ExtraExpenseList() {
  const navigate = useNavigate();
  const { privilegeAccess, userInfo } = useContentSetting();
  const [drawerState, setDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [formType, setFormType] = useState("ADD");
  const [selectedRow, setSelectedRow] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [extraExpenseData, setExtraExpenseData] = useState([]);

  const handleAgreeDelete = (value) => {
    setSelectedRow(value);
    setOpenDelete(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleEdit = (value) => {
    setSelectedRow(value);
    setDrawerState(true);
    setFormType("EDIT");
  };
  const handleOpenDrawer = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_extra_expense === true) {
        setDrawerState(true);
        setFormType("ADD");
      }
    } else {
      setDrawerState(true);
      setFormType("ADD");
    }
  };
  const handleOpenEditDrawer = () => {
    // setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleNavigate = () => {
    navigate(`/extra-expenses/add-expense`);
  };

  const get_extra_expense_list = async () => {
    const response = await _get_extra_expanses_list();
    if (response.code === 200) {
      const data = response.extra_expanses.map((expanse) => {
        return {
          ...expanse,

          less_description: {
            text: expanse.short_description,
            show_full_text: false,
            limit: 50,
          },
        };
      });

      setExtraExpenseData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
      is_access: userInfo.type == 0 ? true : privilegeAccess.edit_extra_expense,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      is_access:
        userInfo.type == 0 ? true : privilegeAccess.delete_extra_expense,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const response = await _delete_extra_expanse(selectedRow._id);
    if (response.code == 200) {
      enqueueSnackbar(response.message, {
        variant: "success",
      });

      setExtraExpenseData((prevData) =>
        prevData.filter((ExtraExpense) => ExtraExpense._id !== selectedRow._id)
      );
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_extra_expense_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <div className="container">
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Expense`}
        componentToPassDown={
          <>
            <AddOrUpdateExtraExpenses
              onCloseDrawer={handleCloseEditDrawer}
              isOpenDrawer={drawerState}
              formType={formType}
              setExtraExpenseData={setExtraExpenseData}
              extraExpenseData={extraExpenseData}
              selectedRow={selectedRow}
            />
          </>
        }
      />

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Expense?"}
        handleAgree={handleDelete}
      />

      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12 mt-3">
          <h3>Extra Expenses</h3>
        </div>
        <div className="col-lg-4 col-sm-12 text-end">
          <Tooltip
            title={
              userInfo.type == 2 && privilegeAccess.add_extra_expense === false
                ? "You have no access to perform this action"
                : ""
            }
            placement="right-end"
            arrow
          >
            <button
              className="small-contained-button"
              onClick={handleOpenDrawer}
            >
              Add Expense
            </button>
          </Tooltip>
        </div>
      </div>
      {userInfo.type == 2 && !privilegeAccess.view_extra_expense ? (
        <NoAccessData />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={extraExpenseData}
          setData={setExtraExpenseData}
          className="card-with-background"
          MENU_OPTIONS={MENU_OPTIONS}
        />
      )}
    </div>
  );
}
