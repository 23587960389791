import { invokeApi } from "../../bl_libs/invokeApi";

export const _customers_list = async (page, limit, search) => {
  const requestObj = {
    path: `api/customer/list_customer_with_pagination?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _customers_list_no_pagination = async () => {
  const requestObj = {
    path: `api/customer/list_customer`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_customer = async (data) => {
  let requestObj = {
    path: `api/customer/add_customer_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_customer = async (id, data) => {
  let requestObj = {
    path: `api/customer/update_customer_by_admin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _change_customer_password = async (data, user_id) => {
  const requestObj = {
    path: `api/app_api/change_employee_password/${user_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
