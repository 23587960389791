import { Typography } from "@mui/material";
import React from "react";

function ProductDetail({ selectedRow }) {
  return (
    <div className="container new-memories">
      <div className="row mb-3">
        <div className="col-12 mt-3 d-flex">
          <Typography sx={{ mr: 1, fontWeight: "bold" }}>Name: </Typography>
          <Typography>
            {selectedRow.common_name + " " + selectedRow.scientific_name}
          </Typography>
        </div>
        <div className="col-12 mt-3 d-flex">
          <Typography sx={{ mr: 1, fontWeight: "bold" }}>Type: </Typography>
          <Typography>{selectedRow.productType}</Typography>
        </div>
        <div className="col-12 mt-3 d-flex">
          <Typography sx={{ mr: 1, fontWeight: "bold" }}>Category: </Typography>
          <Typography>{selectedRow.category}</Typography>
        </div>
        <div className="col-12 mt-3 d-flex">
          <Typography sx={{ mr: 1, fontWeight: "bold" }}>
            Availability:{" "}
          </Typography>
          <Typography>
            {selectedRow.available.split(" | ").map((item, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <br />}
                {item.replace(" |", "")}
              </React.Fragment>
            ))}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
