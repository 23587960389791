import { AppBar, IconButton, Toolbar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import ProfileMenu from "../../components/Profile/ProfileMenu";
import { _admin_logout } from "../../DAL/admin/admin";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";

export default function DashboardNavbar({ drawerWidth, handleDrawerToggle }) {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userInfo } = useContentSetting();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await _admin_logout();
    if (response.code == 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/login");
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  return (
    <>
      <Box>
        <AppBar
          elevation={0}
          color="inherit"
          position="fixed"
          display="space-between"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                justifyContent: "flex-start",
                visibility: { sm: "hidden" },
              }}
            >
              <MenuIcon className="menu" />
            </IconButton>

            <React.Fragment>
              <Box
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <IconButton
                  className="avatar"
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2, justifyContent: "flex-end", display: "flex" }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      bgcolor: "#068ce5",
                    }}
                    alt={userInfo?.first_name[0] + userInfo?.last_name[0]}
                    src={s3baseUrl + userInfo?.profile_image}
                  />
                </IconButton>
              </Box>
            </React.Fragment>
          </Toolbar>
        </AppBar>
      </Box>
      <ProfileMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        handleLogout={handleLogout}
        name={userInfo?.first_name + " " + userInfo?.last_name}
        email={userInfo?.email}
      />
    </>
  );
}
