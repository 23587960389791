import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { _close_invoice } from "../../DAL/Shipment/close_invoice/close_invoice";

export default function CloseInvoice({
  apiFunction,
  selectedRow,
  onCloseDrawer,
}) {
  const [inputs, setInputs] = useState({
    refund_amount: 0,
    adjusted_total_refund_amount: 0,
    total_amount_due: 0,
    paid_status: "",
    refund_type: "",
    invoice_amount: 0,
    invoice_deposit_amount: 0,
    shipping_amount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [update, setUpdate] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

    if (
      value != "" &&
      (name === "adjusted_total_refund_amount" ||
        name === "invoice_amount" ||
        name === "invoice_deposit_amount")
    ) {
      const adjustedRefundAmount =
        name === "adjusted_total_refund_amount"
          ? parseFloat(value)
          : parseFloat(inputs.adjusted_total_refund_amount);

      const invoiceAmount =
        name === "invoice_amount"
          ? parseFloat(value)
          : parseFloat(inputs.invoice_amount);

      const invoiceDepositAmount =
        name === "invoice_deposit_amount"
          ? parseFloat(value)
          : parseFloat(inputs.invoice_deposit_amount);

      const totalAmountDue = parseFloat(
        invoiceAmount - (adjustedRefundAmount + invoiceDepositAmount)
      );

      setInputs((values) => ({ ...values, total_amount_due: totalAmountDue }));
    }
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const objectData = {
      refund_amount: inputs.refund_amount,
      adjusted_total_refund_amount: inputs.adjusted_total_refund_amount,
      total_amount_due: inputs.total_amount_due,
      paid_status: inputs.paid_status,
      refund_type: inputs.refund_type,
      invoice_amount: inputs.invoice_amount,
      invoice_deposit_amount: inputs.invoice_deposit_amount,
      shipping_amount: inputs.shipping_amount,
    };
    const result = await _close_invoice(objectData, selectedRow._id);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      onCloseDrawer();
      apiFunction();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRow.close_invoice) {
      setUpdate(true);
      setInputs({
        adjusted_total_refund_amount:
          selectedRow.close_invoice.adjusted_total_refund_amount,
        refund_amount: selectedRow.total_refund_amount,
        total_amount_due: selectedRow.close_invoice.total_amount_due,
        paid_status: selectedRow.close_invoice.paid_status,
        refund_type: selectedRow.close_invoice.refund_type,
        invoice_amount: selectedRow.close_invoice.invoice_amount,
        invoice_deposit_amount:
          selectedRow.close_invoice.invoice_deposit_amount,
        shipping_amount: selectedRow.close_invoice.shipping_amount,
      });
    } else {
      setInputs((values) => ({
        ...values,
        refund_amount: selectedRow.total_refund_amount,
      }));
    }
  }, []);

  return (
    <div className="container">
      <div className="row mt-3">
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Invoice Amount"
                value={inputs?.invoice_amount}
                required
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={handleChange}
                name="invoice_amount"
                onKeyPress={(event) => {
                  if (!/[.,0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Invoice Deposit Amount"
                value={inputs?.invoice_deposit_amount}
                required
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={handleChange}
                name="invoice_deposit_amount"
                onKeyPress={(event) => {
                  if (!/[.,0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Shipping Amount"
                value={inputs?.shipping_amount}
                required
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={handleChange}
                name="shipping_amount"
                onKeyPress={(event) => {
                  if (!/[.,0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Calculated Total Refund Amount"
                value={inputs?.refund_amount}
                variant="outlined"
                sx={{ width: "100%" }}
                name="refund_amount"
                disabled
              />
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Adjusted Total Refund Amount"
                value={inputs?.adjusted_total_refund_amount}
                required
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={handleChange}
                name="adjusted_total_refund_amount"
                onKeyPress={(event) => {
                  if (!/[.,0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Total Amount Due"
                variant="outlined"
                sx={{ width: "100%" }}
                name="total_amount_due"
                value={inputs?.total_amount_due}
                disabled
              />
            </FormControl>
          </div>

          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Status*</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="paid_status"
                value={inputs.paid_status}
                required
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="unpaid">Un Paid</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Refund type*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="refund_type"
                value={inputs.refund_type}
                required
                label="Refund type"
                onChange={handleChange}
              >
                <MenuItem value="refunded">Refunded</MenuItem>
                <MenuItem value="vendor_credit">Vendor Credit</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-12 text-end mt-3">
            <button
              className="small-contained-button"
              type="submit"
              disabled={isLoading}
            >
              {isLoading
                ? update === true
                  ? "Updating..."
                  : "Saving..."
                : update === false
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
