import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";

import {
  _add_attributeVarients,
  _detail_attribute,
  _edit_attribute,
} from "../../DAL/AttributeVarients/AttributeVarients";

function AddAttribute() {
  const param = useParams();
  const { state } = useLocation();
  const pageSlug = param.page_slug;
  const navigate = useNavigate();
  const { userInfo, privilegeAccess } = useContentSetting();
  const [addArray, setaddArray] = useState([{ item_name: "" }]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    config: true,
    name: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeItems = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (addArray[0].item_name == "") {
      enqueueSnackbar("Please Add Atleast One Item ", { variant: "error" });
      setIsLoading(false);
    } else {
      let postData = {
        attribute_name: inputs.name,
        configure_items: inputs.config,
        attribute_items: addArray,
      };
      setIsLoading(true);

      const result = pageSlug
        ? await _edit_attribute(postData, state.editValues.attribute_slug)
        : await _add_attributeVarients(postData);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        handleBack();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };
  const handleAdd = () => {
    const addedElement = [...addArray, { item_name: "" }];
    setaddArray(addedElement);
  };
  const detailAttribute = async () => {
    setIsLoading(true);
    const result = await _detail_attribute(pageSlug);
    if (result.code == 200) {
      setaddArray(result.Attribute_Varient.attribute_items);
      setInputs({
        ...inputs,
        ["name"]: result.Attribute_Varient?.attribute_name,
        ["config"]: result.Attribute_Varient?.configure_items,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.add_attribute_variant) {
      navigate(`/attribute-variants`);
    }
    if (userInfo.type !== 0 && !privilegeAccess.edit_attribute_variant) {
      navigate(`/attribute-variants`);
    }
    if (pageSlug) {
      if (state) {
        setaddArray(state.editValues?.attribute_items);
        setInputs({
          ...inputs,
          ["name"]: state.editValues?.attribute_name,
          ["config"]: state.editValues?.configure_items,
        });
      } else {
        detailAttribute();
      }
    }
  }, []);
  //===================================================================
  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h3 className="my-3">
            {pageSlug ? "Edit Attribute Variants" : "Add Attribute Variants"}
          </h3>
        </div>
      </div>

      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div className="row">
          <div className="col-md-6 col-xs-12 mb-2">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="name"
              value={inputs?.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-2">
            <FormControl fullWidth sx={{ mb: 0 }}>
              <InputLabel id="demo-simple-select-label">
                Do you want to use configure items
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="config"
                defaultValue="true"
                value={inputs.config}
                disabled
                required
                label="Do you want to use configure items"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12">
            <div className="row align-items-center">
              {inputs.config == true ? (
                <>
                  {addArray?.map((data, index) => {
                    return (
                      <>
                        <div className="col-10 col-lg-5 mt-4">
                          <TextField
                            id="outlined-basic"
                            label="Item Name"
                            variant="outlined"
                            name="item_name"
                            fullWidth
                            value={data.item_name}
                            onChange={(e) => handleChangeItems(e, index)}
                          />
                        </div>

                        <span className="col-2 col-lg-1 mt-3">
                          {addArray.length > 1 ? (
                            <Tooltip title="Remove">
                              <RemoveCircleOutlineIcon
                                onClick={() => handleDelete(index)}
                                className="diary-icon-remove"
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          <Tooltip title="Add">
                            <AddCircleOutlineIcon
                              onClick={() => handleAdd()}
                              className="diary-icon-add"
                            />
                          </Tooltip>
                        </span>
                      </>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 mt-3 text-end">
          <Button variant="contained" type="submit">
            {pageSlug ? "Update" : "Add"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddAttribute;
