import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_social_setting_admin = async (data) => {
    const requestObj = {
      path: `api/website_settings/get_social_setting_admin`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
  export const _add_social_settings_admin = async (data) => {
    const requestObj = {
        path: `api/website_settings/add_social_settings_admin`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };