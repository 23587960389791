import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import { Button, CircularProgress, TextField, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { _general_Image_upload } from "../../DAL/general_image_upload/general_Image_upload";
import {
  _get_whats_on_stock_setting,
  _add_whats_on_stock_setting,
} from "../../DAL/website_setting/whats_on_stock_setting";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

function WhatsOnStockSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { userInfo, privilegeAccess } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    page_heading: "",
    top_description: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const get_what_on_stock_settings_list = async () => {
    setIsLoading(true);

    const result = await _get_whats_on_stock_setting();
    if (result.code === 200) {
      setInputs(result.what_on_stock_settings);

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_stock_setting === true ||
        privilegeAccess.edit_stock_setting === true
      ) {
        setIsButtonLoading(true);
        const result = await _add_whats_on_stock_setting({
          what_on_stock_settings: inputs,
        });
        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      setIsButtonLoading(true);
      const result = await _add_whats_on_stock_setting({
        what_on_stock_settings: inputs,
      });
      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };
  useEffect(() => {
    get_what_on_stock_settings_list();
  }, []);
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  //===================================================================

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="my-3">What's on Stock Setting</h3>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess.view_stock_setting ? (
          <NoAccessData />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data">
            <div className="row">
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  required
                  label="Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="page_heading"
                  onChange={handleChange}
                  value={inputs?.page_heading}
                />
              </div>
            </div>
            {/*  Top description*/}
            <div className="row">
              <div className="col-12 mb-4">
                <p className="home_page_header">Top Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="top_description"
                  editorHeight={350}
                />
              </div>
            </div>

            <div className="col-12 mt-3 text-end">
              <Tooltip
                title={
                  userInfo.type == 2 &&
                  (privilegeAccess.edit_stock_setting ||
                    privilegeAccess.add_stock_setting) === false
                    ? "You have no access to perform this action"
                    : ""
                }
                placement="right-end"
                arrow>
                {isButtonLoading ? (
                  <LoadingButton loading variant="contained">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    // style={{ textTransform: 'capitalize' }}
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                )}
              </Tooltip>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default WhatsOnStockSetting;
