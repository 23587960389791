import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_task_list = async (data) => {
    const requestObj = {
      path: `api/task/list_task`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
  export const _add_new_task = async (data) => {
    const requestObj = {
        path: `api/task/add_task`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_task = async (id) => {
    const requestObj = {
      path: `api/task/delete_task/${id}`,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
  export const _edit_task = async (postData, _id) => {
    const requestObj = {
      path: `api/task/edit_task/${_id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: postData,
    };
    return invokeApi(requestObj);
  };

  export const _update_task_status = async (postData, _id) => {
    const requestObj = {
      path: `api/task/change_task_status/${_id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: postData,
    };
    return invokeApi(requestObj);
  };

  export const _assign_task = async (data, _id) => {
    const requestObj = {
        path: `api/task/assign_task/${_id}`,
        method: "POST",
        headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
  export const _task_filter = async (data) => {
    const requestObj = {
        path: `api/task/task_filter`,
        method: "POST",
        headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _change_task_priority = async (postData, _id) => {
    const requestObj = {
      path: `api/task/change_task_priority/${_id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: postData,
    };
    return invokeApi(requestObj);
  };

  export const _set_task_date_time = async (postData, _id) => {
    const requestObj = {
      path: `api/task/set_task_date_time/${_id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: postData,
    };
    return invokeApi(requestObj);
  };

  export const _update_order_task = async (postData, _id) => {
    const requestObj = {
      path: `api/task/update_order_task/${_id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: postData,
    };
    return invokeApi(requestObj);
  };