import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Icon } from "@iconify/react";

export default function Filters(props) {
  const [selectedCheckbox, setSelectedCheckbox] = useState(props.value);

  const handleCheckboxChange = (event) => {
    setSelectedCheckbox(event.target.name);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    props.setValue(selectedCheckbox);
    props.setFilter(true);
    props.onCloseDrawer();
    props.setPageStatus(0);
    // props.apiFunctionStatus(selectedCheckbox, 0);
  };
  const handleClear = async (e) => {
    e.preventDefault();
    props.setFilter(false);
    props.setValue();
    props.apiFunction();
    props.onCloseDrawer();
  };

  return (
    <div className="container">
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCheckbox === "pending"}
                  onChange={handleCheckboxChange}
                  name="pending"
                />
              }
              label="Pending"
            />
          </div>
          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCheckbox === "ready_to_ship"}
                  onChange={handleCheckboxChange}
                  name="ready_to_ship"
                />
              }
              label="Ready To Ship"
            />
          </div>

          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCheckbox === "shipped"}
                  onChange={handleCheckboxChange}
                  name="shipped"
                />
              }
              label="Shipped"
            />
          </div>

          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCheckbox === "delivered"}
                  onChange={handleCheckboxChange}
                  name="delivered"
                />
              }
              label="Delivered"
            />
          </div>

          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCheckbox === "cancelled"}
                  onChange={handleCheckboxChange}
                  name="cancelled"
                />
              }
              label="Cancelled"
            />
          </div>

          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCheckbox === "returned"}
                  onChange={handleCheckboxChange}
                  name="returned"
                />
              }
              label="Returned"
            />
          </div>

          <div className="col-12 mt-3">
            <button className="small-contained-button  w-100" type="submit">
              <Icon
                icon="material-symbols:filter-alt"
                style={{ width: 18, height: 18, marginRight: 15 }}
              />
              Filter
            </button>
          </div>
          <div className="col-12 mt-3">
            <button
              className="small-contained-button  w-100"
              onClick={handleClear}>
              <Icon
                icon="material-symbols:clear-all-rounded"
                style={{ width: 18, height: 18, marginRight: 10 }}
              />
              Clear All
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
