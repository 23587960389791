import { Box, Drawer } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React, { useState } from "react";
import "../../assets/css/style.css";
import SidebarConfig from "./SidebarConfig";
import NavSection from "./NavSection";
import { useNavigate } from "react-router-dom";
import { sidebar_logo } from "../../assets";

export default function DashboardSidebar({
  handleDrawerToggle,
  mobileOpen,
  drawerWidth,
}) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/dashboard");
  };

  const drawer = (
    <div>
      <Box sx={{ my: 0, mx: 2.5 }}>
        <div className="dashboard-sidebar" onClick={() => handleNavigate()}>
          <Box className="box">
            <Avatar
              className="bar-img"
              variant="square"
              sx={{ height: 100, width: 130 }}
              alt="Remy Sharp"
              src={sidebar_logo}
            />
          </Box>
        </div>
      </Box>
      <NavSection navConfig={SidebarConfig()} />
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      className="app-sidebar-box">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open>
        {drawer}
      </Drawer>
    </Box>
  );
}
