import { invokeApi } from "../../../bl_libs/invokeApi";

export const _invoice_deposit = async (postData, shipment_id) => {
  const requestObj = {
    path: `api/shipment/invoice_deposit_to_shipment/${shipment_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
