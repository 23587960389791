import React, { useEffect } from "react";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import { useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import Filters from "../../components/Orders/Filters";
import { Icon } from "@iconify/react";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import Chip from "@material-ui/core/Chip";
import { useNavigate } from "react-router-dom";
import {
  _list_orders,
  _list_orders_with_status,
} from "../../DAL/orders/orders";
import moment from "moment/moment";
import { CircularProgress, Tooltip, Typography } from "@mui/material";
import BillingInfo from "../../components/Orders/BillingInfo";
import CustomPopover from "../../components/GeneralComponents/CustomPopover";
import OrderDetail from "./OrderDetail";
import { _change_order_status } from "../../DAL/orders/orders";
import { useSnackbar } from "notistack";
import CustomModal from "../../components/GeneralComponents/CustomModal";
import Reason from "../../components/Orders/Reason";

export default function Orders() {
  const { privilegeAccess, userInfo } = useContentSetting();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [billingDrawerState, setBillingDrawerState] = useState(false);
  const [billingInfo, setBillingInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [value, setValue] = useState();
  const [ordersData, setOrdersData] = useState([]);
  const [data, setData] = useState(ordersData);
  const [drawerState, setDrawerState] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [reset, setReset] = useState(0);
  const [pageStatus, setPageStatus] = useState(0);
  const [rowsPerPageStatus, setRowsPerPageStatus] = useState(50);
  const [totalCountStatus, setTotalCountStatus] = useState(0);
  const [showPop, setshowPop] = useState(false);
  const [orderDetail, setOrderDetail] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [statusReason, setStatusReason] = useState(false);

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      label: "Order#",
      alignRight: false,
      renderData: (row) => {
        return (
          <Tooltip title="View Order Detail" placement="bottom-end" arrow>
            <Typography
              className="cursor-table"
              fontSize="25"
              onClick={() => {
                handleOpenPopup(row);
              }}>
              {row.order_id}
            </Typography>
          </Tooltip>
        );
      },
    },
    { id: "customer_info", label: "Customer", alignRight: false },
    { id: "total_price", label: "Total Cost", alignRight: false },
    { id: "discount", label: "Discount", alignRight: false },
    { id: "net_amount", label: "Net Amount", alignRight: false },
    { id: "order_date", label: "Date", alignRight: false },
    {
      id: "status",
      label: "Status",
      alignRight: false,
      renderData: (row) => {
        let chipColor;
        switch (row.order_status) {
          case "pending":
            chipColor = "#ff9800";
            break;
          case "ready_to_ship":
            chipColor = "#03a9f4";
            break;
          case "shipped":
            chipColor = "#0288d1";
            break;
          case "delivered":
            chipColor = "#4caf50";
            break;
          case "cancelled":
            chipColor = "#cccccc";
            break;
          case "returned":
            chipColor = "#ef5350";
            break;
          default:
            chipColor = "#ff9800";
            break;
        }
        return (
          <Chip
            label={row.order_status}
            style={{ backgroundColor: chipColor }}></Chip>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      alignRight: false,
      MENU_OPTIONS: "MENU_OPTIONS",
      type: "action",
    },
  ];
  const handleOpenPopup = (row) => {
    setOrderDetail(row);
    handlePrivilegesPopups();
  };
  const handlePrivilegesPopups = () => {
    setshowPop(true);
  };
  const handleOpenDraweradd = () => {
    if (userInfo.type === 2) {
      if (privilegeAccess.view_order === true) {
        setDrawerState(true);
      }
    } else {
      setDrawerState(true);
    }
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const handleCreateOrder = () => {
    if (userInfo.type === 2) {
      if (privilegeAccess.add_order === true) {
        navigate(`/orders/create-order`, {});
      }
    } else {
      navigate(`/orders/create-order`, {});
    }
  };
  const handleChangeStatus = async (filtered_value, row) => {
    // setOrderId(row._id);
    // setOpenDialouge(true);
    // setStatus(row.order_status);

    const objectData = {
      order_status: filtered_value,
    };
    const result = await _change_order_status(objectData, row._id);
    if (result.code === 200) {
      if (value) {
        get_orders_data_with_status();
      } else {
        get_orders_data_no_loader();
      }
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleStatusCancelled = async (filtered_value, row) => {
    setOrderId(row._id);
    setOpenModel(true);
    setStatusReason(filtered_value);
  };
  const handleStatusReturned = async (filtered_value, row) => {
    setOrderId(row._id);
    setOpenModel(true);
    setStatusReason(filtered_value);
  };
  const handleOrderPrepare = (value) => {
    if (userInfo.type === 2) {
      if (
        privilegeAccess.view_order === true ||
        privilegeAccess.edit_order === true
      ) {
        delete value.MENU_OPTIONS;
        navigate(`/orders/order-prepare/${value._id}`, {
          state: value,
        });
      }
    } else {
      delete value.MENU_OPTIONS;
      navigate(`/orders/order-prepare/${value._id}`, {
        state: value,
      });
    }
  };

  // billing info drawer
  const handleBillingInfo = (value) => {
    setBillingInfo(value);
    setBillingDrawerState(true);
  };
  const handleCloseBillingDrawer = () => {
    setBillingDrawerState(false);
  };

  const handle_menus = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Order Detail",
        icon: "carbon:data-view-alt",
        handleClick: handleOpenPopup,
      },
    ];
    if (row.order_status == "pending") {
      MENU_OPTIONS.splice(0, 0, {
        label: "Prepare Order",
        icon: "cil:color-border",
        handleClick: handleOrderPrepare,
        is_access: userInfo.type === 0 ? true : privilegeAccess.add_order,
      });
    }
    if (row.order_status == "shipped") {
      MENU_OPTIONS.splice(0, 0, {
        label: "Delivered",
        icon: "mdi:package-variant-closed-delivered",
        handleClick: () => handleChangeStatus("delivered", row),
        is_access: userInfo.type === 0 ? true : privilegeAccess.add_order,
      });
    }
    if (row.order_status == "ready_to_ship") {
      MENU_OPTIONS.splice(0, 0, {
        label: "Shipped",
        icon: "la:shipping-fast",
        handleClick: () => handleChangeStatus("shipped", row),
        is_access: userInfo.type === 0 ? true : privilegeAccess.edit_order,
      });
    }
    if (
      row.order_status == "pending" ||
      row.order_status == "ready_to_ship" ||
      row.order_status == "shipped"
    ) {
      MENU_OPTIONS.splice(1, 0, {
        label: "Cancelled",
        icon: "mdi:cart-remove",
        handleClick: () => handleStatusCancelled("cancelled", row),
        is_access: userInfo.type === 0 ? true : privilegeAccess.edit_order,
      });
    }
    if (row.order_status == "shipped" || row.order_status == "delivered") {
      MENU_OPTIONS.splice(0, 0, {
        label: "Returned",
        icon: "tabler:truck-return",
        handleClick: () => handleStatusReturned("returned", row),
        is_access: userInfo.type === 0 ? true : privilegeAccess.edit_order,
      });
    }

    return MENU_OPTIONS;
  };
  // orders listing with status
  const get_orders_data_with_status = async () => {
    setIsLoading(true);
    const response = await _list_orders_with_status(
      pageStatus,
      rowsPerPageStatus,
      value
    );
    if (response.code === 200) {
      let data = response.order.map((order) => {
        let user_name = "";
        if (order.customer?.first_name) {
          user_name = order.customer?.first_name;
          if (order.customer?.last_name) {
            user_name = user_name + " " + order.customer?.last_name;
            if (order?.email) {
              user_name = user_name + " (" + order?.email + ")";
            }
          } else {
            if (order?.email) {
              user_name = user_name + " (" + order?.email + ")";
            }
          }
        } else {
          user_name = "Guest User";
        }
        let totalPrice = 0;
        if (order.order_total_without_discount) {
          totalPrice = order.order_total_without_discount.toFixed(2);
        } else {
          totalPrice = totalPrice.toFixed(2);
        }
        let netAmount = 0;
        if (order.total_price_after_discount) {
          netAmount = order.total_price_after_discount.toFixed(2);
        } else {
          netAmount = netAmount.toFixed(2);
        }
        let discountAmount = 0.0;
        if (order.total_order_discount) {
          discountAmount = order.total_order_discount.toFixed(2);
        } else {
          discountAmount = discountAmount.toFixed(2);
        }
        return {
          ...order,
          MENU_OPTIONS: handle_menus(order),
          customer_info: user_name,
          order_date: moment(order.createdAt).format("DD-MM-YYYY"),
          total_price: "$" + totalPrice,
          shipping_state: order.billing_info?.states,
          discount: "$" + discountAmount,
          net_amount: "$" + netAmount,
        };
      });
      setTotalCountStatus(response.total_count);
      setOrdersData(data);
      setData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  // orders listing
  const get_orders_data = async () => {
    setIsLoading(true);
    const response = await _list_orders(page, rowsPerPage, searchText);
    if (response.code === 200) {
      let data = response.order.map((order) => {
        let user_name = "";
        if (order.customer?.first_name) {
          user_name = order.customer?.first_name;
          if (order.customer?.last_name) {
            user_name = user_name + " " + order.customer?.last_name;
            if (order?.email) {
              user_name = user_name + " (" + order?.email + ")";
            }
          } else {
            if (order?.email) {
              user_name = user_name + " (" + order?.email + ")";
            }
          }
        } else {
          user_name = "Guest User";
        }
        let totalPrice = 0;
        if (order.order_total_without_discount) {
          totalPrice = order.order_total_without_discount.toFixed(2);
        } else {
          totalPrice = totalPrice.toFixed(2);
        }
        let netAmount = 0;
        if (order.total_price_after_discount) {
          netAmount = order.total_price_after_discount.toFixed(2);
        } else {
          netAmount = netAmount.toFixed(2);
        }
        let discountAmount = 0.0;
        if (order.total_order_discount) {
          discountAmount = order.total_order_discount.toFixed(2);
        } else {
          discountAmount = discountAmount.toFixed(2);
        }
        return {
          ...order,
          MENU_OPTIONS: handle_menus(order),
          customer_info: user_name,
          order_date: moment(order.createdAt).format("DD-MM-YYYY"),
          total_price: "$" + totalPrice,
          shipping_state: order.billing_info?.states,
          discount: "$" + discountAmount,
          net_amount: "$" + netAmount,
        };
      });
      setTotalCount(response.total_count);
      setOrdersData(data);
      setData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  // orders listng for status change without loader
  const get_orders_data_no_loader = async () => {
    const response = await _list_orders(page, rowsPerPage, searchText);
    if (response.code === 200) {
      let data = response.order.map((order) => {
        let user_name = "";
        if (order.customer?.first_name) {
          user_name = order.customer?.first_name;
          if (order.customer?.last_name) {
            user_name = user_name + " " + order.customer?.last_name;
            if (order?.email) {
              user_name = user_name + " (" + order?.email + ")";
            }
          } else {
            if (order?.email) {
              user_name = user_name + " (" + order?.email + ")";
            }
          }
        } else {
          user_name = "Guest User";
        }
        let totalPrice = 0;
        if (order.order_total_without_discount) {
          totalPrice = order.order_total_without_discount.toFixed(2);
        } else {
          totalPrice = totalPrice.toFixed(2);
        }
        let netAmount = 0;
        if (order.total_price_after_discount) {
          netAmount = order.total_price_after_discount.toFixed(2);
        } else {
          netAmount = netAmount.toFixed(2);
        }
        let discountAmount = 0.0;
        if (order.total_order_discount) {
          discountAmount = order.total_order_discount.toFixed(2);
        } else {
          discountAmount = discountAmount.toFixed(2);
        }
        return {
          ...order,
          customer_info: user_name,
          MENU_OPTIONS: handle_menus(order),
          order_date: moment(order.createdAt).format("DD-MM-YYYY"),
          total_price: "$" + totalPrice,
          shipping_state: order.billing_info?.states,
          discount: "$" + discountAmount,
          net_amount: "$" + netAmount,
        };
      });
      setTotalCount(response.total_count);
      setOrdersData(data);
      setData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  // pages handling without Filters
  const searchFunction = () => {
    setPage(0);
    get_orders_data();
  };
  const searchResetFunction = () => {
    setValue();
    setSearchText("");
    setPage(0);
    if (reset === 0) {
      setReset(1);
    } else {
      setReset(0);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // pages handling for filters
  const handleChangeRowsPerPageStatus = (event) => {
    setRowsPerPageStatus(parseInt(event.target.value, 10));
    setPageStatus(0);
  };

  const handleChangePageStatus = (event, newPage) => {
    setPageStatus(newPage);
  };

  useEffect(() => {
    get_orders_data();
  }, [page, rowsPerPage, reset]);

  useEffect(() => {
    if (value) {
      get_orders_data_with_status();
    }
  }, [value, pageStatus, rowsPerPageStatus]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Orders</h3>
          </div>

          <div className="col-6 text-end">
            {/* <Tooltip
              title={
                userInfo.type === 2 && privilegeAccess.add_order === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              <button
                className="small-contained-button"
                onClick={handleCreateOrder}>
                Create Order
              </button>
            </Tooltip> */}

            <Tooltip
              title={
                userInfo.type === 2 && privilegeAccess.view_order === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              <button
                className="small-contained-button"
                style={{ marginLeft: 10 }}
                onClick={handleOpenDraweradd}>
                <Icon
                  icon="material-symbols:filter-alt"
                  style={{ width: 18, height: 18, marginRight: 10 }}
                />
                Filters
              </button>
            </Tooltip>
          </div>
        </div>

        {userInfo.type === 2 && !privilegeAccess.view_order ? (
          <NoAccessData />
        ) : (
          <>
            {value ? (
              <div className="row mt-3">
                <div className="col-12">
                  <CustomMUITable
                    TABLE_HEAD={TABLE_HEAD}
                    data={data}
                    className="card-with-background"
                    custom_pagination={{
                      total_count: totalCountStatus,
                      rows_per_page: rowsPerPageStatus,
                      page: pageStatus,
                      handleChangePage: handleChangePageStatus,
                      onRowsPerPageChange: handleChangeRowsPerPageStatus,
                    }}
                    custom_search={{
                      searchText: searchText,
                      setSearchText: setSearchText,
                      handleSubmit: searchFunction,
                      handleReset: searchResetFunction,
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="row mt-3">
                <div className="col-12">
                  <CustomMUITable
                    TABLE_HEAD={TABLE_HEAD}
                    data={data}
                    className="card-with-background"
                    custom_pagination={{
                      total_count: totalCount,
                      rows_per_page: rowsPerPage,
                      page: page,
                      handleChangePage: handleChangePage,
                      onRowsPerPageChange: handleChangeRowsPerPage,
                    }}
                    custom_search={{
                      searchText: searchText,
                      setSearchText: setSearchText,
                      handleSubmit: searchFunction,
                      handleReset: searchResetFunction,
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <Filters
            pageStatus={pageStatus}
            setPageStatus={setPageStatus}
            setValue={setValue}
            value={value}
            onCloseDrawer={handleCloseEditDrawer}
            filter={filter}
            setFilter={setFilter}
            apiFunction={get_orders_data}
            apiFunctionStatus={get_orders_data_with_status}
          />
        }
      />

      <CustomDrawer
        isOpenDrawer={billingDrawerState}
        onCloseDrawer={handleCloseBillingDrawer}
        pageTitle={"Billing Info"}
        componentToPassDown={<BillingInfo selectedRow={billingInfo} />}
      />
      <CustomPopover
        isOpenPop={showPop}
        isClosePop={setshowPop}
        title={"Order Detail"}
        status={orderDetail.order_status}
        componentToPassDown={<OrderDetail orderDetail={orderDetail} />}
      />
      <CustomModal
        title={statusReason == "cancelled" ? "Cancel Order" : "Return Order"}
        openModel={openModel}
        setOpenModel={setOpenModel}
        componentToPassDown={
          <Reason
            setOpenModel={setOpenModel}
            statusReason={statusReason}
            setStatusReason={setStatusReason}
            orderId={orderId}
            apiFunction={
              value ? get_orders_data_with_status : get_orders_data_no_loader
            }
          />
        }
      />
    </>
  );
}
