import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { _add_employee } from "../../DAL/employee/employee";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";

const Input = styled("input")({
  display: "none",
});
function AddEmployees() {
  const navigate = useNavigate();
  const { userInfo, privilegeAccess } = useContentSetting();

  const [Previvlages, setPrevivlages] = useState([
    {
      privilege_item_lable: "Employee",
      privilege_item_for: "employee",
      privilege_items: [
        {
          privilege_label_text: "Add Employee",
          privilege_label_for: "add_employee",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Employee",
          privilege_label_for: "edit_employee",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Employee",
          privilege_label_for: "delete_employee",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Employee",
          privilege_label_for: "view_employee",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Customers",
      privilege_item_for: "customers",
      privilege_items: [
        {
          privilege_label_text: "Add Customers",
          privilege_label_for: "add_customers",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Customers",
          privilege_label_for: "edit_customers",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Customers",
          privilege_label_for: "view_customers",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Vendor",
      privilege_item_for: "vendor",
      privilege_items: [
        {
          privilege_label_text: "Add Vendor",
          privilege_label_for: "add_vendor",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Vendor",
          privilege_label_for: "edit_vendor",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Vendor",
          privilege_label_for: "delete_vendor",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Vendor",
          privilege_label_for: "view_vendor",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Category",
      privilege_item_for: "category",

      privilege_items: [
        {
          privilege_label_text: "Add Category",
          privilege_label_for: "add_category",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Category",
          privilege_label_for: "edit_category",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Category",
          privilege_label_for: "delete_category",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Category",
          privilege_label_for: "view_category",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Attribute Variant",
      privilege_item_for: "attribute_variant",
      privilege_items: [
        {
          privilege_label_text: "Add Attribute Variant",
          privilege_label_for: "add_attribute_variant",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Attribute Variant",
          privilege_label_for: "edit_attribute_variant",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Attribute Variant",
          privilege_label_for: "delete_attribute_variant",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Attribute Variant",
          privilege_label_for: "view_attribute_variant",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Product",
      privilege_item_for: "product",
      privilege_items: [
        {
          privilege_label_text: "Add Product",
          privilege_label_for: "add_product",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Product",
          privilege_label_for: "edit_product",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Product",
          privilege_label_for: "delete_product",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Product",
          privilege_label_for: "view_product",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "What's on Stock",
      privilege_item_for: "stock",
      privilege_items: [
        {
          privilege_label_text: "View What's on Stock",
          privilege_label_for: "view_stock",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Extra Expenses",
      privilege_item_for: "extra_expense",
      privilege_items: [
        {
          privilege_label_text: "Add Extra Expenses",
          privilege_label_for: "add_extra_expense",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Extra Expenses",
          privilege_label_for: "edit_extra_expense",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Extra Expenses",
          privilege_label_for: "delete_extra_expense",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Extra Expenses",
          privilege_label_for: "view_extra_expense",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Shipment",
      privilege_item_for: "shipment",
      privilege_items: [
        {
          privilege_label_text: "Add Shipment",
          privilege_label_for: "add_shipment",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Shipment",
          privilege_label_for: "edit_shipment",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Shipment",
          privilege_label_for: "delete_shipment",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Shipment",
          privilege_label_for: "view_shipment",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Shipment Prices",
      privilege_item_for: "shipment_prices",
      privilege_items: [
        {
          privilege_label_text: "Add Shipment Prices",
          privilege_label_for: "add_shipment_prices",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Shipment Prices",
          privilege_label_for: "edit_shipment_prices",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Shipment Prices",
          privilege_label_for: "delete_shipment_prices",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Shipment Prices",
          privilege_label_for: "view_shipment_prices",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Order",
      privilege_item_for: "order",
      privilege_items: [
        {
          privilege_label_text: "Add Order",
          privilege_label_for: "add_order",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Order",
          privilege_label_for: "edit_order",
          privilege_access: false,
        },

        {
          privilege_label_text: "View Order",
          privilege_label_for: "view_order",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Task",
      privilege_item_for: "task",
      privilege_items: [
        {
          privilege_label_text: "Add Task",
          privilege_label_for: "add_task",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Task",
          privilege_label_for: "edit_task",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Task",
          privilege_label_for: "delete_task",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Task",
          privilege_label_for: "view_task",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Website FAQs",
      privilege_item_for: "website_faqs",
      privilege_items: [
        {
          privilege_label_text: "Add Website FAQs",
          privilege_label_for: "add_website_faqs",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Website FAQs",
          privilege_label_for: "edit_website_faqs",
          privilege_access: false,
        },
        {
          privilege_label_text: "Delete Website FAQs",
          privilege_label_for: "delete_website_faqs",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Website FAQs",
          privilege_label_for: "view_website_faqs",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "General Setting",
      privilege_item_for: "general_setting",
      privilege_items: [
        {
          privilege_label_text: "Add General Setting",
          privilege_label_for: "add_general_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit General Setting",
          privilege_label_for: "edit_general_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View General Setting",
          privilege_label_for: "view_general_setting",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Home Setting",
      privilege_item_for: "home_setting",
      privilege_items: [
        {
          privilege_label_text: "Add Home Setting",
          privilege_label_for: "add_home_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Home Setting",
          privilege_label_for: "edit_home_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Home Setting",
          privilege_label_for: "view_home_setting",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Social Setting",
      privilege_item_for: "Social_setting",
      privilege_items: [
        {
          privilege_label_text: "Add Social Setting",
          privilege_label_for: "add_Social_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Social Setting",
          privilege_label_for: "edit_Social_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Social Setting",
          privilege_label_for: "view_Social_setting",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Contact Us Setting",
      privilege_item_for: "contact_us_setting",
      privilege_items: [
        {
          privilege_label_text: "Add Contact Us Setting",
          privilege_label_for: "add_contact_us_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Contact Us Setting",
          privilege_label_for: "edit_contact_us_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Contact Us Setting",
          privilege_label_for: "view_contact_us_setting",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Payment Setting",
      privilege_item_for: "payment_setting",
      privilege_items: [
        {
          privilege_label_text: "Add Payment Setting",
          privilege_label_for: "add_payment_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Payment Setting",
          privilege_label_for: "edit_payment_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Payment Setting",
          privilege_label_for: "view_payment_setting",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Product Detail Setting",
      privilege_item_for: "product_detail_setting",
      privilege_items: [
        {
          privilege_label_text: "Add Product Detail Setting",
          privilege_label_for: "add_product_detail_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Product Detail Setting",
          privilege_label_for: "edit_product_detail_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Product Detail Setting",
          privilege_label_for: "view_product_detail_setting",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "What's on Stock Setting",
      privilege_item_for: "stock_setting",
      privilege_items: [
        {
          privilege_label_text: "Add What's on Stock Setting",
          privilege_label_for: "add_stock_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit What's on Stock Setting",
          privilege_label_for: "edit_stock_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View What's on Stock Setting",
          privilege_label_for: "view_stock_setting",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "About Us Setting",
      privilege_item_for: "about_us_setting",
      privilege_items: [
        {
          privilege_label_text: "Add About Us Setting",
          privilege_label_for: "add_about_us_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit About Us Setting",
          privilege_label_for: "edit_about_us_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View About Us Setting",
          privilege_label_for: "view_about_us_setting",
          privilege_access: false,
        },
      ],
    },
    {
      privilege_item_lable: "Checkout Setting",
      privilege_item_for: "checkout_setting",
      privilege_items: [
        {
          privilege_label_text: "Add Checkout Setting",
          privilege_label_for: "add_checkout_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "Edit Checkout Setting",
          privilege_label_for: "edit_checkout_setting",
          privilege_access: false,
        },
        {
          privilege_label_text: "View Checkout Setting",
          privilege_label_for: "view_checkout_setting",
          privilege_access: false,
        },
      ],
    },
  ]);
  const [image, setImage] = useState({});
  const [previews, setPreviews] = useState();
  const [buttonText, setButtonText] = useState(() =>
    Array.from({ length: Previvlages.length }, () => "Select All")
  );
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    status: true,
    address: "",
    email: "",
    password: "",
    contact_number: "",
  });
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("status", inputs.status);
    formData.append("privilages", JSON.stringify(Previvlages));
    formData.append("address", inputs.address);
    formData.append("email", inputs.email);
    if (previews) {
      formData.append("image", image);
    }
    formData.append("password", inputs.password);
    formData.append("contact_number", inputs.contact_number);

    const result = await _add_employee(formData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSelectAll = (ParentIndex) => {
    const newPrevivlages = [...Previvlages];
    const allChecked = newPrevivlages[ParentIndex].privilege_items.every(
      (item) => item.privilege_access
    );
    newPrevivlages[ParentIndex].privilege_items.forEach(
      (item) => (item.privilege_access = !allChecked)
    );
    setPrevivlages(newPrevivlages);
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    }
  };

  const handleCheckboxChange = (ParentIndex, childIndex) => {
    const newPrevivlages = [...Previvlages];
    newPrevivlages[ParentIndex].privilege_items[childIndex].privilege_access =
      !newPrevivlages[ParentIndex].privilege_items[childIndex].privilege_access;
    setPrevivlages(newPrevivlages);
    const allChecked = newPrevivlages[ParentIndex].privilege_items.every(
      (item) => item.privilege_access
    );
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    }
  };
  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.add_employee) {
      navigate(`/employee`);
    }
  }, []);

  //===================================================================

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Employee</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data">
          <div className="row table-form">
            <div className="col-12">
              <Divider
                sx={{
                  width: "100%",
                  borderColor: "black",
                  color: "#068ce5",
                  mt: 0,
                }}>
                <h5>Personal Info</h5>
              </Divider>
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="First Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="first_name"
                value={inputs?.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_2"
                label="Last Name"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="last_name"
                value={inputs?.last_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  required
                  label="Status"
                  onChange={handleChange}>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_3"
                label="Email"
                type="email"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="email"
                value={inputs?.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_4"
                label="Password"
                type="password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="password"
                value={inputs?.password}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_5"
                label="Address"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="address"
                value={inputs?.address}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_7"
                label="Contact Number"
                variant="outlined"
                sx={{ width: "100%" }}
                type="number"
                required
                name="contact_number"
                value={inputs?.contact_number}
                onChange={handleChange}
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-5">
                  <p className="">Profile Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  <img src={previews} height={50} />
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      id="contained-button-file"
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span">
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12">
              <Divider
                sx={{
                  width: "100%",
                  borderColor: "black",
                  color: "#068ce5",
                }}>
                <h5>Privileges</h5>
              </Divider>
            </div>

            <div className="col-12">
              <div className="row">
                {Previvlages.map((prevaliges_item, ParentIndex) => {
                  return (
                    <div className="col-4" key={ParentIndex}>
                      <Accordion
                        className="accordian"
                        sx={{
                          boxShadow: "none",
                          borderBottom: "1px solid rgb(229, 229, 229)",
                        }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                          <Typography>
                            {prevaliges_item.privilege_item_lable}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className=" text-end">
                            <Button
                              variant="text"
                              onClick={() => handleSelectAll(ParentIndex)}>
                              {buttonText[ParentIndex]}
                            </Button>
                          </div>
                          <FormGroup>
                            {prevaliges_item.privilege_items?.map(
                              (child, childIndex) => {
                                return (
                                  <FormControlLabel
                                    key={childIndex}
                                    control={
                                      <Checkbox
                                        name={child.privilege_label_for}
                                        checked={child.privilege_access}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            ParentIndex,
                                            childIndex
                                          )
                                        }
                                      />
                                    }
                                    label={child.privilege_label_text}
                                  />
                                );
                              }
                            )}
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddEmployees;
