import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Card,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  _detail_website_faqs,
  _edit_website_faqs,
} from "../../DAL/website_faqs/website_faqs";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";

export default function EditWebsiteFaqs() {
  const { userInfo, privilegeAccess } = useContentSetting();
  const navigate = useNavigate();
  const param = useParams();
  const _ID = param.id;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [inputs, setInputs] = useState({
    question_statement: "",
    answer_statement: "",
    status: true,
    order: 0,
  });
  const handleBack = () => {
    navigate(`/website-faqs`);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const objectData = {
      question_statement: inputs.question_statement,
      answer_statement: inputs.answer_statement,
      status: inputs.status,
      order: inputs.order,
    };
    const result = await _edit_website_faqs(_ID, objectData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/website-faqs`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const get_detail_faqs = async () => {
    const response = await _detail_website_faqs(_ID);
    if (response.code == 200) {
      setInputs(response.faq);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.edit_website_faqs) {
      navigate(`/website-faqs`);
    } else {
      get_detail_faqs();
    }
  }, []);
  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Edit Website FAQ</h3>
        </div>
      </div>
      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 2,
        }}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data">
          <div className="row table-form pt-2 pb-2">
            <div className="col-md-6 col-xs-12 ">
              <TextField
                id="outlined-basic"
                label="Question Statement"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="question_statement"
                onChange={handleChange}
                value={inputs.question_statement}
              />
            </div>
            <div className="col-md-6 col-xs-12 ">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="order"
                onChange={handleChange}
                value={inputs.order}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  FAQ Status*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  defaultValue="true"
                  value={inputs.status}
                  required
                  label="FAQ Status"
                  onChange={handleChange}>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                id="outlined-multiline-static"
                required
                label="Answer Statement"
                multiline
                rows={5}
                name="answer_statement"
                onChange={(e) => handleChange(e)}
                value={inputs.answer_statement}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}
