import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Icon } from "@iconify/react";
import { Box, Typography, IconButton, Chip } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";
import { useState } from "react";
import { useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect } from "react";
export default function CustomPopover({
  isOpenPop,
  isClosePop,
  title,
  status,
  componentToPassDown,
}) {
  const contentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chipColor, setChipColor] = useState();

  const handleClose = () => {
    isClosePop(false);
  };

  const handleDownloadPDF = () => {
    setIsLoading(true);
    const contentElement = contentRef.current;

    // Capture the content element as an image using html2canvas
    html2canvas(contentElement, { scale: 3 })
      .then((canvas) => {
        // Create a new jsPDF instance
        const pdf = new jsPDF("p", "mm", "a4");

        // Calculate the aspect ratio of the content element
        const contentWidth = contentElement.offsetWidth;
        const contentHeight = contentElement.offsetHeight;
        const aspectRatio = contentWidth / contentHeight;

        // Set the PDF dimensions based on the aspect ratio
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdfWidth / aspectRatio;

        // Convert the canvas image to a data URL
        const imageData = canvas.toDataURL("image/png");

        // Add the image to the PDF document
        pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);

        // Save the PDF file
        pdf.save("orderDetail.pdf");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    switch (status) {
      case "pending":
        setChipColor("#ff9800");
        break;
      case "ready_to_ship":
        setChipColor("#03a9f4");
        break;
      case "shipped":
        setChipColor("#0288d1");
        break;
      case "delivered":
        setChipColor("#4caf50");
        break;
      case "cancelled":
        setChipColor("#cccccc");
        break;
      case "returned":
        setChipColor("#ef5350");
        break;
      default:
        setChipColor("#ff9800");
        break;
    }
  }, [status]);
  return (
    <Dialog
      maxWidth="md"
      sx={{ ml: 25 }}
      open={isOpenPop}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box ref={contentRef}>
        <div className="change-password-modal mt-2 px-4 pt-3">
          <div>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {title}
            </Typography>
          </div>
          <div data-html2canvas-ignore="true">
            <IconButton onClick={handleClose} className="icon-button">
              <Icon icon={closeFill} width={20} height={20} />
            </IconButton>
          </div>
        </div>
        <div className="px-4 d-flex">
          <div>
            <span
              className="badge badge-style download-button"
              style={{
                backgroundColor: chipColor,
              }}
            >
              {status}
            </span>
          </div>
          <div data-html2canvas-ignore="true">
            <button
              className="badge badge-style download-button"
              style={{
                marginLeft: "10px",
                backgroundColor: "rgb(25, 97, 222)",
              }}
              onClick={handleDownloadPDF}
              disabled={isLoading}
            >
              <Icon
                icon="ic:baseline-sim-card-download"
                style={{ width: 20, height: 13, marginRight: 3 }}
              />
              {isLoading ? "Downloading..." : "Generate PDF"}
            </button>
          </div>
        </div>

        <div>{componentToPassDown}</div>
      </Box>
    </Dialog>
  );
}
