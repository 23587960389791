import React, { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { _add_product_API } from "../../../DAL/product/product";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Input,
  FormHelperText,
  TextareaAutosize,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { _list_category } from "../../../DAL/category/category";
function AddManageWebsite() {
  const param = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = React.useState({
    status: "false",
    page_title: "",
    meta_title: "",
    meta_keyword: "",
    choose_template: "",
    redirect_user: "",
    facebook: "",
    pinterest: "",
    instagram: "",
    youtube: "",
    snapchat: "",
    twitter: "",
    linkedin: "",
    tiktok: "",
    mailbox: "",
    Logo_image: "",
    favicon_image: "",
    footer_logo_image: "",
    meta_description: "",
    google_tracking_code_header: "",
    google_tracking_code_body: "",
    facebook_tracking_code_header: "",
    facebook_tracking_code_body: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  //===================================================================

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h3 className="my-3">Add Page</h3>
        </div>
      </div>

      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        enctype="multipart/form-data"
      >
        <div className="row">
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Page Title"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="page_title"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Meta Title"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="meta_title"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Meta Keyword"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="meta_keyword"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <FormControl fullWidth sx={{ mb: 0 }}>
              <InputLabel id="demo-simple-select-label">
                Choose Template *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="choose_template"
                required
                label="Choose Template"
                onChange={handleChange}
              >
                <MenuItem value="home">Home</MenuItem>
                <MenuItem value="about">About</MenuItem>
                <MenuItem value="contact">Contact</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-12 mb-4">
            <FormControl fullWidth sx={{ mb: 0 }}>
              <InputLabel id="demo-simple-select-label">
                Use general brand logo, footer logo and favicon? *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="use_general_brand_footer_favicon"
                defaultValue="false"
                value={inputs.status}
                required
                label="Use general brand logo, footer logo and favicon?*"
                onChange={handleChange}
              >
                {/* <MenuItem value={true}>Yes</MenuItem> */}
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <FormControl fullWidth sx={{ mb: 0 }}>
              <InputLabel id="demo-simple-select-label">
                Use general Social Media Links? *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="use_general_brand_footer_favicon"
                defaultValue="false"
                value={inputs.status}
                required
                label="Use general Social Media Links?*"
                onChange={handleChange}
              >
                {/* <MenuItem value={true}>Yes</MenuItem> */}
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-12 mb-4">
            <FormControl fullWidth sx={{ mb: 0 }}>
              <InputLabel id="demo-simple-select-label">
                Redirect User
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="redirect_user"
                label="Redirect User"
                onChange={handleChange}
              >
                <MenuItem value="thankyou">Thanks Page</MenuItem>
                <MenuItem value="dashboard">Dashboard</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Facebook Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="facebook"
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Social Links */}
        <div className="row">
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Pinterest Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="pinterest"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Instagram Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="instagram"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Youtube Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="youtube"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="SnapChat Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="snapchat"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Twitter Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="twitter"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Linkedin Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="linkedin"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Tiktok Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="tiktok"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-xs-12 mb-4">
            <TextField
              id="outlined-basic"
              label="Mailbox Link"
              variant="outlined"
              sx={{ width: "100%" }}
              name="mailbox"
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Brand Logo */}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4 employe-profile">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Brand Logo</p>
              <FormHelperText className="pt-0">
                Recommended Size(200 X 100) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-3">
              <div className="col-3">
                {inputs.plan_image &&
                  {
                    // <img src={s3baseUrl + inputs.plan_image} height="70" />
                  }}
              </div>
            </div>
            <div className="col-4 text-end pt-2">
              <label htmlFor="contained-button-file div-style">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  sx={{ display: "none" }}
                  name="Logo_image"
                  // onChange={fileChangedHandler}
                />
                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        {/* Brand Favicon */}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4 employe-profile">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Brand Favicon</p>
              <FormHelperText className="pt-0">
                Recommended Size(32 X 32) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-3">
              <div className="col-3">
                {inputs.plan_image &&
                  {
                    // <img src={s3baseUrl + inputs.plan_image} height="70" />
                  }}
              </div>
            </div>
            <div className="col-4 text-end pt-2">
              <label htmlFor="contained-button-file div-style">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  sx={{ display: "none" }}
                  name="favicon_image"
                  // onChange={fileChangedHandler}
                />
                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        {/* Footer Logo */}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4 employe-profile">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Footer Logo</p>
              <FormHelperText className="pt-0">
                Recommended Size(200 X 100) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-3">
              <div className="col-3">
                {inputs.plan_image &&
                  {
                    // <img src={s3baseUrl + inputs.plan_image} height="70" />
                  }}
              </div>
            </div>
            <div className="col-4 text-end pt-2">
              <label htmlFor="contained-button-file div-style">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  sx={{ display: "none" }}
                  name="footer_logo_image"
                  // onChange={fileChangedHandler}
                />
                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>

        <div className="col-12 mt-5 mb-2">
          <TextareaAutosize
            aria-label="empty textarea"
            name="meta_description"
            onChange={(e) => handleChange(e)}
            inputs={inputs}
            placeholder="Meta Description"
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "transparent",
              color: "black",
              borderRadius: "10px",
              paddingTop: "8px",
              paddingLeft: "5px",
              focus: "green",
            }}
          />
        </div>
        <div className="col-12 mt-4">
          <h4 style={{ fontSize: "0.97rem", paddingLeft: "0.4rem" }}>
            Facebook Tracking Code Header
          </h4>
          <TextareaAutosize
            aria-label="empty textarea"
            value={inputs.facebook_tracking_code_header}
            name="facebook_tracking_code_header"
            onChange={handleChange}
            placeholder="Facebook Tracking Code Header"
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "transparent",
              color: "black",
              borderRadius: "10px",
              paddingTop: "8px",
              paddingLeft: "5px",
              focus: "green",
            }}
          />
        </div>
        <div className="col-12 mt-4">
          <h4 style={{ fontSize: "0.97rem", paddingLeft: "0.4rem" }}>
            Facebook Tracking Code Body{" "}
          </h4>
          <TextareaAutosize
            aria-label="empty textarea"
            value={inputs.facebook_tracking_code_body}
            name="facebook_tracking_code_body"
            onChange={handleChange}
            placeholder="Facebook Tracking Code Body"
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "transparent",
              color: "black",
              borderRadius: "10px",
              paddingTop: "8px",
              paddingLeft: "5px",
              focus: "green",
            }}
          />
        </div>
        <div className="col-12 mt-4">
          <h4 style={{ fontSize: "0.97rem", paddingLeft: "0.4rem" }}>
            Google Tracking Code Header
          </h4>
          <TextareaAutosize
            aria-label="empty textarea"
            value={inputs.google_tracking_code_header}
            name="google_tracking_code_header"
            onChange={handleChange}
            placeholder="Google Tracking Code Header"
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "transparent",
              color: "black",
              borderRadius: "10px",
              paddingTop: "8px",
              paddingLeft: "5px",
              focus: "green",
            }}
          />
        </div>

        <div className="col-12 mt-4">
          <h4 style={{ fontSize: "0.97rem", paddingLeft: "0.4rem" }}>
            Google Tracking Code Body
          </h4>
          <TextareaAutosize
            aria-label="empty textarea"
            value={inputs.google_tracking_code_body}
            name="google_tracking_code_body"
            onChange={handleChange}
            placeholder="Google Tracking Code Body"
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "transparent",
              color: "black",
              borderRadius: "10px",
              paddingTop: "8px",
              paddingLeft: "5px",
              focus: "green",
            }}
          />
        </div>

        <div className="col-12 mt-3 text-end">
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddManageWebsite;
