import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { invokeApi } from "../../../src/bl_libs/invokeApi";
import { s3baseUrl } from "../../../src/config/config";
import ReactTextEditorKit from "./ReactTextEditorKit";

export default function GeneralCkeditor({
  inputs,
  setInputs,
  name,
  editorHeight,
  is_array,
  index,
  value,
}) {
  const editorRef = useRef(null);

  const log = () => {
    if (editorRef.current) {
      if (is_array) {
        const list = [...inputs];
        list[index][name] = editorRef.current.getContent();
        setInputs(list);
      } else if (inputs) {
        setInputs((prevState) => ({
          ...prevState,
          [name]: editorRef.current.getContent(),
        }));
      } else {
        setInputs(editorRef.current.getContent());
      }
    }
  };

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let requestObj = {
      path: "api/app_api/upload_image",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    };

    let _formData = new FormData();
    _formData.append("image", blobInfo.blob());
    _formData.append("width", "600");
    requestObj["postData"] = _formData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        success(s3baseUrl + res.path);
      } else {
        failure(res.message);
      }
    });
  }

  const editor_value = () => {
    let value_data = "";
    if (is_array && inputs[index][name]) {
      value_data = inputs[index][name];
    } else if (inputs[name]) {
      value_data = inputs[name];
    } else if (value) {
      value_data = value;
    }
    return value_data;
  };

  const handleChange = (value) => {
    if (is_array) {
      const list = [...inputs];
      list[index][name] = value;
      setInputs(list);
    } else if (inputs) {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputs(value);
    }
  };

  return (
    <>
      <ReactTextEditorKit
        onChange={handleChange}
        value={editor_value()}
        get_editor_ref={(ref) => (editorRef.current = ref)}
      />
      {/* <Editor
        apiKey="o9aroywf8dnp926v37lwgmaw2pwspgsdx6yj4jq07dmi5ff0"
        onChange={log}
        value={editor_value()}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={(newValue, editor) => {
          log(newValue, editor);
        }}
        init={{
          images_upload_handler: example_image_upload_handler,
          height: editorHeight ? editorHeight : 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      /> */}
    </>
  );
}
