import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { _edit_shipment, _detail_shipment } from "../../DAL/Shipment/shipment";
import { _Vendor_listApi } from "../../DAL/Vender/vender";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import Autocomplete from "@mui/material/Autocomplete";

function EditShipment({ onCloseDrawer, data, apiFunction }) {
  const param = useParams();
  const _ID = param.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [vendorData, setVendorData] = useState();
  const { userInfo, privilegeAccess } = useContentSetting();
  const [selectedVendor, setSelectedVendor] = useState(null);

  const [inputs, setInputs] = useState({
    shipment_title: "",
    vendor_info: "",
    shipment_type: "",
    shippment_date: dayjs(new Date()),
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDateChange = (date) => {
    setInputs((values) => ({ ...values, shippment_date: dayjs(date.$d) }));
  };

  const get_vendor_list = async () => {
    const response = await _Vendor_listApi();
    if (response.code === 200) {
      setVendorData(response.vendor);
      setIsLoading(false);
      setIsLoadingDrawer(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handle_format_data = (data) => {
    get_vendor_list();
    let vendor_id = "";
    if (data.vendor_info?._id) {
      vendor_id = data.vendor_info?._id;
    }
    setInputs({
      ...data,
      shippment_date: dayjs(
        moment(data.shippment_date, "DD-MM-YYYY").format("MM-DD-YYYY")
      ),
      vendor_info: vendor_id,
    });
    setSelectedVendor(data.vendor_info);
  };

  const get_detail_shipment = async () => {
    const response = await _detail_shipment(_ID);
    if (response.code === 200) {
      handle_format_data(response.Shipment);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const objectData = {
      vendor_info: selectedVendor._id,
      shipment_type: inputs.shipment_type,
      shipment_title: inputs.shipment_title,
      shippment_date: moment(inputs.shippment_date.$d).format("DD-MM-YYYY"),
    };

    const result = await _edit_shipment(objectData, inputs._id);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      onCloseDrawer();
      apiFunction();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo.type !== 0 && !privilegeAccess.edit_shipment) {
      navigate(`/shipment`);
    }
    if (!data) {
      get_detail_shipment();
    } else {
      handle_format_data(data);
      // window.history.replaceState({}, document.title);
    }
  }, []);

  //===================================================================
  if (isLoadingDrawer) {
    return <CircularProgress className="centerd" color="primary" />;
  }
  return (
    <>
      <div className="container">
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="col-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Shipment Title"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="shipment_title"
                value={inputs.shipment_title}
                onChange={handleChange}
              />
            </div>
            <div className="col-12">
              <FormControl fullWidth>
                <Autocomplete
                  id="vendor-autocomplete"
                  options={vendorData}
                  getOptionLabel={(option) =>
                    option.first_name + " " + option.last_name
                  }
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                  value={selectedVendor}
                  onChange={(event, newValue) => {
                    setSelectedVendor(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendor*"
                      variant="outlined"
                      sx={{ mb: 2, mt: 2 }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Shipment Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="shipment_type"
                  defaultValue="true"
                  value={inputs.shipment_type}
                  required
                  label="Shipment Type"
                  onChange={handleChange}
                >
                  <MenuItem value="local">Local </MenuItem>
                  <MenuItem value="international">International</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Shipment Date"
                  format="DD-MM-YYYY"
                  className="form-control"
                  name="shippment_date"
                  value={inputs.shippment_date}
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </div>

            <div className="col-12 mt-3 text-end">
              <button
                className="small-contained-button"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditShipment;
