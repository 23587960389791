import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_contact_settings = async (data) => {
  const requestObj = {
    path: `api/website_settings/get_contact_settings_admin`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _add_contact_settings = async (data) => {
  const requestObj = {
    path: `api/website_settings/add_contact_settings_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
