import React, { useState, useEffect } from "react";
import { FormControl, TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { _add_customer, _update_customer } from "../../DAL/Customers/Customers";

export default function AddOrUpdateCustomer({
  onCloseDrawer,
  formType,
  apiFunction,
  setCustomerList,
  CustomerList,
  selectedRow,
}) {
  const [previews, setPreviews] = useState("");
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    address: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };
  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("email", inputs.email);
    formData.append("contact_number", inputs.contact_number);
    formData.append("address", inputs.address);

    if (image) {
      formData.append("profile_image", image);
    }
    if (formType == "ADD") {
      formData.append("password", inputs.password);
    }

    setIsLoading(true);
    const response =
      formType === "ADD"
        ? await _add_customer(formData)
        : await _update_customer(inputs._id, formData);
    if (response.code === 200) {
      let response_object = response.customer;
      response_object.customer_image = {
        src: s3baseUrl + response.customer.profile_image,
        alt: response.customer.first_name,
      };
      if (formType === "ADD") {
        setCustomerList((prev) => [response_object, ...prev]);
      } else {
        const newState = CustomerList.map((obj) => {
          if (obj._id === inputs._id) {
            return response_object;
          }
          return obj;
        });
        setCustomerList(newState);
      }
      apiFunction();
      onCloseDrawer();

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (formType == "EDIT") {
      setInputs(selectedRow);
      if (selectedRow.profile_image != "") {
        setPreviews(s3baseUrl + selectedRow.profile_image);
      }
    }
  }, [formType]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="first_name"
              value={inputs?.first_name}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="outlined-basic_1"
              label="Last Name"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="last_name"
              value={inputs?.last_name}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="outlined-basic_2"
              label="Email"
              variant="outlined"
              type="email"
              sx={{ width: "100%" }}
              required
              name="email"
              value={inputs?.email}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        {formType == "ADD" && (
          <div className="col-12">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic_3"
                label="Password"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                type="password"
                name="password"
                value={inputs?.password}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        )}
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="Contact No."
              id="outlined-basic_4"
              type="number"
              variant="outlined"
              sx={{ width: "100%" }}
              name="contact_number"
              value={inputs?.contact_number}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="outlined-basic-5"
              label="Address"
              variant="outlined"
              sx={{ width: "100%" }}
              // required
              name="address"
              value={inputs?.address}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <p className="mt-2 mb-0">Upload Image (1000x670)</p>
        <div className="col-md-12 mt-2 d-flex">
          {previews && (
            <span className="preview mt-2">
              <span onClick={handleRemove}>x</span>
              <img src={previews} />
            </span>
          )}
          {previews == "" && (
            <span className="upload-button mt-2">
              <input
                color="primary"
                accept="image/*"
                type="file"
                id="icon-button-file"
                style={{ display: "none" }}
                name="profile_image"
                onChange={handleUpload}
              />
              <label htmlFor="icon-button-file">
                <CloudUploadIcon />
              </label>
            </span>
          )}
        </div>

        <div className="text-end mt-3 mb-3">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
              ? "Save"
              : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
