import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  TextField,
  IconButton,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import {
  _add_payment_settings,
  _get_payment_settings,
} from "../../DAL/website_setting/payment_setting";

function PaymentSetting() {
  const { privilegeAccess, userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = React.useState({
    sandbox_application_Id: "",
    sandbox_location_Id: "",
    live_application_Id: "",
    live_location_Id: "",
    sandbox_access_token: "",
    live_access_token: "",
    payment_mode: "sandbox",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_contact_settings_list = async () => {
    // setIsLoading(false);
    setIsLoading(true);
    const result = await _get_payment_settings();
    if (result.code === 200) {
      setInputs(result.payment_settings);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_contact_settings_list();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_payment_setting === true ||
        privilegeAccess.edit_payment_setting === true
      ) {
        setIsButtonLoading(true);
        const result = await _add_payment_settings({
          payment_settings: inputs,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      setIsButtonLoading(true);
      const result = await _add_payment_settings({
        payment_settings: inputs,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-12">
          <h3 className="my-3">Payment Setting</h3>
        </div>
      </div>
      {userInfo.type == 2 && !privilegeAccess.view_payment_setting ? (
        <NoAccessData />
      ) : (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data">
          {/*  Payment  */}
          <div className="row ">
            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Sandbox Application ID"
                variant="outlined"
                sx={{ width: "100%" }}
                name="sandbox_application_Id"
                onChange={handleChange}
                value={inputs?.sandbox_application_Id}
              />
            </div>

            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Sandbox Location ID"
                variant="outlined"
                sx={{ width: "100%" }}
                name="sandbox_location_Id"
                onChange={handleChange}
                value={inputs?.sandbox_location_Id}
              />
            </div>

            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Live Application ID"
                variant="outlined"
                sx={{ width: "100%" }}
                name="live_application_Id"
                onChange={handleChange}
                value={inputs?.live_application_Id}
              />
            </div>

            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Live Location ID"
                variant="outlined"
                sx={{ width: "100%" }}
                name="live_location_Id"
                onChange={handleChange}
                value={inputs?.live_location_Id}
              />
            </div>
            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Sandbox Access Token"
                variant="outlined"
                sx={{ width: "100%" }}
                name="sandbox_access_token"
                onChange={handleChange}
                value={inputs?.sandbox_access_token}
              />
            </div>
            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Live Access Token"
                variant="outlined"
                sx={{ width: "100%" }}
                name="live_access_token"
                onChange={handleChange}
                value={inputs?.live_access_token}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Payment Mode
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="payment_mode"
                  defaultValue="sandbox"
                  value={inputs.payment_mode}
                  required
                  label="Payment Mode"
                  onChange={handleChange}>
                  <MenuItem value="sandbox">Sandbox</MenuItem>
                  <MenuItem value="live">Live</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-12 mt-3 text-end">
            <Tooltip
              title={
                userInfo.type == 2 &&
                (privilegeAccess.edit_payment_setting ||
                  privilegeAccess.add_payment_setting) === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              {isButtonLoading ? (
                <LoadingButton loading variant="contained">
                  Submit
                </LoadingButton>
              ) : (
                <Button
                  // style={{ textTransform: 'capitalize' }}
                  variant="contained"
                  type="submit">
                  Submit
                </Button>
              )}
            </Tooltip>
          </div>
        </form>
      )}
    </div>
  );
}

export default PaymentSetting;
