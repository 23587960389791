import { invokeApi } from "../../../bl_libs/invokeApi";

export const _add_shipment_received = async (data) => {
  const requestObj = {
    path: `api/shipment_received/add_shipment_received`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_shipment_received = async (postData, shipment_id) => {
  const requestObj = {
    path: `api/shipment_received/edit_shipment_received/${shipment_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _detail_shipment = async (id) => {
  const requestObj = {
    path: `api/shipment/detail_shipment/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
