import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import {
  Button,
  FormHelperText,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { s3baseUrl } from "../../config/config";
import { _general_Image_upload } from "../../DAL/general_image_upload/general_Image_upload";
import {
  _get_about_us_settings_admin,
  _add_about_us_settings_admin,
  _add_image,
  _del_image,
} from "../../DAL/website_setting/about_us_setting";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function AboutUsSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { userInfo, privilegeAccess } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    page_heading: "",
    top_description: "",
    middle_description: "",
    bottom_description: "",
  });
  const [image, setImage] = useState([]);
  const [paths, setPaths] = useState([]);
  const [image2, setImage2] = useState([]);
  const [paths2, setPaths2] = useState([]);
  const [showImages, setShowImages] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const get_about_us_setting_admin_list = async () => {
    setIsLoading(true);

    const result = await _get_about_us_settings_admin();
    if (result.code === 200) {
      setInputs(result.about_us_settings);
      if (result.about_us_settings.images) {
        setPaths(result.about_us_settings.images);
        setImage(result.about_us_settings.images);

        if (result.about_us_settings.images.length > 1) {
          setShowImages(true);
        }
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_about_us_setting === true ||
        privilegeAccess.edit_about_us_setting === true
      ) {
        const merged = [...paths, ...paths2];
        const objectData = {
          ...inputs,
          images: merged,
        };
        setIsButtonLoading(true);
        const result = await _add_about_us_settings_admin({
          about_us_settings: objectData,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      const merged = [...paths, ...paths2];
      const objectData = {
        ...inputs,
        images: merged,
      };
      setIsButtonLoading(true);
      const result = await _add_about_us_settings_admin({
        about_us_settings: objectData,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };
  const uploadMaterial = async (image) => {
    const formData = new FormData();
    formData.append("image", image);
    const result = await _add_image(formData);
    if (result.code === 200) {
      return result.path;
    } else {
      console.log(result.message, "error");
    }
  };

  const handleMaterial = async (e) => {
    const images = Array.from(e.target.files);
    setImage2((prevImages) => [...prevImages, ...images]);
    setShowImages(true);

    const results = [];
    for (let i = 0; i < images.length; i++) {
      const result = await uploadMaterial(images[i]);
      results.push(result);
    }
    setPaths2((prevMaterials) => [...prevMaterials, ...results]);
  };

  const handleDeleteMaterial = (index) => {
    delMaterial(paths[index]);
    const newFile = [...image];
    newFile.splice(index, 1);
    setImage(newFile);
    const newMaterail = [...paths];
    newMaterail.splice(index, 1);
    setPaths(newMaterail);
    if (newFile.length === 0 && image2.length === 0) {
      setShowImages(false);
    }
  };
  const delMaterial = async (img) => {
    const data = new FormData();
    data.append("image_path", img);
    const result = await _del_image(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  const handleDeleteMaterial2 = (index) => {
    delMaterial2(paths2[index]);
    const newFile = [...image2];
    newFile.splice(index, 1);
    setImage2(newFile);
    const newMaterail = [...paths2];
    newMaterail.splice(index, 1);
    setPaths2(newMaterail);
    if (newFile.length === 0 && image.length == 0) {
      setShowImages(false);
    }
  };
  const delMaterial2 = async (img) => {
    const data = new FormData();
    data.append("image_path", img);
    const result = await _del_image(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  useEffect(() => {
    get_about_us_setting_admin_list();
  }, []);
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  //===================================================================

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="my-3">About Us Setting</h3>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess.view_about_us_setting ? (
          <NoAccessData />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data">
            <div className="row">
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  required
                  label="Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="page_heading"
                  onChange={handleChange}
                  value={inputs?.page_heading}
                />
              </div>
            </div>
            {/*  Top description*/}
            <div className="row">
              <div className="col-12 mb-4">
                <p className="home_page_header">Top Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="top_description"
                  editorHeight={350}
                />
              </div>
            </div>
            <div className="row">
              {/* Banner 1 image*/}
              {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="home_page_header">About Us Images</p>
                    <FormHelperText className="pt-0">
                      Recommended Size(200 X 200) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-7">
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={3.5}>
                      <span className="upload-button mx-4">
                        <input
                          color="primary"
                          accept="image/*"
                          type="file"
                          id="paths"
                          style={{ display: "none" }}
                          name="paths"
                          onChange={handleMaterial}
                          inputprops={{ multiple: true }}
                        />
                        <label htmlFor="paths" className="mt-3">
                          <CloudUploadIcon />
                        </label>
                      </span>
                    </Stack>
                  </div>
                </div>
                {showImages === true ? (
                  <Stack direction="row" spacing={3.5} className="mt-3">
                    <Stack direction="row" spacing={3.5}>
                      <>
                        {image.map((img, index) => (
                          <Stack
                            className="delete-icon"
                            key={index}
                            position="relative"
                            alignItems="center"
                            justifyContent="center">
                            <img
                              alt={`image-${index}`}
                              src={s3baseUrl + img}
                              width="150"
                              style={{ maxWidth: "100%" }}
                            />

                            <span
                              aria-label="delete"
                              onClick={() => handleDeleteMaterial(index)}
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                backgroundColor: "red",
                                fontSize: "13px",
                                fontWeight: "bold",
                                height: "20px,",
                                width: "20px",
                                color: "#fff",
                                textAlign: "center",
                                borderRadius: "10px",
                              }}>
                              x
                            </span>
                          </Stack>
                        ))}
                      </>
                    </Stack>
                    <Stack direction="row" spacing={3.5}>
                      <>
                        {image2.map((img, index) => (
                          <Stack
                            className="delete-icon"
                            key={index}
                            position="relative"
                            alignItems="center"
                            justifyContent="center">
                            <img
                              alt={`image-${index}`}
                              src={URL.createObjectURL(img)}
                              width="150"
                              style={{ maxWidth: "100%" }}
                            />

                            <span
                              aria-label="delete"
                              onClick={() => handleDeleteMaterial2(index)}
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                backgroundColor: "red",
                                fontSize: "13px",
                                fontWeight: "bold",
                                height: "20px,",
                                width: "20px",
                                color: "#fff",
                                textAlign: "center",
                                borderRadius: "10px",
                              }}>
                              x
                            </span>
                          </Stack>
                        ))}
                      </>
                    </Stack>
                  </Stack>
                ) : null}
              </div> */}

              {/*  Middle  description*/}
              <div className="col-12 mb-4">
                <p className="home_page_header">Middle Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="middle_description"
                  editorHeight={350}
                />
              </div>
              {/* Bottom description*/}
              <div className="col-12 mb-4">
                <p className="home_page_header">Bottom Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="bottom_description"
                  editorHeight={350}
                />
              </div>
            </div>

            <div className="col-12 mt-3 text-end">
              <Tooltip
                title={
                  userInfo.type == 2 &&
                  (privilegeAccess.edit_stock_setting ||
                    privilegeAccess.add_stock_setting) === false
                    ? "You have no access to perform this action"
                    : ""
                }
                placement="right-end"
                arrow>
                {isButtonLoading ? (
                  <LoadingButton loading variant="contained">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    // style={{ textTransform: 'capitalize' }}
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                )}
              </Tooltip>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default AboutUsSetting;
