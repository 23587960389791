import React, { useState } from "react";
import PinField from "react-pin-field";
// material
import { Stack } from "@mui/material";
import { Button, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function PincodeVerification({
  onhandlePinCodeSubmit,
  setIsLoading,
}) {
  const [pinCode, setPinCode] = useState("");

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (pinCode.length > 5) {
      onhandlePinCodeSubmit(pinCode);
    } else {
      alert("All fields should be filled");
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <form noValidate onSubmit={handleSubmit}>
          <div className="col-12">
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              display="flex"
              justifyContent="space-between">
              <PinField
                onChange={setPinCode}
                type="numeric"
                inputMode="number"
                validate={/^[0-9]$/}
                style={{
                  caretColor: "transparent",
                  width: 50,
                  height: 50,
                  outline: "none",
                  textAlign: "center",
                  borderColor: "#198BA8",
                }}
                length={6}
              />
            </Stack>
          </div>

          <div className=" col-12 text-end">
            <Stack>
              <Typography variant="body2" sx={{ mt: 3 }}>
                Back to Login?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="/login">
                  Login
                </Link>
              </Typography>
            </Stack>
          </div>

          <div className="col-12">
            <Button fullWidth size="large" type="submit" variant="contained">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
