import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Button, TextField, CircularProgress, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  _add_social_settings_admin,
  _get_social_setting_admin,
} from "../../DAL/website_setting/social_settings";
import {
  _add_contact_settings,
  _get_contact_settings,
} from "../../DAL/website_setting/contact_us_setting";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

function ContactUsSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const navigate = useNavigate();
  const { privilegeAccess, userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    page_heading: "",
    contact_location: "",
    contact_number: "",
    contact_email: "",
    contact_hours_heading: "",
    contact_hours_text: "",
    drop_us_a_line_heading: "",
    drop_us_a_line_text: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_contact_settings_list = async () => {
    setIsLoading(true);
    const result = await _get_contact_settings();
    if (result.code === 200) {
      setInputs(result.contact_us_settings);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_contact_settings_list();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_contact_us_setting === true ||
        privilegeAccess.edit_contact_us_setting === true
      ) {
        setIsButtonLoading(true);
        const result = await _add_contact_settings({
          contact_us_settings: inputs,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      setIsButtonLoading(true);
      const result = await _add_contact_settings({
        contact_us_settings: inputs,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-12">
          <h3 className="my-3">Contact Us Setting</h3>
        </div>
      </div>
      {userInfo.type == 2 && !privilegeAccess.view_contact_us_setting ? (
        <NoAccessData />
      ) : (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data">
          {/*  Contact Us  */}
          <div className="row ">
            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Page Heading"
                variant="outlined"
                sx={{ width: "100%" }}
                name="page_heading"
                onChange={handleChange}
                required
                value={inputs?.page_heading}
              />
            </div>
            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Location"
                variant="outlined"
                sx={{ width: "100%" }}
                name="contact_location"
                onChange={handleChange}
                value={inputs?.contact_location}
              />
            </div>

            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Number"
                type="number"
                variant="outlined"
                sx={{ width: "100%" }}
                name="contact_number"
                onChange={handleChange}
                value={inputs?.contact_number}
                inputProps={{ min: 0 }}
              />
            </div>
            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Email"
                type="email"
                variant="outlined"
                sx={{ width: "100%" }}
                name="contact_email"
                onChange={handleChange}
                value={inputs?.contact_email}
              />
            </div>

            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Hours Heading  "
                variant="outlined"
                sx={{ width: "100%" }}
                name="contact_hours_heading"
                onChange={handleChange}
                value={inputs?.contact_hours_heading}
              />
            </div>
            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Hours Text"
                variant="outlined"
                sx={{ width: "100%" }}
                name="contact_hours_text"
                onChange={handleChange}
                value={inputs?.contact_hours_text}
              />
            </div>

            <div className="col-md-6 col-xs-12 mb-4">
              <TextField
                id="outlined-basic"
                label="Drop Us A Line Heading"
                variant="outlined"
                sx={{ width: "100%" }}
                name="drop_us_a_line_heading"
                onChange={handleChange}
                value={inputs?.drop_us_a_line_heading}
              />
            </div>
            <div className="col-12 mb-4">
              <TextField
                id="outlined-multiline-static"
                label="Drop Us A Line Text"
                multiline
                rows={3}
                name="drop_us_a_line_text"
                onChange={(e) => handleChange(e)}
                inputs={inputs}
                variant="outlined"
                style={{ width: "100%" }}
                value={inputs?.drop_us_a_line_text}
              />
            </div>
          </div>

          <div className="col-12 mt-3 text-end">
            <Tooltip
              title={
                userInfo.type == 2 &&
                (privilegeAccess.edit_contact_us_setting ||
                  privilegeAccess.add_contact_us_setting) === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              {isButtonLoading ? (
                <LoadingButton loading variant="contained">
                  Submit
                </LoadingButton>
              ) : (
                <Button
                  // style={{ textTransform: 'capitalize' }}
                  variant="contained"
                  type="submit">
                  Submit
                </Button>
              )}
            </Tooltip>
          </div>
        </form>
      )}
    </div>
  );
}

export default ContactUsSetting;
