import React, { useEffect, useState } from "react";
// material
import { Box, CircularProgress } from "@mui/material";
import DashboardCard from "../../components/dashboard/DashboardCard";
import { Icon } from "@iconify/react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { _get_dashboard } from "../../DAL/login/login";
import moment from "moment/moment";

const TABLE_HEAD_PRODUCTS = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "common_name", label: "Common Name", alignRight: false },
  { id: "scientific_name", label: "Scientific Name", alignRight: false },
  { id: "product_type", label: "Type", alignRight: false },
];
const TABLE_HEAD_CUSTOMERS = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "date", label: "Created At", alignRight: false },
];
const TABLE_HEAD_CATEGORY = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Main Category", alignRight: false },
  { id: "date", label: "Created At", alignRight: false },
];
const TABLE_HEAD_VENDORS = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "name", label: "Name", alignRight: false },
  { id: "contact_number", label: "Phone", alignRight: false },
  { id: "date", label: "Created At", alignRight: false },
];
const TABLE_HEAD_EMPLOYEES = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "date", label: "Created At", alignRight: false },
];
const TABLE_HEAD_ORDERS = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "name", label: "Customer", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "net_amount", label: "Net Amount", alignRight: false },
  { id: "date", label: "Created At", alignRight: false },
];

const getIcon = (name) => <Icon icon={name} width={25} height={25} />;

export default function Dashboard() {
  const [productData, setProductData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [vendorsData, setVendorsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDashboard = async () => {
    const response = await _get_dashboard();
    const adminDashboardStats = response.admin_dashboard_stats;

    setCards((cards) => [
      {
        ...cards[0],
        counting: adminDashboardStats.employe_count,
      },
      {
        ...cards[1],
        counting: adminDashboardStats.product_count,
      },
      {
        ...cards[2],
        counting: adminDashboardStats.vendor_count,
      },
      {
        ...cards[3],
        counting: adminDashboardStats.category_count,
      },
      {
        ...cards[4],
        counting: adminDashboardStats.customer_count,
      },
      {
        ...cards[5],
        counting: adminDashboardStats.order_count,
      },
      {
        ...cards[6],
        counting: adminDashboardStats.pending_order,
      },
      {
        ...cards[7],
        counting: adminDashboardStats.today_orders_count,
      },
      {
        ...cards[8],
        counting: adminDashboardStats.delivered_order,
      },
      {
        ...cards[9],
        counting: adminDashboardStats.cancelled_order,
      },
    ]);
    let employees = adminDashboardStats.recent_employees.map((item) => {
      return {
        ...item,
        name: item.first_name + " " + item.last_name,
        date: moment(item.createdAt).format("DD-MM-YYYY"),
      };
    });
    let vendors = adminDashboardStats.recent_vendors.map((item) => {
      return {
        ...item,
        name: item.first_name + " " + item.last_name,
        date: moment(item.createdAt).format("DD-MM-YYYY"),
      };
    });
    let category = adminDashboardStats.recent_category.map((item) => {
      return {
        ...item,
        date: moment(item.createdAt).format("DD-MM-YYYY"),
      };
    });
    let customer = adminDashboardStats.recent_customer.map((item) => {
      return {
        ...item,
        name: item.first_name + " " + item.last_name,
        date: moment(item.createdAt).format("DD-MM-YYYY"),
      };
    });
    let order = adminDashboardStats.recent_orders.map((item) => {
      let user_name = "";
      if (item.customer?.first_name) {
        user_name = item.customer?.first_name;
        if (item.customer?.last_name) {
          user_name = user_name + " " + item.customer?.last_name;
        }
      } else {
        user_name = "Guest User";
      }
      return {
        ...item,
        name: user_name,
        net_amount: "$" + item.total_price_after_discount,
        date: moment(item.createdAt).format("DD-MM-YYYY"),
      };
    });
    setProductData(adminDashboardStats.recent_products);
    setEmployeeData(employees);
    setVendorsData(vendors);
    setCategoryData(category);
    setCustomersData(customer);
    setOrdersData(order);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getDashboard();
  }, []);

  const [cards, setCards] = useState([
    {
      icon: getIcon("clarity:employee-group-line"),
      counting: 0,
      title: "Total Employees",
      className: "total-employees",
    },
    {
      icon: getIcon("fluent-mdl2:product-variant"),
      counting: 0,
      title: "Total Products",
      className: "total-products",
    },
    {
      icon: getIcon("fa6-solid:users-rectangle"),
      counting: 0,
      title: "Total Vendors",
      className: "total-vendors",
    },
    {
      icon: getIcon("carbon:category"),
      counting: 0,
      title: "Total Categories",
      className: "total-categories",
    },
    {
      icon: getIcon("pepicons-pop:persons"),
      counting: 0,
      title: "Total Customers",
      className: "total-customers",
    },
    {
      icon: getIcon("icon-park-twotone:transaction-order"),
      counting: 0,
      title: "Total Orders",
      className: "total-orders",
    },
    {
      icon: getIcon("mdi:receipt-text-pending"),
      counting: 0,
      title: "Pending Orders",
      className: "pending-orders",
    },
    {
      icon: getIcon("fluent-mdl2:goto-today"),
      counting: 0,
      title: "Today's Orders",
      className: "today-orders",
    },
    {
      icon: getIcon("mdi:package-variant-closed-delivered"),
      counting: 0,
      title: "Delivered Orders",
      className: "delivered-orders",
    },
    {
      icon: getIcon("mdi:cart-remove"),
      counting: 0,
      title: "Cancelled Orders",
      className: "cancelled-orders",
    },
  ]);
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }
  return (
    <div className="container">
      <Box sx={{ pb: 2, mt: 2 }}>
        <h3>Hi, Welcome back</h3>
      </Box>
      <DashboardCard cards={cards} />
      <div className="row">
        {employeeData.length > 0 ? (
          <div className="col-12 col-md-6 mt-4">
            <h3 className="pb-2">Recent Employees </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_EMPLOYEES}
              data={employeeData}
              hide_search={true}
              hide_pagination={true}
            />
          </div>
        ) : (
          ""
        )}
        {productData.length > 0 ? (
          <div className="col-12 col-md-6 mt-4">
            <h3 className="pb-2">Recent Products </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_PRODUCTS}
              data={productData}
              hide_search={true}
              hide_pagination={true}
            />
          </div>
        ) : (
          ""
        )}
        {vendorsData.length > 0 ? (
          <div className="col-12 col-md-6 mt-4">
            <h3 className="pb-2">Recent Vendors </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_VENDORS}
              data={vendorsData}
              hide_search={true}
              hide_pagination={true}
            />
          </div>
        ) : (
          ""
        )}
        {categoryData.length > 0 ? (
          <div className="col-12 col-md-6 mt-4">
            <h3 className="pb-2">Recent Categories </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_CATEGORY}
              data={categoryData}
              setData={setCategoryData}
              hide_search={true}
              hide_pagination={true}
            />
          </div>
        ) : (
          ""
        )}
        {customersData.length > 0 ? (
          <div className="col-12 col-md-6 mt-4">
            <h3 className="pb-2">Recent Customers </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_CUSTOMERS}
              data={customersData}
              hide_search={true}
              hide_pagination={true}
            />
          </div>
        ) : (
          ""
        )}
        {ordersData.length > 0 ? (
          <div className="col-12 col-md-6 mt-4">
            <h3 className="pb-2">Recent Orders </h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_ORDERS}
              data={ordersData}
              hide_search={true}
              hide_pagination={true}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
