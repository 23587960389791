import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  _add_shipping_price,
  _edit_shipping_price,
} from "../../DAL/shipping_price/shipping_price";

export default function AddOrUpdateShipmentPrices({
  onCloseDrawer,
  formType,
  selectedRow,
  apiFunction,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    abbreviation: "",
    price: "",
    state: "",
    tex_rate: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSelect = (value) => {
    setInputs((values) => ({ ...values, ["state"]: value }));
  };

  const states = [
    "Alaska",
    "Alabama",
    "Arkansas",
    "American Samoa",
    "Arizona",
    "California",
    "Colorado",
    "Connecticut",
    "District of Columbia",
    "Delaware",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Iowa",
    "Idaho",
    "Illinois",
    "Indiana",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Massachusetts",
    "Maryland",
    "Maine",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Mississippi",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "Nevada",
    "New York",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Virginia",
    "Virgin Islands",
    "Vermont",
    "Washington",
    "Wisconsin",
    "West Virginia",
    "Wyoming",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("state_name", inputs.state);
    formData.append("state_abbreviation", inputs.abbreviation);
    formData.append("shipping_price", inputs.price);
    formData.append("tex_rate", inputs.tex_rate);
    setIsLoading(true);
    const response =
      formType === "ADD"
        ? await _add_shipping_price(formData)
        : await _edit_shipping_price(selectedRow._id, formData);
    if (response.code === 200) {
      apiFunction();
      onCloseDrawer();
      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setInputs({
        ...inputs,
        ["price"]: selectedRow.shipping_price,
        ["abbreviation"]: selectedRow.state_abbreviation,
        ["state"]: selectedRow.state_name,
        ["tex_rate"]: selectedRow.tex_rate,
      });
    }
  }, [formType]);
  return (
    <>
      <div className="container">
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row ">
            <div className="col-12">
              <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                <Autocomplete
                  value={inputs.state ? inputs?.state : ""}
                  onChange={(event, newValue) => {
                    handleSelect(newValue);
                  }}
                  inputValue={inputs.state ? inputs?.state : ""}
                  onInputChange={(event, newValue) => {
                    handleSelect(newValue);
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={states}
                  renderInput={(params) => (
                    <TextField {...params} label="States" required />
                  )}
                />
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                id="outlined-basic"
                label="State Abbreviation"
                variant="outlined"
                required
                sx={{ width: "100%", mb: 2 }}
                name="abbreviation"
                onChange={(e) => handleChange(e)}
                value={inputs?.abbreviation}
              />
            </div>
            <div className="col-12">
              <TextField
                label="Shipping Charges"
                id="outlined-start-adornment"
                sx={{ width: "100%", mb: 2 }}
                name="price"
                type="number"
                required
                onChange={(e) => handleChange(e)}
                value={inputs?.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-12">
              <TextField
                label="Tex Rate"
                id="outlined-start-adornment"
                sx={{ width: "100%", mb: 2 }}
                name="tex_rate"
                required
                type="number"
                onChange={(e) => handleChange(e)}
                value={inputs?.tex_rate}
                InputProps={{
                  max: 100,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="text-end mb-3">
            <button className="small-contained-button" disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
