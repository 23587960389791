import React from "react";

export default function BillingInfo({ selectedRow }) {
  return (
    <div className="container">
      <div className="row border-div">
        <div className="col-4 size">First Name:</div>
        <div className="col-6">{selectedRow.billing_info?.first_name}</div>
      </div>
      <div className="row border-div">
        <div className="col-4 size">Last Name:</div>
        <div className="col-6">{selectedRow.billing_info?.last_name}</div>
      </div>
      <div className="row border-div">
        <div className="col-4 size">Email:</div>
        <div className="col-6" style={{ wordBreak: "break-word" }}>
          {selectedRow.billing_info?.email}
        </div>
      </div>
      <div className="row border-div">
        <div className="col-4 size">Contact Number:</div>
        <div className="col-6">{selectedRow.billing_info?.contact_number}</div>
      </div>
      <div className="row border-div">
        <div className="col-4 size">Company Name:</div>
        <div className="col-6">{selectedRow.billing_info?.company_name}</div>
      </div>
      <div className="row border-div">
        <div className="col-4 size">City:</div>
        <div className="col-6">{selectedRow.billing_info?.city}</div>
      </div>
      <div className="row border-div">
        <div className="col-4 size">Postal Code:</div>
        <div className="col-6">{selectedRow.billing_info?.post_code}</div>
      </div>
      <div className="row border-div">
        <div className="col-4 size">Address:</div>
        <div className="col-6">{selectedRow.billing_info?.address}</div>
      </div>
      <div className="row border-div">
        <div className="col-4 size">Apartment:</div>
        <div className="col-6">{selectedRow.billing_info?.apartment}</div>
      </div>
      <div className="row border-div pt-2 pb-2">
        <div className="col-4 size">Shipping Type:</div>
        <div className="col-6">{selectedRow.billing_info?.shipping}</div>
      </div>
      <div className="row pt-2 pb-2">
        <div className="col-4 size">State:</div>
        <div className="col-6">{selectedRow.billing_info?.states}</div>
      </div>
    </div>
  );
}
