import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_shipping_price = async (data) => {
  const requestObj = {
    path: `api/shipping_price/list_shipping_price`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_shipping_price = async (data) => {
  const requestObj = {
    path: `api/shipping_price/add_shipping_price`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_shipping_price = async (_id, postData) => {
  const requestObj = {
    path: `api/shipping_price/edit_shipping_price/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _delete_shipping_price = async (id) => {
  const requestObj = {
    path: `api/shipping_price/delete_shipping_price/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
