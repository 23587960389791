import { invokeApi } from "../../bl_libs/invokeApi";
export const _add_vendor = async (data) => {
  const requestObj = {
    path: `api/vendor/create_vendor`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _Vendor_listApi = async (data) => {
  const requestObj = {
    path: `api/vendor/list_vendor`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const vendor_Delete_Api = async (id) => {
  const requestObj = {
    path: `api/vendor/delete_vendor/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_vendor_Api = async (postData, _id) => {
  const requestObj = {
    path: `api/vendor/edit_vendor/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _vendors_list_pagination = async (page, limit, search) => {
  const requestObj = {
    path: `api/vendor/list_vendor_pagination?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
