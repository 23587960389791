import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function SelectMui({
  attribute,
  item_id,
  handleChangeAttributes,
}) {
  return (
    <FormControl fullWidth disabled>
      <InputLabel id={`demo-simple-select-autowidth-label`}>
        {attribute.attribute_name}
      </InputLabel>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        name={attribute._id}
        value={item_id ? item_id : ""}
        label={attribute.attribute_name}
      >
        {attribute.attribute_items.map((item, item_index) => {
          return (
            <MenuItem
              value={item._id}
              key={item_index}
              onClick={(e) => handleChangeAttributes(attribute, item)}
            >
              {item.item_name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
