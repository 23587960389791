import ReactEditor from "react-text-editor-kit";
import { _general_Image_upload } from "../../DAL/general_image_upload/general_Image_upload";
import { s3baseUrl } from "../../config/config";
import { _add_image } from "../../DAL/product/product";

function ReactTextEditorKit({ value, onChange, get_editor_ref }) {
  const image_handler = async (e) => {
    let formData = new FormData();
    formData.append("image", e.image);
    console.log(...formData);
    const result = await _add_image(formData);
    if (result.code === 200) {
      return s3baseUrl + result.path;
    } else {
      return "";
    }
  };

  return (
    <div className="App">
      <ReactEditor
        value={value}
        onChange={onChange}
        getEditorRef={get_editor_ref}
        placeholder="Write your text here"
        image_handler={image_handler} // if you want to upload image on your server
      />
    </div>
  );
}

export default ReactTextEditorKit;
