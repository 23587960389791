import { invokeApi } from "../../bl_libs/invokeApi";
export const _add_order = async (data) => {
  const requestObj = {
    path: `api/order/add_order`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_orders = async (page, limit, search) => {
  const requestObj = {
    path: `api/order/list_order?page=${page}&limit=${limit}&search_text=${
      search === undefined || search === "" ? "" : search
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _list_orders_with_status = async (page, limit, status) => {
  const requestObj = {
    path: `api/order/list_order_with_status?page=${page}&limit=${limit}&status=${
      status === undefined || status === "" ? "" : status
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _detail_orders = async (id) => {
  const requestObj = {
    path: `api/order/order_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _add_order_prepared = async (data) => {
  let requestObj = {
    path: `api/order_prepared/add_order_prepared`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _change_order_status = async (postData, _id) => {
  const requestObj = {
    path: `api/order_proceed/change_status_of_proceed_order/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
