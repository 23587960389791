import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { _get_user_from_localStorage } from "../../DAL/localStorage/localStorage";
const CreateContentSetting = React.createContext();
const _get_user_info = _get_user_from_localStorage();

export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [userInfo, setUserInfo] = useState(_get_user_info);
  const [privilegeAccess, setPrivilegeAccess] = useState({});

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleUserInfo = (val) => {
    setUserInfo(val);
  };

  const collection = {
    userInfo,
    handleUserInfo,
    privilegeAccess,
  };

  useEffect(() => {
    if (userInfo?.privilages) {
      const privileges = JSON.parse(userInfo.privilages);
      let privileg_obj = {};
      privileges.map((privilege) =>
        privilege.privilege_items.map((item) => {
          privileg_obj[item.privilege_label_for] = item.privilege_access
        })
      );
      setPrivilegeAccess(privileg_obj);
    }
  }, [userInfo]);

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
