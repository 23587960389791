import React, { useState, useEffect } from "react";
import { Typography, CircularProgress } from "@mui/material";
import { _detail_orders } from "../../DAL/orders/orders";

export default function OrderDetail({ orderDetail }) {
  const [price, setPrice] = useState();
  const [discount, setDiscount] = useState();
  const [netAmount, setNetAmount] = useState();
  const [shippingAmount, setShippingAmount] = useState();
  const [orderItems, setOrderItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [priceData, setPriceData] = useState({});

  const get_detail_order = async () => {
    const response = await _detail_orders(orderDetail._id);
    console.log(response, "responseresponse");
    if (response.code === 200) {
      let data = response.order.order_items.map((item) => {
        let variationName;
        if (item.product_id.variation?.length > 0) {
          for (let i = 0; i < item.product_id.variation.length; i++) {
            if (item.product_id.variation[i]._id === item.variation) {
              const variation = item.product_id.variation[i].attributes_items[1]
                ? item.product_id.variation[i].attributes_items[0].item_name +
                  "/" +
                  item.product_id.variation[i].attributes_items[1].item_name
                : item.product_id.variation[i].attributes_items[0].item_name;
              variationName = variation;
            }
          }
        }
        let discount_price = 0;
        if (item.total_discount) {
          discount_price = item.total_discount.toFixed(2);
        } else {
          discount_price = discount_price.toFixed(2);
        }
        let totalPrice = 0;
        if (item.price) {
          totalPrice = item.price.toFixed(2);
        } else {
          totalPrice = totalPrice.toFixed(2);
        }
        let priceAfterDiscount = 0;
        if (item.price_after_discount) {
          priceAfterDiscount = item.price_after_discount.toFixed(2);
        } else {
          priceAfterDiscount = priceAfterDiscount.toFixed(2);
        }

        let matched_shipment = "";
        let isFirstMatch = true;
        if (Object.keys(response.order_prepared).length > 0) {
          for (const prepareItem of response.order_prepared.items) {
            for (const shipment of prepareItem.shipment) {
              const matchedOrder = item.product_id.shipment.find(
                (orderShipment) => {
                  return orderShipment._id === shipment._id;
                }
              );

              if (matchedOrder) {
                if (
                  item.product_id._id === prepareItem.product_id &&
                  item.variation === prepareItem.variation
                ) {
                  if (isFirstMatch) {
                    matched_shipment +=
                      matchedOrder.shipment_id + " |" + shipment.quantity;
                    isFirstMatch = false;
                  } else {
                    matched_shipment +=
                      "  " +
                      matchedOrder.shipment_id +
                      " |" +
                      shipment.quantity;
                  }
                }
              }
            }
          }
        }
        return {
          ...item,
          product_name:
            item.product_id.common_name + " " + item.product_id.scientific_name,
          quantity_ordered: item.quantity,
          variation_name: variationName,
          total_price: "$" + totalPrice,
          discountedPrice: "$" + discount_price,
          priceAfterDiscount: "$" + priceAfterDiscount,
          matched_shipment: matched_shipment,
        };
      });
      setOrderItems(data);
      setPriceData(response.order);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  console.log(orderDetail, "orderDetailorderDetail");

  const sub_total =
    priceData.total_price_after_discount - priceData.shipping_price;

  useEffect(() => {
    get_detail_order();
    // shipping amount in bottom
    let shipping_amount = 0;
    if (orderDetail.shipping_price) {
      shipping_amount = orderDetail.shipping_price.toFixed(2);
    } else {
      shipping_amount = shipping_amount.toFixed(2);
    }
    setShippingAmount(shipping_amount);

    // sub total in bottom
    let totalWitoutDiscount = 0;
    if (orderDetail.order_total_without_discount) {
      totalWitoutDiscount = orderDetail.order_total_without_discount.toFixed(2);
    } else {
      totalWitoutDiscount = totalWitoutDiscount.toFixed(2);
    }
    setPrice(totalWitoutDiscount);

    // grand total in bottom
    let totalAmount = 0;
    if (orderDetail.total_order_price) {
      totalAmount = orderDetail.total_order_price.toFixed(2);
    } else {
      totalAmount = totalAmount.toFixed(2);
    }
    setNetAmount(totalAmount);

    // total discount in bottom
    let discountAmount = 0;
    if (orderDetail.total_order_discount) {
      discountAmount = orderDetail.total_order_discount.toFixed(2);
    } else {
      discountAmount = discountAmount.toFixed(2);
    }
    setDiscount(discountAmount);
  }, []);

  return (
    <>
      <div className="container px-4">
        {/* top order number and date */}
        <div className="row mt-2">
          <div className="col-12 d-flex">
            <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
              Order Number:{" "}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {orderDetail.order_id}
            </Typography>
          </div>
          <div className="col-12 mt-1 d-flex">
            <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
              Order Date:{" "}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {orderDetail.order_date}
            </Typography>
          </div>
        </div>
        {/* ship and bill to box */}
        <div className=" row mt-3 solid-border">
          <div className="col-6 mb-3">
            <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "20px" }}>
              Ship To
            </Typography>
            <div className="col-md-12 col-lg-6 mt-2 d-flex">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
                Name:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {orderDetail.shipping_info?.first_name
                  ? orderDetail.shipping_info?.first_name
                  : " " + " " + orderDetail.shipping_info?.last_name
                  ? orderDetail.shipping_info?.last_name
                  : " "}
              </Typography>
            </div>
            <div className="col-md-12 col-lg-6 mt-1 d-flex">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
                Email:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {orderDetail.shipping_info?.email}
              </Typography>
            </div>
            <div className="col-md-12 col-lg-6 mt-1 d-flex">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
                Contact No:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {orderDetail.shipping_info?.contact_number}
              </Typography>
            </div>
            <div className="col-md-12 col-lg-6 mt-1 d-flex ">
              <Typography
                sx={{
                  mr: 1,
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Address:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                {orderDetail.shipping_info?.address}
              </Typography>
            </div>
            <div className="col-md-12 col-lg-6 mt-1 d-flex">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
                City:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {orderDetail.shipping_info?.city}
              </Typography>
            </div>
            <div className="col-md-12 col-lg-6 mt-1 d-flex">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
                Country:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {orderDetail.shipping_info?.country}
              </Typography>
            </div>
            <div className="col-md-12 col-lg-6 mt-1 d-flex">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
                Shipping:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {orderDetail.shipping_info?.shipping}
              </Typography>
            </div>
            <div className="col-md-12 col-lg-6 mt-1 d-flex">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
                State:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {orderDetail.shipping_info?.states}
              </Typography>
            </div>
            <div className="col-md-12 col-lg-6 mt-1 d-flex">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}>
                Postal Code:{" "}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {orderDetail.shipping_info?.post_code}
              </Typography>
            </div>
          </div>
          {orderDetail.email ? (
            <div className="col-5 mb-3">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "20px" }}>
                Bill To
              </Typography>
              <div className="col-md-12 col-lg-6 mt-2 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Name:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.first_name +
                    " " +
                    orderDetail.billing_info?.last_name}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Email:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.email}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Contact No:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.contact_number}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex ">
                <Typography
                  sx={{
                    mr: 1,
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Address:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                  {orderDetail.billing_info?.address}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  City:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.city}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Country:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.country}
                </Typography>
              </div>
              {/* <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Shipping:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.shipping}
                </Typography>
              </div> */}
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  State:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.states}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Postal Code:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.post_code}
                </Typography>
              </div>
            </div>
          ) : (
            <div className="col-6 mb-3">
              <Typography sx={{ mr: 1, fontWeight: "bold", fontSize: "20px" }}>
                Bill To
              </Typography>
              <div className="col-md-12 col-lg-6 mt-2 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Name:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.first_name +
                    " " +
                    orderDetail.billing_info?.last_name}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Email:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.email}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Contact No:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.contact_number}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex ">
                <Typography
                  sx={{
                    mr: 1,
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Address:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                  {orderDetail.billing_info?.address}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  City:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.city}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Country:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.country}
                </Typography>
              </div>
              {/* <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Shipping:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.shipping}
                </Typography>
              </div> */}
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  State:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.states}
                </Typography>
              </div>
              <div className="col-md-12 col-lg-6 mt-1 d-flex">
                <Typography
                  sx={{ mr: 1, fontWeight: "bold", fontSize: "14px" }}
                >
                  Postal Code:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {orderDetail.billing_info?.post_code}
                </Typography>
              </div>
            </div>
          )}

          {orderDetail.cancellation_reason != "" &&
            orderDetail.order_status == "cancelled" && (
              <div className="col-12 mt-4 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Order Cancellation Reason:
                </Typography>
                <Typography>{orderDetail.cancellation_reason}</Typography>
              </div>
            )}
          {orderDetail.return_reason != "" &&
            orderDetail.order_status == "returned" && (
              <div className="col-12 mt-4 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Order Return Reason:
                </Typography>
                <Typography>{orderDetail.return_reason}</Typography>
              </div>
            )}
        </div>

        {/* table  */}
        <div className="row mt-3">
          <table class="table table-nowrap ">
            <thead>
              <tr>
                <th scope="col" className="size-table">
                  #
                </th>
                <th scope="col" className="size-table fixed-width">
                  Product Name
                </th>
                <th scope="col" className="size-table fixed-width">
                  Attribute
                </th>

                <th scope="col" className="size-table fixed-width">
                  Unit Price
                </th>
                <th scope="col" className="size-table fixed-width">
                  Quantity
                </th>
                <th scope="col" className="size-table fixed-width">
                  Total Cost
                </th>
                <th scope="col" className="size-table fixed-width">
                  Discount
                </th>
                <th scope="col" className="size-table fixed-width">
                  Net Amount
                </th>
                <th scope="col" className="size-table fixed-width">
                  Shipment | Quantity
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <th className="size-table" scope="row"></th>
                  <td className="size-table"></td>
                  <td className="size-table"></td>
                  <td className="size-table"></td>
                  <td className="size-table">
                    <CircularProgress color="primary" />
                  </td>
                  <td className="size-table"></td>
                  <td className="size-table"></td>
                  <td className="size-table"></td>
                </tr>
              ) : (
                <>
                  {orderItems.length > 0 &&
                    orderItems?.map((items, index) => {
                      return (
                        <tr key={index}>
                          <th className="size-table" scope="row">
                            {index + 1}
                          </th>
                          <td className="size-table">{items.product_name}</td>
                          <td className="size-table">{items.variation_name}</td>
                          <td className="size-table">{`$${items.unit_price.toFixed(
                            2
                          )}`}</td>
                          <td className="size-table">
                            {items.quantity_ordered}
                          </td>

                          <td className="size-table">{items.total_price}</td>
                          <td className="size-table">
                            {items.discountedPrice}
                          </td>
                          <td className="size-table">
                            {items.priceAfterDiscount}
                          </td>
                          <td className="size-table">
                            {items.matched_shipment
                              .split("  ")
                              .map((item, index) => (
                                <React.Fragment key={index}>
                                  {index !== 0 && <br />}
                                  {item.replace("  ", "")}
                                </React.Fragment>
                              ))}
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
        </div>
        {/* bottom prices box */}
        <div class="row border-div mb-4">
          <div className="col-7"></div>
          <div className="col-5 text-end px-4 mb-2">
            <div className="justify-content-between d-flex">
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Sub Total:{" "}
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px" }}>{`$${sub_total.toFixed(
                  2
                )}`}</Typography>
              </div>
            </div>
            <div className="mt-2 justify-content-between d-flex">
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Tax Rate:{" "}
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px" }}>
                  {`$${(priceData.tex_amount
                    ? priceData.tex_amount
                    : 0
                  ).toFixed(2)}`}
                </Typography>
              </div>
            </div>
            <div className="mt-2 justify-content-between d-flex">
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Packing Charges :{" "}
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px" }}>
                  {`$${(priceData.packing_price
                    ? priceData.packing_price
                    : 0
                  ).toFixed(2)}`}
                </Typography>
              </div>
            </div>
            <div className="mt-2 justify-content-between d-flex">
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Shipping Charges:{" "}
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px" }}>
                  {`$${shippingAmount}`}
                </Typography>
              </div>
            </div>
            <div className="mt-2 justify-content-between d-flex">
              <div>
                <Typography
                  sx={{
                    mr: 15,
                    fontWeight: "bold",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Total Discount:{" "}
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px" }}>
                  {`$${discount}`}
                </Typography>
              </div>
            </div>
            <div className="mt-2 justify-content-between d-flex">
              <div>
                <Typography
                  sx={{ mr: 12, fontWeight: "bold", fontSize: "14px" }}
                >
                  Grand Total:{" "}
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px" }}>
                  {`$${netAmount}`}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
