import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, MenuItem, Tooltip } from "@mui/material";
import MenuPopover from "./MenuPopover";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CustomPopover(props) {
  const anchorRef = useRef(null);
  const { menu, data } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (e, data, option) => {
    e.preventDefault();
    if (option.is_access !== false) {
      setOpen(false);
      option.handleClick(data);
    }
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className="remove-border menu-background-circle">
      <MoreVertIcon
        style={{
          cursor: "pointer",
          fontSize: 20,
        }}
        className="pointer custom-popover-icon"
        ref={anchorRef}
        onClick={handleOpen}
      />
      <MenuPopover
        className="custom-popover"
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ marginLeft: 1.8, maxHeight: 300 }}>
        {menu.map((option) => (
          <Tooltip
            title={
              option.is_access === false
                ? "You have no access to perform this action"
                : ""
            }
            placement="right-start"
            arrow>
            <MenuItem
              key={option.label}
              onClick={(e) => {
                handleClick(e, data, option);
              }}>
              {option.icon && (
                <Icon
                  fontSize="18"
                  style={{ color: "var(--portal-theme-primary)" }}
                  className="me-2"
                  icon={option.icon}
                />
              )}
              <span>{option.label}</span>
            </MenuItem>
          </Tooltip>
        ))}

        {/* <PositionedMenu /> */}
      </MenuPopover>
    </div>
  );
}
