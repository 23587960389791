import React, { useEffect, useState } from "react";
import CustomMUITable from "../../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _delete_product_API,
  _list_product_API,
} from "../../../DAL/product/product";
import { CircularProgress } from "@mui/material";
import CustomConfirmation from "../../../components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "page_title", label: "Page Title", alignRight: false },
  { id: "url", label: "Url", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
  { id: "action", label: "Action", alignRight: false, type: "action" },
];
const ManageWebsiteListData = [
  {
    id: 1,
    page_title: "Home",
    url: "Url",
    status: true,
  },
];

export default function ManageWebsiteList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleEdit = (value) => {};

  const handleAgreeDelete = (value) => {};

  const handleNavigate = () => {
    navigate(`/website-pages/add-page`);
  };

  const handleDelete = async () => {};

  const handleFaq = (value) => {
    navigate(`/website-pages/faq/`, {});
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "FAQs",
      icon: "mdi:chat-question-outline",
      handleClick: handleFaq,
    },
  ];

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Page?"}
        handleAgree={handleDelete}
      />

      <div className="row mt-2">
        <div className="col-6">
          <h3>Website Pages</h3>
        </div>
        <div className="col-6 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            Add Page
          </button>
        </div>

        <div className="col-12 mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={ManageWebsiteListData}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
          />
        </div>
      </div>
    </div>
  );
}
