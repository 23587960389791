import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  TextField,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Icon } from "@iconify/react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _add_shipment_item,
  _delete_shipment_item,
  _list_shipment_items_by_shipment,
  _update_shipment_item,
} from "../../DAL/Shipment/shipment_items/shipment_items";
import { useEffect } from "react";
import { _list_product } from "../../DAL/product/product";
import SelectMui from "../../components/shipments/shipment_items/SelectMui";
import { useSnackbar } from "notistack";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { useRef } from "react";
import { _detail_shipment } from "../../DAL/Shipment/shipment_received/shipment_received";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "product_name", label: "Product Name", alignRight: false },
  { id: "attribute1", label: "Attribute 1", alignRight: false },
  { id: "attribute2", label: "Attribute 2", alignRight: false },
  { id: "total_quantity", label: "Total Quantity", alignRight: false },
  { id: "perUnitCost", label: "Vendor Price Per Item", alignRight: false },
  { id: "totalCost", label: "Vendor Total Price", alignRight: false },
];

export default function ShipmentItems() {
  const { shipment_id } = useParams();
  const add_new_ref = useRef(null);
  const [shipmentItems, setShipmentItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [formType, setFormType] = useState("ADD");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    total_quantity: "",
    per_unit_cost: "",
    total_cost: "",
  });
  const [values, setValues] = useState({
    shipment_title: "",
    shipment_id: "",
    vendor_name: "",
    shippment_date: "",
    live_stock_total_cost: 0,
  });

  const handleBack = () => {
    navigate(`/shipment`);
  };

  const handle_remove_values = () => {
    setInputs({
      total_quantity: "",
      per_unit_cost: "",
      total_cost: "",
    });
    setFormType("ADD");
    setSelectedAttributes([]);
    setSelectedProduct(null);
  };

  const get_shipment_items_info = async () => {
    const response = await _list_shipment_items_by_shipment(shipment_id);
    if (response.code === 200) {
      let data = response.shipment_item.map((item) => {
        return {
          ...item,
          attribute1: item.attributes_items[0]?.attribute_name
            ? item.attributes_items[0]?.attribute_name +
              " | " +
              item.attributes_items[0]?.item_name
            : "",
          attribute2: item.attributes_items[1]?.attribute_name
            ? item.attributes_items[1]?.attribute_name +
              " | " +
              item.attributes_items[1]?.item_name
            : "",
          totalCost: "$" + item.total_cost,
          perUnitCost: "$" + item.per_unit_cost,
        };
      });
      setShipmentItems(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedProduct != null) {
      setIsLoadingForm(true);
      const objectData = {
        product_id: selectedProduct._id,
        product_name:
          selectedProduct.common_name +
          " (" +
          selectedProduct.scientific_name +
          " )",

        total_quantity: inputs.total_quantity,
        total_cost: inputs.total_cost,
        per_unit_cost: inputs.per_unit_cost,
        attributes_items: selectedAttributes,
      };
      if (formType === "ADD") {
        objectData.shipment_id = shipment_id;
      } else {
        objectData.shipment_item_id = inputs._id;
      }
      const result =
        formType === "ADD"
          ? await _add_shipment_item(objectData)
          : await _update_shipment_item(objectData, shipment_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        handle_remove_values();
        setIsLoadingForm(false);
        get_shipment_items_info();
        get_detail_shipment();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingForm(false);
      }
    } else {
      enqueueSnackbar("Product is required", { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name === "total_quantity" || name === "per_unit_cost") {
      const totalQuantity =
        name === "total_quantity" ? value : inputs.total_quantity;
      const perUnitCost =
        name === "per_unit_cost" ? value : inputs.per_unit_cost;
      const totalCost =
        totalQuantity > 0 && perUnitCost > 0
          ? (parseFloat(perUnitCost) * parseFloat(totalQuantity)).toFixed(2)
          : "";
      setInputs((values) => ({ ...values, total_cost: totalCost }));
    }
  };

  const handleEdit = (value) => {
    setInputs(value);
    let selecte_product = productList.find(
      (product) => product._id === value.product_id
    );
    setSelectedProduct(selecte_product);
    setFormType("EDIT");
    if (value.attributes_items.length > 0) {
      setSelectedAttributes(value.attributes_items);
    }
    setTimeout(() => {
      add_new_ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleAgreeDelete = (value) => {
    setSelectedItem(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    let objectData = {
      shipment_item_id: selectedItem._id,
    };
    const result = await _delete_shipment_item(objectData, shipment_id);
    if (result.code === 200) {
      const data = shipmentItems.filter(
        (item) => item._id !== selectedItem._id
      );
      setShipmentItems(data);
      get_detail_shipment();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleChangeAttributes = (attribute, item) => {
    // Making Answer Object
    let newValue = {
      item_name: item.item_name,
      item_id: item._id,
      attribute_id: attribute._id,
      attribute_name: attribute.attribute_name,
    };

    if (selectedAttributes.length > 0) {
      let is_find = selectedAttributes.find(
        (obj) => obj.attribute_id === attribute._id
      );
      if (!is_find) {
        setSelectedAttributes((oldArray) => [...oldArray, newValue]);
      } else {
        setSelectedAttributes((prev) => {
          return prev.map((item) => {
            if (item.attribute_id === attribute._id) {
              return newValue;
            } else {
              return item;
            }
          });
        });
      }
    } else {
      setSelectedAttributes([newValue]);
    }
  };

  const get_product_listing = async () => {
    setIsLoading(true);
    const result = await _list_product();
    if (result.code === 200) {
      setProductList(result.product);
      get_shipment_items_info();
    } else {
      setIsLoading(false);
    }
  };
  const get_detail_shipment = async () => {
    const response = await _detail_shipment(shipment_id);
    console.log(response, "responseresponseresponse");
    if (response.code === 200) {
      const vendorName =
        response.Shipment.vendor_info.first_name +
        response.Shipment.vendor_info.last_name;
      setValues({
        shipment_id: response.Shipment.shipment_id,
        vendor_name: vendorName,
        live_stock_total_cost: response.Shipment.live_stock_total_cost,
        shippment_date: response.Shipment.shippment_date,
        shipment_title: response.Shipment.shipment_title,
      });
    } else {
    }
  };

  useEffect(() => {
    get_product_listing();
    get_detail_shipment();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      <div className="container" ref={add_new_ref}>
        <div className="row">
          <div className="col-12">
            <IconButton onClick={handleBack} className="icon-button">
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
          </div>
          <div className="col-6 my-3">
            <h3>Confirm Shipment</h3>
          </div>
        </div>
        <div className="row mx-1">
          <div className="card mb-4">
            <div className="row align-items-center mb-3">
              {values.shipment_title && (
                <div className="col-xs-12 col-md-4 mt-3 d-flex">
                  <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                    Shipment Title:{" "}
                  </Typography>
                  <Typography>{values.shipment_title}</Typography>
                </div>
              )}
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Shipment Id:{" "}
                </Typography>
                <Typography>{values.shipment_id}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Shipment Date:{" "}
                </Typography>
                <Typography> {values.shippment_date}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Vendor Name:{" "}
                </Typography>
                <Typography>{values.vendor_name}</Typography>
              </div>
              <div className="col-xs-12 col-md-4 mt-3 d-flex">
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                  Invoice Total Cost:{" "}
                </Typography>
                <Typography>{`$${values.live_stock_total_cost}`}</Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-1">
          <div className="card mb-4" id="add_new">
            <Typography
              className="typography-font-heading"
              sx={{ mt: "10px", ml: "15px" }}
            >
              {`${formType === "ADD" ? "Add" : "Update"} Confirm Shipment Item`}
            </Typography>
            <form
              className="new-variation-form"
              autoComplete="off"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="row align-items-center">
                <div className="col-xs-12 col-md-4 mt-3">
                  {/* autocomplete */}
                  <FormControl fullWidth>
                    <Autocomplete
                      id="product-select-123"
                      options={productList}
                      getOptionLabel={(product) =>
                        product.common_name +
                        " (" +
                        product.scientific_name +
                        ")"
                      }
                      getOptionSelected={(option, value) =>
                        option._id === value._id
                      }
                      value={selectedProduct}
                      onChange={(event, newValue) => {
                        setSelectedProduct(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Product *"
                          variant="outlined"
                        />
                      )}
                      required
                    />
                  </FormControl>
                </div>

                {selectedProduct?.attributes?.length > 0 &&
                  selectedProduct?.attributes.map((attribute, index) => {
                    //Getting Selected Item
                    let item_id = selectedAttributes.find(
                      (item) => item.attribute_id === attribute._id
                    )?.item_id;

                    return (
                      <div className="col-xs-12 col-md-4 mt-3" key={index}>
                        <SelectMui
                          attribute={attribute}
                          item_id={item_id}
                          handleChangeAttributes={handleChangeAttributes}
                        />
                      </div>
                    );
                  })}

                <div className="col-xs-12 col-md-4 mt-3">
                  <TextField
                    value={inputs.total_quantity}
                    name="total_quantity"
                    onChange={handleChange}
                    fullWidth
                    type="number"
                    label="Total Quantity"
                    className="form-control"
                    required
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </div>
                <div className="col-xs-12 col-md-4 mt-3">
                  <TextField
                    value={inputs.per_unit_cost}
                    name="per_unit_cost"
                    onChange={handleChange}
                    fullWidth
                    required
                    label="Vendor Price Per Item "
                    className="form-control"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onKeyPress={(event) => {
                      if (!/[.,0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="col-xs-12 col-md-4 mt-3">
                  <TextField
                    value={inputs.total_cost}
                    name="total_cost"
                    onChange={handleChange}
                    fullWidth
                    disabled
                    label="Vendor Total Price"
                    className="form-control"
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
              <div className="my-3 text-end">
                <button
                  className="small-contained-button"
                  type="submit"
                  disabled={isLoadingForm}
                >
                  {isLoadingForm
                    ? formType === "ADD"
                      ? "Submitting..."
                      : "Updating..."
                    : formType === "ADD"
                    ? "Submit"
                    : "Update"}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="row mx-1 card">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={shipmentItems}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
          />
        </div>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Order Item?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
