import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "../GeneralComponents/GeneralCkeditor";
import { useSnackbar } from "notistack";
import { _edit_task } from "../../DAL/task/task";
export default function AddOrUpdateTast({
  task_id,
  onCloseDrawer,
  isOpenDrawer,
  formType,
  taskListData,
  setTaskListData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = React.useState({
    title: "",
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const dateFormat = (date_str) => {
    const dateStr = date_str;
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: '2-digit', second: '2-digit', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', options);
    
    
    return formattedDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await _edit_task(inputs, task_id);
    // setIsLoading(true);

    if (result.code === 200) {
      let response_object = result.Task;

      const newState = taskListData.map((obj) => {
        if (obj._id === task_id) {
          return response_object;
        }
        return obj;
      });
      console.log('result',result);
      console.log('newState',newState);
      setTaskListData(newState);

      onCloseDrawer();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let new_task_list_clone = taskListData.filter((task) => {
      return task._id === task_id;
    });
    if (new_task_list_clone[0]) {
      if (formType == "Edit") {
        setCreatedAt(new_task_list_clone[0].createdAt)
        setInputs({
          title: new_task_list_clone[0].title,
          description: new_task_list_clone[0].description,
        });
      }
    }
  }, [formType]);

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-12">
          <h6>Task created on :</h6>
          {/* <p>02, Mar 2023 09:16:15 am</p> */}
          <p>{dateFormat(createdAt)}</p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              inputProps={{
                style: {
                  padding: 10
                }
            }}

              id="outlined-multiline-static"
              label="Task Title"
              multiline
              rows={4}
              name="title"
              value={inputs.title}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              style={{ width: "100%" }}
              className=" mb-3"
              required
            />
            </FormControl>
          </div>
          <div className="col-12">
            <h4 style={{ fontSize: "1rem" }}>Task Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              required
              name="description"
              editorHeight={230}
            />
          </div>

          <div className="col-12 text-end mt-3">
            <button className="small-contained-button" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
