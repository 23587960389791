import React, { useState, useEffect } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useNavigate } from "react-router-dom";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { CircularProgress, Tooltip } from "@mui/material";
import {
  _get_website_faqs,
  _delete_website_faqs,
} from "../../DAL/website_faqs/website_faqs";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "question_statement", label: "Question Statement", alignRight: false },
  { id: "answer_statement", label: "Answer Statement", alignRight: false },
  { id: "order", label: "Order", alignRight: false },
  { id: "status", label: "FAQ Status", alignRight: false, type: "row_status" },
  { id: "action", label: "Action", alignRight: false, type: "action" },
];

export default function WebsiteFaqs() {
  const { privilegeAccess, userInfo } = useContentSetting();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const handleNavigate = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_website_faqs === true) {
        navigate(`/website-faqs/add-website-faqs`);
      }
    } else {
      navigate(`/website-faqs/add-website-faqs`);
    }
  };
  const handleEdit = (value) => {
    navigate(`/website-faqs/edit-website-faqs/${value._id}`);
  };
  const handleAgreeDelete = (value) => {
    setSelectedRow(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const response = await _delete_website_faqs(selectedRow._id);
    if (response.code == 200) {
      enqueueSnackbar(response.message, {
        variant: "success",
      });

      setFaqs((prevData) =>
        prevData.filter((faqs) => faqs._id !== selectedRow._id)
      );
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
      is_access: userInfo.type == 0 ? true : privilegeAccess.edit_website_faqs,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      is_access:
        userInfo.type == 0 ? true : privilegeAccess.delete_website_faqs,
    },
  ];
  const get_faqs_list = async () => {
    setIsLoading(true);
    const response = await _get_website_faqs(page, rowsPerPage);
    if (response.code === 200) {
      let data = response.faq.map((faqs) => {
        return {
          ...faqs,
        };
      });
      setTotalCount(response.total_count);

      setFaqs(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    get_faqs_list();
  }, [page, rowsPerPage]);
  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Website FAQs</h3>
          </div>
          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.add_website_faqs === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              <button
                className="small-contained-button"
                onClick={handleNavigate}>
                Add FAQ
              </button>
            </Tooltip>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess.view_website_faqs ? (
          <NoAccessData />
        ) : (
          <div className="row">
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={faqs}
                MENU_OPTIONS={MENU_OPTIONS}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this FAQ?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
