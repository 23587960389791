import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import CustomModal from "../../components/GeneralComponents/CustomModal";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import { _customers_list } from "../../DAL/Customers/Customers";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import AddOrUpdateCustomer from "../../components/Customers/AddOrUpdateCustomer";
import CustomerChangePassword from "../../components/Customers/CustomerChangePassword";

export default function CustomersList() {
  const { privilegeAccess, userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [CustomerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const navigate = useNavigate();
  const [reset, setReset] = useState(0);
  const [drawerState, setDrawerState] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [deleteValue, setDeleteValue] = useState("");
  const [openModel, setOpenModel] = useState(false);

  const customerListing = async () => {
    setIsLoading(true);
    const result = await _customers_list(page, rowsPerPage, searchText);
    if (result.code === 200) {
      const customers = result.customer.map((customers) => {
        return {
          ...customers,
          customer_image: {
            src: s3baseUrl + customers.profile_image,
            alt: customers.first_name,
          },
          name: customers.first_name + " " + customers.last_name,
          date: moment(customers.createdAt).format("DD-MM-YYYY"),
        };
      });
      setTotalCount(result.total_count);
      setCustomerList(customers);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    customerListing();
  };
  const searchResetFunction = () => {
    setSearchText("");
    setPage(0);
    if (reset == 0) {
      setReset(1);
    } else {
      setReset(0);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleOrders = () => {
    navigate(`/customers/customer-orders`);
  };
  const handleOpenDrawer = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_customers === true) {
        setDrawerState(true);
        setFormType("ADD");
      }
    } else {
      setDrawerState(true);
      setFormType("ADD");
    }
  };
  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const handleEdit = (value) => {
    setSelectedRow(value);
    setDrawerState(true);
    setFormType("EDIT");
  };
  const handleChangePassword = (row) => {
    setDeleteValue(row);
    setOpenModel(true);
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "name", label: "Name", alignRight: false },
    {
      id: "customer_image",
      label: "Profile Image",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "email", label: "Email", alignRight: false },
    { id: "date", label: "Created At", alignRight: false },
    { id: "contact_number", label: "Phone", alignRight: false },
    { id: "address", label: "Address", alignRight: false },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
      is_access: userInfo.type == 0 ? true : privilegeAccess.edit_customers,
    },
    {
      label: "Change Password",
      icon: "mdi:password",
      handleClick: handleChangePassword,
      is_access: userInfo.type == 0 ? true : privilegeAccess.edit_customers,
    },
    // {
    //   label: "Orders",
    //   icon: "icon-park-twotone:transaction-order",
    //   handleClick: handleOrders,
    // },
  ];

  useEffect(() => {
    customerListing();
  }, [page, rowsPerPage, reset]);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Customers</h3>
          </div>
          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.add_customers === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow>
              <button
                className="small-contained-button"
                onClick={handleOpenDrawer}>
                Add Customer
              </button>
            </Tooltip>
          </div>
        </div>

        {userInfo.type == 2 && !privilegeAccess.view_customers ? (
          <NoAccessData />
        ) : (
          <div className="row">
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={CustomerList}
                MENU_OPTIONS={MENU_OPTIONS}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                  handleReset: searchResetFunction,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Customer`}
        componentToPassDown={
          <AddOrUpdateCustomer
            apiFunction={customerListing}
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            formType={formType}
            setCustomerList={setCustomerList}
            CustomerList={CustomerList}
            selectedRow={selectedRow}
          />
        }
      />
      <CustomModal
        title="Change Password"
        openModel={openModel}
        setOpenModel={setOpenModel}
        componentToPassDown={
          <CustomerChangePassword
            setOpenModel={setOpenModel}
            deleteValue={deleteValue}
          />
        }
      />
    </>
  );
}
