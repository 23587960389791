import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SelectMui from "../shipment_items/SelectMui";
import { _list_product } from "../../../DAL/product/product";
import Autocomplete from "@mui/material/Autocomplete";

import { TextField, FormControl } from "@mui/material";
import { useSnackbar } from "notistack";
import { _add_shipment_item } from "../../../DAL/Shipment/shipment_items/shipment_items";

function AddShipmentReceived({
  onCloseDrawer,
  apiFunction,
  setShipmentItems,
  shipmentItems,
}) {
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [productList, setProductList] = useState([]);
  const { shipment_id } = useParams();
  const [inputs, setInputs] = useState({
    total_quantity: "",
    per_unit_cost: "",
    total_cost: "",
  });

  const get_product_listing = async () => {
    const result = await _list_product();
    if (result.code === 200) {
      setProductList(result.product);
    } else {
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name === "total_quantity" || name === "per_unit_cost") {
      const totalQuantity =
        name === "total_quantity" ? value : inputs.total_quantity;
      const perUnitCost =
        name === "per_unit_cost" ? value : inputs.per_unit_cost;
      const totalCost =
        totalQuantity > 0 && perUnitCost > 0
          ? (parseFloat(perUnitCost) * parseFloat(totalQuantity)).toFixed(2)
          : "";
      setInputs((values) => ({ ...values, total_cost: totalCost }));
    }
  };
  const handleChangeAttributes = (attribute, item) => {
    // Making Answer Object
    let newValue = {
      item_name: item.item_name,
      item_id: item._id,
      attribute_id: attribute._id,
      attribute_name: attribute.attribute_name,
    };

    if (selectedAttributes.length > 0) {
      let is_find = selectedAttributes.find(
        (obj) => obj.attribute_id === attribute._id
      );
      if (!is_find) {
        setSelectedAttributes((oldArray) => [...oldArray, newValue]);
      } else {
        setSelectedAttributes((prev) => {
          return prev.map((item) => {
            if (item.attribute_id === attribute._id) {
              return newValue;
            } else {
              return item;
            }
          });
        });
      }
    } else {
      setSelectedAttributes([newValue]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedProduct != null) {
      setIsLoadingForm(true);
      const objectData = {
        product_id: selectedProduct._id,
        product_name:
          selectedProduct.common_name +
          " (" +
          selectedProduct.scientific_name +
          " )",

        total_quantity: inputs.total_quantity,
        total_cost: inputs.total_cost,
        per_unit_cost: inputs.per_unit_cost,
        attributes_items: selectedAttributes,
        shipment_id: shipment_id,
      };
      const result = await _add_shipment_item(objectData);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoadingForm(false);
        apiFunction();
        // setShipmentItems((prev) => [result.shipment_item, ...prev]);

        onCloseDrawer();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingForm(false);
      }
    } else {
      enqueueSnackbar("Product is required", { variant: "error" });
    }
  };
  useEffect(() => {
    get_product_listing();
  }, []);
  return (
    <form
      className="new-variation-form"
      autoComplete="off"
      onSubmit={handleSubmit}
      encType="multipart/form-data">
      <div className="row">
        <div className="col-12">
          {/* <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Product</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-label"
              name="product"
              value={selectedProduct?._id ? selectedProduct : ""}
              label="product"
              onChange={(e) => {
                setSelectedProduct(e.target.value);
              }}
              MenuProps={MenuProps}>
              {productList.length > 0 &&
                productList.map((product, index) => {
                  return (
                    <MenuItem value={product} key={index}>
                      {product.common_name +
                        " (" +
                        product.scientific_name +
                        " )"}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl> */}
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <Autocomplete
              id="product-select-123"
              options={productList}
              getOptionLabel={(product) =>
                product.common_name + " (" + product.scientific_name + ")"
              }
              getOptionSelected={(option, value) => option._id === value._id}
              value={selectedProduct}
              onChange={(event, newValue) => {
                setSelectedProduct(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Product" variant="outlined" />
              )}
            />
          </FormControl>
        </div>

        {selectedProduct?.attributes?.length > 0 &&
          selectedProduct?.attributes.map((attribute, index) => {
            //Getting Selected Item
            let item_id = selectedAttributes.find(
              (item) => item.attribute_id === attribute._id
            )?.item_id;

            return (
              <div className="col-12 mb-3" key={index}>
                <SelectMui
                  attribute={attribute}
                  item_id={item_id}
                  handleChangeAttributes={handleChangeAttributes}
                />
              </div>
            );
          })}

        <div className="col-12">
          <TextField
            sx={{ mb: 2 }}
            value={inputs.total_quantity}
            name="total_quantity"
            onChange={handleChange}
            fullWidth
            type="number"
            label="Total Quantity"
            className="form-control"
            required
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </div>

        <div className="col-12">
          <TextField
            sx={{ mb: 2 }}
            value={inputs.per_unit_cost}
            name="per_unit_cost"
            onChange={handleChange}
            fullWidth
            required
            label="Vendor Price Per Item"
            className="form-control"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            onKeyPress={(event) => {
              if (!/[.,0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
        <div className="col-12">
          <TextField
            sx={{ mb: 2 }}
            value={inputs.total_cost}
            name="total_cost"
            onChange={handleChange}
            fullWidth
            disabled
            label="Vendor Total Price"
            className="form-control"
            required
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
      </div>
      <div className="col-12 mt-3 mb-5 text-end">
        <button
          className="small-contained-button"
          type="submit"
          disabled={isLoadingForm}>
          {isLoadingForm ? "Saving..." : "save"}
        </button>
      </div>
    </form>
  );
}

export default AddShipmentReceived;
