import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import {
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MenuPopovers from "./popup";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function CustomPopovers(props) {
  let opens = props.isOpenPop;
  let prevalivges = props.data;
  let forClose = props.forClosePopUp;
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    forClose();
  };
  return (
    <div
      style={{ cursor: "pointer" }}
      className=" remove-border menu-background-circle  center">
      <Icon
        fontSize="18"
        style={{ color: "transparent" }}
        icon={"pajamas:eye"}
        className="pointer custom-popover-icon"
        ref={anchorRef}
        onClick={handleOpen}
      />

      <MenuPopovers
        className="custom-popover positioning-menu"
        open={opens}
        onClose={forClose}
        anchorEl={anchorRef.current}
        sx={{
          top: "50%",
          left: "50%",
        }}>
        <div className="container">
          <div className="row mt-2 ms-auto">
            <div className="col-6">
              <Typography
                variant="h5"
                sx={{ mt: 1, fontWeight: "500", color: "#045dbb" }}>
                {/* Edit  */}
                Privileges
              </Typography>
            </div>
            <div className="col-6 text-end">
              <IconButton sx={{ width: 50, height: 50 }} onClick={handleClose}>
                <Icon icon="emojione:cross-mark-button" />
              </IconButton>
            </div>
          </div>
          <div className="row">
            {prevalivges?.map((prevaliges_item, ParentIndex) => {
              return (
                <div className="col-md-4 col-xs-6" key={ParentIndex}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography sx={{ fontWeight: "600" }}>
                      {prevaliges_item.privilege_item_lable}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      {prevaliges_item.privilege_items?.map(
                        (child, childIndex) => {
                          return (
                            <FormControlLabel
                              key={childIndex}
                              control={
                                <Checkbox
                                  name={child.privilege_label_for}
                                  checked={child.privilege_access}
                                  // onChange={() =>
                                  //   handleCheckboxChange(
                                  //     ParentIndex,
                                  //     childIndex
                                  //   )
                                  // }
                                />
                              }
                              label={child.privilege_label_text}
                            />
                          );
                        }
                      )}
                    </FormGroup>
                  </AccordionDetails>
                </div>
              );
            })}
          </div>
        </div>
      </MenuPopovers>
    </div>
  );
}
