import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { _add_product } from "../../DAL/product/product";


import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Card,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import { _categories_list_pagination } from "../../DAL/category/category";
import { _list_attribute } from "../../DAL/AttributeVarients/AttributeVarients";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";

function AddMiscellaneousExpense() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = React.useState({
    title: "",
    amount: "",
    short_description: "",
    detailed_description: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleBack = () => {
    navigate(-1);
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

 

  //===================================================================

  return (
    <div className="container">



      <div className="row">

        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Extra Expense</h3>
        </div>
      </div>
      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 2,
        }}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data">
          <div className="row table-form pt-2 pb-2">
            <div className="col-md-6 col-xs-12 ">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="title"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="amount"
                onChange={handleChange}
              />
            </div>

            <div className="col-12">
              <TextField
                id="outlined-multiline-static"
                label="Short Description"
                multiline
                rows={5}
                name="short_description"
                onChange={(e) => handleChange(e)}
                inputs={inputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-12">
              <h4 style={{ fontSize: "1rem" }}>Detail Description </h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="detailed_description"
                editorHeight={350}
              />
            </div>
            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddMiscellaneousExpense;
