import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { _edit_product } from "../../DAL/product/product";
import { useTheme } from "@mui/material/styles";
import { _list_attribute } from "../../DAL/AttributeVarients/AttributeVarients";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Card,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import { _categories_list_pagination } from "../../DAL/category/category";
import { _list_product, _detail_product } from "../../DAL/product/product";

function EditMiscellaneousExpense() {
  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = React.useState({
    title: "",
    amount: "",
    short_description: "",
    detailed_description: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleBack = () => {
    navigate(-1);
  };
 
 
  const get_detail_miscellaneous_expense = async () => {
 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
 
  };

 
  const handle_format_data = (data) => {
    setInputs({
      ...data,
    });
  };

  useEffect(() => {
     
    if (!state) {
    //   get_detail_product();
    } else {
        console.log('state',state);
        console.log('state.value',state.value);
        handle_format_data(state);
      window.history.replaceState({}, document.title);
    }
  }, []);
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  //===================================================================

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Edit Miscellaneous Expense</h3>
        </div>
      </div>
      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 2,
        }}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data">
          <div className="row table-form pt-5 pb-4">
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="title"
                value={inputs?.title}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="amount"
                value={inputs?.amount}
                onChange={handleChange}
              />
            </div>

            <div className="col-12">
              <TextField
                id="outlined-multiline-static"
                label="Short Description"
                multiline
                rows={5}
                name="short_description"
                value={inputs?.short_description}
                onChange={(e) => handleChange(e)}
                inputs={inputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-12">
              <h4 style={{ fontSize: "1rem" }}>Detail Description </h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="detailed_description"
                editorHeight={350}
              />
            </div>
            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default EditMiscellaneousExpense;
