import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import Menu from "@mui/material/Menu";
import LockIcon from "@mui/icons-material/Lock";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import ChangePassword from "../../pages/UserProfile/ChangePassword";
import EditProfile from "../../pages/UserProfile/EditProfile";
import CustomDrawer from "../GeneralComponents/CustomDrawer";
import CustomModal from "../GeneralComponents/CustomModal";

export default function ProfileMenu({
  anchorEl,
  handleClose,
  open,
  handleLogout,
  name,
  email,
}) {
  const [openModel, setOpenModel] = React.useState(false);
  const [drawerState, setDrawerState] = React.useState(false);

  const handleChangePassword = () => {
    setOpenModel(true);
  };

  const handleEditProfileClose = () => {
    setDrawerState(false);
  };
  const handleEditProfileOpen = () => {
    setDrawerState(true);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <div className="profile-menu">
          <p>{name}</p>
          <p className="profile-menu-email">{email}</p>
        </div>
        <Divider sx={{ bgcolor: "black" }} />
        <MenuItem onClick={handleEditProfileOpen}>
          <ListItemIcon>
            <PersonIcon className="profile-menu-icons" />
          </ListItemIcon>
          Edit Profile
        </MenuItem>
        <MenuItem onClick={handleChangePassword}>
          <ListItemIcon>
            <LockIcon className="profile-menu-icons" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Button className="profile-menu-logout">LOGOUT</Button>
        </MenuItem>
      </Menu>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onCloseDrawer={handleEditProfileClose}
        pageTitle="Edit Profile"
        componentToPassDown={
          <EditProfile onCloseDrawer={handleEditProfileClose} />
        }
      />
      <CustomModal
        title="Change Password"
        openModel={openModel}
        setOpenModel={setOpenModel}
        componentToPassDown={<ChangePassword setOpenModel={setOpenModel} />}
      />
    </>
  );
}
