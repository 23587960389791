import React, { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import {
  Button,
  Input,
  FormHelperText,
  CircularProgress,
  Tooltip,
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { s3baseUrl } from "../../config/config";
import { _general_Image_upload } from "../../DAL/general_image_upload/general_Image_upload";
import {
  _get_checkout_settings_admin,
  _add_checkout_settings_admin,
} from "../../DAL/website_setting/checkout_setting";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";

function CheckoutSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isbanner1Loading, setIsbanner1Loading] = useState(true);
  const { userInfo, privilegeAccess } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [previewsBanner1, setPreviewsBanner1] = useState();
  const [inputs, setInputs] = useState({
    checkout_page_heading: "",
    cart_page_heading: "",
    checkout_button_text: "",
    continue_shopping_button_text: "",
    subTotal_text: "",
    grandTotal_text: "",
    agreement_text: "",
    order_detail_text: "",
    payment_method_text: "",
    payment_image: "",
    pickup_title: "",
    cargo_title: "",
    over_night_title: "",
    pickup_description: "",
    cargo_description: "",
    over_night_description: "",
    checkout_text_for_guest_user: "",
    Billing_text_for_cargo: "",
    Billing_text_for_over_night: "",
    Billing_text_for_pickup: "",
    shipping_text_for_cargo: "",
    shipping_text_for_over_night: "",
    shipping_text_for_pickup: "",
    packing_charge_enabled: false,
    packing_amount: 14.99,
  });

  const fileChangedHandlerBanner1 = async (event) => {
    if (event.target.files[0]) {
      setIsbanner1Loading(true);
      const result = await _general_Image_upload(event.target.files[0], "200");
      if (result.code === 200) {
        setPreviewsBanner1(result.path);
        setInputs({
          ...inputs,
          payment_image: result.path,
        });
      }
      setIsbanner1Loading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_checkout_setting_admin_list = async () => {
    setIsLoading(true);
    setIsbanner1Loading(true);

    const result = await _get_checkout_settings_admin();
    if (result.code === 200) {
      setInputs(result.cart_variant_settings);
      if (result.cart_variant_settings.payment_image) {
        setPreviewsBanner1(result.cart_variant_settings.payment_image);
      }
      setIsLoading(false);
      setIsbanner1Loading(false);
    } else {
      setIsLoading(false);
      setIsbanner1Loading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_checkout_setting === true ||
        privilegeAccess.edit_checkout_setting === true
      ) {
        setIsButtonLoading(true);
        const result = await _add_checkout_settings_admin({
          cart_variant_settings: inputs,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      setIsButtonLoading(true);
      const result = await _add_checkout_settings_admin({
        cart_variant_settings: inputs,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };

  useEffect(() => {
    get_checkout_setting_admin_list();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  //===================================================================

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="my-3">Checkout Setting</h3>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess.view_checkout_setting ? (
          <NoAccessData />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            {/*  Top description*/}
            <div className="row">
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  required
                  label="Checkout Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="checkout_page_heading"
                  onChange={handleChange}
                  value={inputs?.checkout_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Cart Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="cart_page_heading"
                  onChange={handleChange}
                  value={inputs?.cart_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Continue Shopping Button Text"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="continue_shopping_button_text"
                  onChange={handleChange}
                  value={inputs?.continue_shopping_button_text}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="SubTotal Text"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="subTotal_text"
                  onChange={handleChange}
                  value={inputs?.subTotal_text}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="GrandTotal Text"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="grandTotal_text"
                  onChange={handleChange}
                  value={inputs?.grandTotal_text}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Checkout Button Text"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="checkout_button_text"
                  onChange={handleChange}
                  value={inputs?.checkout_button_text}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Order Detail Text"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="order_detail_text"
                  onChange={handleChange}
                  value={inputs?.order_detail_text}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Payment Method Text"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="payment_method_text"
                  onChange={handleChange}
                  value={inputs?.payment_method_text}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Pickup Title"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="pickup_title"
                  onChange={handleChange}
                  value={inputs?.pickup_title}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Cargo Title"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="cargo_title"
                  onChange={handleChange}
                  value={inputs?.cargo_title}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Over Night Title"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="over_night_title"
                  onChange={handleChange}
                  value={inputs?.over_night_title}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Billing Text for Pickup"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="Billing_text_for_pickup"
                  onChange={handleChange}
                  value={inputs?.Billing_text_for_pickup}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Billing Text for Cargo"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="Billing_text_for_cargo"
                  onChange={handleChange}
                  value={inputs?.Billing_text_for_cargo}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Billing Text for Over Night"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="Billing_text_for_over_night"
                  onChange={handleChange}
                  value={inputs?.Billing_text_for_over_night}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Shipping Text for Pickup"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="shipping_text_for_pickup"
                  onChange={handleChange}
                  value={inputs?.shipping_text_for_pickup}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Shipping Text for Cargo"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="shipping_text_for_cargo"
                  onChange={handleChange}
                  value={inputs?.shipping_text_for_cargo}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Shipping Text for Over Night"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="shipping_text_for_over_night"
                  onChange={handleChange}
                  value={inputs?.shipping_text_for_over_night}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <FormControl fullWidth sx={{ mb: 0 }}>
                  <InputLabel id="demo-simple-select-label">
                    Packing Charges Enabled *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="packing_charge_enabled"
                    defaultValue="sandbox"
                    value={inputs.packing_charge_enabled}
                    required
                    label="Packing Charges Enabled"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.packing_charge_enabled && (
                <div className="col-md-6 col-xs-12 mb-4">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Packing Amount *
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      label="Packing Amount"
                      required
                      name="packing_amount"
                      type="number"
                      onChange={handleChange}
                      value={inputs?.packing_amount}
                      sx={{ width: "100%" }}
                    />
                  </FormControl>
                </div>
              )}
            </div>
            <div className="row">
              {/* Banner 1 image*/}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100  ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="home_page_header">Payment Option Image</p>
                    <FormHelperText className="pt-0">
                      Recommended Size(100 X 50) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isbanner1Loading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img src={s3baseUrl + previewsBanner1} height={50} />
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file-banner-1">
                      <Input
                        accept="image/*"
                        id="contained-button-file-banner-1"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="payment_image"
                        onChange={fileChangedHandlerBanner1}
                      />
                      <Button
                        id="contained-button-file-banner-1"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4">
                <p className="general_setting_page_header">Aggreement Text *</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="agreement_text"
                  editorHeight={350}
                />
              </div>
              <div className="col-12 mt-4">
                <p className="general_setting_page_header">
                  Checkout Text for Guest User
                </p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="checkout_text_for_guest_user"
                  editorHeight={350}
                />
              </div>
              <div className="col-12 mt-4">
                <p className="general_setting_page_header">
                  Pickup Description
                </p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="pickup_description"
                  editorHeight={350}
                />
              </div>
              <div className="col-12 mt-4">
                <p className="general_setting_page_header">Cargo Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="cargo_description"
                  editorHeight={350}
                />
              </div>
              <div className="col-12 mt-4">
                <p className="general_setting_page_header">
                  Over Night Description
                </p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="over_night_description"
                  editorHeight={350}
                />
              </div>
            </div>
            <div className="col-12 mt-3 text-end">
              <Tooltip
                title={
                  userInfo.type == 2 &&
                  (privilegeAccess.edit_checkout_setting ||
                    privilegeAccess.add_checkout_setting) === false
                    ? "You have no access to perform this action"
                    : ""
                }
                placement="right-end"
                arrow
              >
                {isButtonLoading ? (
                  <LoadingButton loading variant="contained">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    // style={{ textTransform: 'capitalize' }}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </Tooltip>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default CheckoutSetting;
