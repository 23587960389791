import React, { useEffect, useState } from "react";
import {
  _categories_list_pagination,
  _delete_category,
} from "../../DAL/category/category";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import AddOrUpdateCategory from "../../components/Categories/AddOrUpdateCategory";
import { CircularProgress, Tooltip } from "@mui/material";
import CustomCofirmation from "../../components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Main Category", alignRight: false },
  { id: "parent_category_title", label: "Parent Category", alignRight: false },
  {
    id: "category_image",
    label: "Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

export default function Categories() {
  const [formType, setFormType] = useState("ADD");
  const [isLoading, setIsLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [drawerState, setDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const { privilegeAccess, userInfo } = useContentSetting();
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [reset, setReset] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    setIsLoading(true);
    const response = await _delete_category(selectedRow._id);
    if (response.code == 200) {
      enqueueSnackbar(response.message, {
        variant: "success",
      });
      setOpenDelete(false);
      setCategoryData((prevData) =>
        prevData.filter((category) => category._id !== selectedRow._id)
      );
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    setSelectedRow(value);
    setDrawerState(true);
    setFormType("EDIT");
  };
  const handleAgreeDelete = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleOpenDrawer = () => {
    if (userInfo.type == 2) {
      if (privilegeAccess.add_category === true) {
        setDrawerState(true);
        setFormType("ADD");
      }
    } else {
      setDrawerState(true);
      setFormType("ADD");
    }
  };
  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  const getParentCategoryTitle = (categoryId, category) => {
    const parentCategory = category.find((cat) => cat._id === categoryId);
    return parentCategory ? parentCategory.title : "";
  };
  const get_category_list = async () => {
    setIsLoading(true);
    const response = await _categories_list_pagination(
      page,
      rowsPerPage,
      searchText
    );

    if (response.code === 200) {
      const category = response.category.map((category, index) => {
        return {
          ...category,
          category_image: {
            src: s3baseUrl + category.image,
            alt: category.title,
          },
          parent_category_title: getParentCategoryTitle(
            category.parent_category,
            response.category
          ),
        };
      });
      setTotalCount(response.total_count);
      setCategoryData(category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    get_category_list();
  };
  const searchResetFunction = () => {
    setSearchText("");
    setPage(0);
    if (reset == 0) {
      setReset(1);
    } else {
      setReset(0);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
      is_access: userInfo.type == 0 ? true : privilegeAccess.edit_category,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      is_access: userInfo.type == 0 ? true : privilegeAccess.delete_category,
    },
  ];

  useEffect(() => {
    get_category_list();
  }, [page, rowsPerPage, reset]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <h3>Categories</h3>
          </div>

          <div className="col-6 text-end">
            <Tooltip
              title={
                userInfo.type == 2 && privilegeAccess.add_category === false
                  ? "You have no access to perform this action"
                  : ""
              }
              placement="right-end"
              arrow
            >
              <button
                className="small-contained-button"
                onClick={handleOpenDrawer}
              >
                Add Category
              </button>
            </Tooltip>
          </div>
        </div>

        {userInfo.type == 2 && !privilegeAccess.view_category ? (
          <NoAccessData />
        ) : (
          <div className="row">
            <div className="col-12 mt-3">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={categoryData}
                MENU_OPTIONS={MENU_OPTIONS}
                setData={setCategoryData}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                  handleReset: searchResetFunction,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Category`}
        componentToPassDown={
          <AddOrUpdateCategory
            apiFunction={get_category_list}
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            formType={formType}
            setCategoryData={setCategoryData}
            categoryData={categoryData}
            selectedRow={selectedRow}
          />
        }
      />
      <CustomCofirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Category?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
